/* eslint no-shadow: 0 */
import React, {
  Fragment, useEffect, useMemo, useState
} from 'react';
import propTypes from 'prop-types';
import queryString from 'query-string';
import { Cookies, useCookies } from 'react-cookie';
import MainLayout from 'containers/MainLayout';
import tracker from 'utils/tracking';
import tiktok from 'utils/tracking/pixels/tiktok';
import { connect, useDispatch } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import {
  PURCHASE_COMPLETE,
  pushEvent
} from 'utils/tracking/gtm';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import { convertToDollars } from 'utils/moneyUtils';
import { FAILURE, LOADED, NONE } from '../../redux/reducers/orderReducer';
import { fetchOrder, clearCheckout } from '../../redux/actions';
import './ThankYouPage.scss';

const TRACKING_HOST = process.env.REACT_APP_TRACKING_HOST ?
  process.env.REACT_APP_TRACKING_HOST :
  'https://teespring.com';

const ThankYouPageWrapper = (WrappedComponent) => {
  const BaseTheme = (props) => {
    const {
      location,
      storeData,
      localizationData,
      fetchOrder,
      order,
      orders,
      orderLoadStatus
    } = props;
    const [purchaseCompleteEventTracked, setPurchaseCompleteEventTracked] = useState(false);
    const {
      orderNumber, orderLookupNumber, deviceId, cart, contentIds
    } = queryString.parse(
      location.search
    );

    // eslint-disable-next-line no-unused-vars
    const [{ _teespring_partner_attribution: partnerCookie }] = useCookies();

    const sendTrackingPixelPurchase = (amount, currency) => {
      ReactPixel.track('Purchase', {
        content_type: 'product',
        content_ids: [contentIds],
        currency,
        value: convertToDollars(amount)
      });
    };

    const dispatch = useDispatch();
    const orderTotalCost = reduce(orders, (total, cartOrder) => total + get(cartOrder, 'totalCost.amount'), 0);

    useEffect(() => {
      const getOrder = async () => {
        if (orderLoadStatus === NONE) {
          try {
            await fetchOrder(orderLookupNumber || cart);
          } catch (err) {
            tracker.track('thank_you_page.order_fetch_error', {
              error: err,
              order_number: orderLookupNumber || orderNumber
            });
          }
          try {
            tracker.setAmplitudeDeviceId(deviceId);
            tracker.track('thank_you_page.viewed', {
              order_number: orderLookupNumber || orderNumber,
              cart,
              partner: tiktok.trackPartnersCookieInParams(partnerCookie)
            });
          } catch (err) {
            tracker.track('thank_you_page.amplitide_reporting_error', {
              error: err,
              order_number: orderLookupNumber || orderNumber
            });
          }
          try {
            ReactPixel.pageView();
          } catch (err) {
            tracker.track('thank_you_page.ga_pixel_reporting_error', {
              error: err,
              order_number: orderLookupNumber || orderNumber
            });
          }
        }
      };

      getOrder();
    }, [orderLoadStatus, fetchOrder, cart]);

    useEffect(() => {
      const { cookies } = props;
      const purchaseIsUntracked = !(cookies.get('purchaseTrackId') === get(order, 'lookupNumber'));
      if (!purchaseCompleteEventTracked && orderLoadStatus === LOADED && order) {
        pushEvent(PURCHASE_COMPLETE, {
          order,
          orders,
          storeName: storeData.name,
          storeSlug: storeData.slug,
          storeId: storeData.id,
          sellerId: get(storeData, 'sellerId')
        });
        setPurchaseCompleteEventTracked(true);
      }
      if (orderLoadStatus === LOADED && order && purchaseIsUntracked) {
        if (order.totalCost) {
          sendTrackingPixelPurchase(
            orderTotalCost,
            order.totalCost.currency,
            orderLookupNumber || orderNumber
          );
        }
        // Mark Purchase event as tracked
        cookies.set('purchaseTrackId', get(order, 'lookupNumber'));
      }

      if (orderLoadStatus === FAILURE) {
        sendTrackingPixelPurchase(
          orderTotalCost,
          localizationData.buyer_currency,
          orderLookupNumber || orderNumber
        );
      }

      if (orderLoadStatus === LOADED || orderLoadStatus === FAILURE) {
        dispatch(clearCheckout());
      }
    }, [orderLoadStatus, order]);

    const trackingUrl = useMemo(() => {
      let lookup = orderLookupNumber || orderNumber;

      if (orderLoadStatus === LOADED && orders.length > 0) {
        lookup = orders[0].lookupNumber;
      }

      return `${TRACKING_HOST}/track/order/${lookup}`;
    }, [orders, orderLookupNumber, orderNumber]);

    const components = {};

    components.title = (
      <h4>
        Thank you for your order!
      </h4>
    );

    components.orderSummary = (
      <div className="thankyou__order mb4">

        <p className="mb2">
          We have received it and are processing it. Look out for a confirmation email with your order details.
          Once your order ships, you will receive another email containing your shipping tracking information.
        </p>
        <p className="mb2">
          For digital products, a link to download your purchase will be available once your order is processed,
          which may take a few minutes to complete. Please check your inbox for two emails: one confirming your purchase
          and another containing a link to download your digital product.
        </p>
        <p>
          Reach out to us at&nbsp;
          <a
            className="typ--link"
            onClick={ () => tracker.track('thank_you_page.support_email.clicked')
            }
            href="mailto:fanhelp@spri.ng"
          >
            fanhelp@spri.ng
          </a>
          &nbsp;or via Live Chat for any questions or modifications to your order. We are here to assist you!
        </p>
      </div>
    );

    components.usefulLinks = (
      <Fragment>
        <h4 className="mb1">Useful Links</h4>
        <ul>
          <li>
            <a
              className="typ--link"
              href="https://teespring.com/login"
              onClick={ () => tracker.track('thank_you_page.log_into_account.clicked')
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Login to your account
            </a>
          </li>
          <li>
            <a
              className="typ--link"
              href="https://answers.teespring.com/"
              onClick={ () => tracker.track('thank_you_page.faq_links.clicked') }
              target="_blank"
              rel="noopener noreferrer"
            >
              Frequently asked questions
            </a>
          </li>

          <li>
            <a
              className="typ--link"
              href="https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations/"
              onClick={ () => tracker.track('thank_you_page.faq_links.clicked') }
              target="_blank"
              rel="noopener noreferrer"
            >
              Refunds and Cancellations
            </a>
          </li>
        </ul>
      </Fragment>
    );

    return (
      <MainLayout location={ location } storeData={ storeData }>
        <WrappedComponent
          { ...props }
          { ...components }
          trackingUrl={ trackingUrl }
        />
      </MainLayout>
    );
  };

  const {
    shape, string, number, bool, func, instanceOf, arrayOf
  } = propTypes;

  BaseTheme.propTypes = {
    fetchOrder: func.isRequired,
    order: shape({}).isRequired,
    orders: arrayOf(shape({})).isRequired,
    orderLoadStatus: string.isRequired,
    cookies: instanceOf(Cookies).isRequired,
    storeData: shape({
      banner_url: string,
      collections: arrayOf(shape({})),
      description: string,
      link_color: string,
      logo_height: number,
      logo_url: string,
      logo_width: number,
      name: string,
      social_identities: shape({}),
      theme_color: string,
      url: string,
      use_logo: bool,
      location: shape({})
    }).isRequired,
    localizationData: shape({
      buyer_currency: string
    }).isRequired,
    location: shape({
      pathname: string,
      hash: string,
      search: string,
      state: shape({})
    }).isRequired,
    checkout: shape({
      email: string
    }).isRequired
  };

  const mapStateToProps = state => ({
    order: state.order.data[0],
    orders: state.order.data,
    orderLoadStatus: state.order.loadStatus,
    checkout: state.checkout
  });

  return connect(mapStateToProps, { fetchOrder })(BaseTheme);
};

export default ThankYouPageWrapper;

