import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import MainLayout from 'containers/MainLayout';
import get from 'lodash/get';
import './DocumentWrapper.scss';

function DocumentWrapper(WrappedComponent) {
  const Document = (props) => {
    const { location, storeData, stores } = props;

    /**
     * Remove Contacting Company Name section
Remove "Company name" from Title of page
Use Name = StoreName and Company_Name = StoreName
Date should be "Last Updated: February 2020"
Merge the PR
Separate PR to add Public name, Name, Company Name, Email and Address for Terms of Service
Date required for when Terms were drafted
     */

    let domain = window.location.href;
    const currentPage = domain.split('/')[domain.split('/').length - 1];
    domain = domain.replace(`/${currentPage}`, '');

    // TODO Replace with creator info
    const creator = {
      company: get(stores, 'name'),
      name: get(stores, 'name'),
      domain,
      email: null,
      address: null
    };

    return (
      <MainLayout location={ location } storeData={ storeData }>
        <div className="document row">
          <WrappedComponent creator={ creator } />
        </div>
      </MainLayout>
    );
  };

  const {
    string, number, object, bool, shape, array
  } = propTypes;

  Document.propTypes = {
    storeData: shape({
      banner_url: string,
      collections: array,
      description: string,
      link_color: string,
      logo_height: number,
      logo_url: string,
      logo_width: number,
      name: string,
      social_identities: object,
      theme_color: string,
      url: string,
      use_logo: bool,
      location: object
    }).isRequired,
    location: shape({
      pathname: string,
      hash: string,
      search: string,
      state: object
    }).isRequired,
    stores: shape({
      name: string
    }).isRequired
  };

  const mapStateToProps = state => ({
    stores: state.stores
  });

  return connect(mapStateToProps)(Document);
}

export default DocumentWrapper;
