import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputField,
  Alert,
  Button
} from '@springforcreators/propel-ui';
import { updateCheckout } from 'redux/actions';
import tracker from 'utils/tracking';
import get from 'lodash/get';
import propTypes from 'prop-types';

import './Promocode.scss';

const Promocode = ({ showPromoCode }) => {
  const {
    handleSubmit,
    register,
    formState,
    setValue,
    getValues
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { promocode: '' }
  });

  const dispatch = useDispatch();
  const { checkout } = useSelector(state => state);

  const [removingCode, setRemovingCode] = useState(false);
  const [focus, setFocus] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState(false);
  const [showRequiredFieldsError, setshowRequiredFieldsError] = useState(false);

  const onSubmit = async (values) => {
    const promocode = get(values, 'promocode');
    tracker.track('checkout.apply_promo_code.clicked', { promoCode: promocode });
    if (get(formState, 'isValid')) {
      await dispatch(updateCheckout({ promoCode: promocode }));
    }
    setValue('promocode', '');
  };

  const toggleFocus = () => {
    const inputElem = document.querySelector('#promocode');
    inputElem?.focus();
  };

  useEffect(() => {
    if (linkClicked) toggleFocus();
  }, [linkClicked]);

  useEffect(() => {
    const promoCode = get(checkout, 'promoCode');
    if (!checkout?.fetchingCheckout && checkout?.costs?.discount?.value) {
      setPromoCodeError(false);
      tracker.track('checkout.promo_code.applied', { promoCode });
    }
  }, [checkout?.promoCodeErrors, checkout?.fetchingCheckout, checkout?.costs?.discount?.value]);
  useEffect(() => {
    const promoCode = get(checkout, 'promoCode');
    if (checkout?.promoCodeErrors) {
      dispatch(updateCheckout({ promoCode: '' }));
      setPromoCodeError(true);
      tracker.track('checkout.promo_code.failed', { promoCode });
    }
  }, [checkout?.promoCodeErrors]);

  useEffect(() => {
    if (showRequiredFieldsError && linkClicked && showPromoCode) {
      setshowRequiredFieldsError(false);
    }
  }, [showPromoCode]);

  useEffect(() => {
    if (removingCode && !checkout?.promoCode) {
      if (removingCode === 'focus') toggleFocus();
      setRemovingCode(false);
    }
  }, [removingCode, checkout?.promoCode]);

  const removePromocode = () => {
    setRemovingCode(true);
    dispatch(updateCheckout({ promoCode: '' }));
    setValue('promocode', '');
  };

  const onChangeCodeHandler = () => {
    setLinkClicked(true);
    removePromocode();
    setRemovingCode('focus');
  };

  const appliedPromocode = (
    <>
      { checkout.promoCode && (
        <div className="promocode__active mt2">
          <div className="promocode__active__rm">
            <div>
              <span className="typ--bold">{ checkout.promoCode }</span>
              &nbsp;promo code applied
            </div>

            <button className="promocode__close" onClick={ removePromocode } type="button">
              Remove
            </button>
          </div>

          <Button
            className="promocode__change"
            type="button"
            onClick={ onChangeCodeHandler }
            fullWidth={ true }
            btnType="secondary"
            size="md"
          >
            Change code
          </Button>
        </div>
      )}
    </>
  );

  const promoCodeForm = (
    <form onSubmit={ handleSubmit(onSubmit) }>
      <div className={ `promocode__fields ${focus ? 'is-focused' : ''} ${promoCodeError ? 'has-error' : ''}` }>
        <InputField
          label="Promo code"
          name="promocode"
          type="text"
          required={ true }
          register={ register }
          defaultValue={ getValues('promocode') }
          onFocus={ () => setFocus(true) }
          onBlur={ () => setFocus(false) }
        />
        <button
          className={ `promocode__submit pr_btn ${get(formState, 'isValid') ? '' : 'btn--disabled'}` }
          type="submit"
        >
          Apply
        </button>
      </div>
      { promoCodeError && (
        <span className="pr_form__error" aria-label="error-message">
          Sorry, this promo code cannot be applied to your order.
        </span>
      ) }
    </form>
  );

  const onClickHandler = () => {
    tracker.track('checkout.have_promo_code.clicked');
    setLinkClicked(true);

    if (showPromoCode) {
      setshowRequiredFieldsError(false);
    } else {
      setshowRequiredFieldsError(true);
    }
  };

  return (
    <div className="promocode">
      { ((!checkout?.promoCode && !linkClicked && showPromoCode) || (!checkout?.promoCode && !showPromoCode)) && (
        <Button
          icon="Plus"
          btnType="secondary"
          className="promocode__link"
          onClick={ onClickHandler }
          type="button"
        >
          Promo code
        </Button>
      ) }

      { (!checkout?.promoCode && !showRequiredFieldsError && linkClicked && showPromoCode) && promoCodeForm }

      { (checkout?.promoCode && !promoCodeError && !removingCode) && appliedPromocode }

      { showRequiredFieldsError && (
        <Alert status="error" className="mt2">
          Please enter your address before you enter a promo code.
        </Alert>
      ) }
    </div>
  );
};

const {
  bool
} = propTypes;

Promocode.propTypes = {
  showPromoCode: bool.isRequired
};

export default Promocode;
