import { combineReducers } from 'redux';
import * as reducers from './storefrontReducers';
import initThemeData from './initThemeReducer';
import themeData from './themeReducer';
import breakpoint from './breakpointReducer';
import activeModal from './modalReducer';
import localizationData from './localizationReducer';
import order from './orderReducer';
import httpError from './errorReducer';
import inventory from './inventoryReducer';
import checkout from './checkoutReducer';
import deliveryOptions from './deliveryOptionsReducer';
import toast from './toastReducer';

export default combineReducers({
  initThemeData,
  themeData,
  breakpoint,
  activeModal,
  localizationData,
  order,
  httpError,
  inventory,
  checkout,
  deliveryOptions,
  toast,
  ...reducers
});
