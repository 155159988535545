import React from 'react';
import { connect, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { Icon } from '@springforcreators/propel-ui';
import get from 'lodash/get';
import { setThemeData, setPreviewMode } from 'redux/actions';
import { bpProps } from 'utils/responsiveUtils';
import SocialMediaIcons from 'components/SocialMediaIcons';

import './LoginPage.scss';

const LoginPage = () => {
  const themeData = useSelector(state => state.themeData);
  const {
    brand,
    content,
    meta
  } = themeData || {};
  const {
    storeName
  } = meta || {};

  const { getStyles } = useSelector(state => ({ ...bpProps(state) })); // eslint-disable-line  no-unused-vars

  if (!get(themeData, 'styles')) return false;

  return (
    <div
      className="login-page"
      style={ { 'background-color': get(content, 'login.backgroundColor') || '#ffffff' } }
    >
      <div className="header">
        <img
          src={ get(content, 'login.headerLogo') }
          alt={ storeName || '' }
        />
      </div>
      <div
        className="login-wrapper"
        style={ { color: get(content, 'login.color') || '#000000' } }
      >
        <h3
          style={ { color: get(content, 'login.color') || '#000000' } }
        >
          { get(content, 'login.titleText') || 'Login'}
        </h3>
        <form className="login-form" method="GET" action="/">
          <input
            type="text"
            name="accessCode"
            placeholder="Password"
            required={ true }
            style={ {
              'background-color': `${get(content, 'login.color') || '#000000'} !important`
            } }
          />
          <button
            className="login-button"
            type="submit"
            style={ { color: get(content, 'login.color') || '#000000' } }
          >
            Login
            <Icon name="ArrowRight" size={ 16 } />
          </button>

        </form>
        { (get(content, 'footer.showSocialIcons') && get(brand, 'socialMedia'))
          && (
            <div className="socialIcons">
              <SocialMediaIcons />
            </div>
          )
        }
      </div>
      <div className="footer">
        <img
          src={ get(content, 'login.footerLogo') }
          alt={ storeName }
        />
      </div>
    </div>
  );
};

const {
  string,
  bool,
  shape,
  func
} = propTypes;

LoginPage.propTypes = {
  content: shape({
    header: shape({
      logo: string,
      showSocialIcons: bool
    })
  }).isRequired,
  setThemeData: func.isRequired,
  setPreviewMode: func.isRequired
};

export default connect(null, { setThemeData, setPreviewMode })(LoginPage);
