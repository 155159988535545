import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import { usePaymentRequest } from 'hooks';
import { getCartLineItems, cartItemsWithQty, redirectToCheckout } from 'utils/cartUtils';
import { getCookie } from 'utils/cookiesUtils';
import tracker from 'utils/tracking';

import './AfterpayUnavailableModal.scss';

const AfterpayUnavailableModal = () => {
  const {
    userCart,
    cartProducts,
    localizationData,
    inventory,
    ...state
  } = useSelector(s => s);

  const storeId = get(state, 'stores.slug');
  const cartItems = getCartLineItems(userCart, cartProducts, inventory);
  const cartItemsWithQuantity = cartItemsWithQty(cartItems);
  const affiliate = getCookie('affiliate');
  const { walletType } = usePaymentRequest();

  useEffect(() => {
    tracker.track(`checkout.delivery_options.failed`);
    const timeout = setTimeout(() => {
      redirectToCheckout('stripe', cartItemsWithQuantity, storeId, localizationData, affiliate, userCart, cartProducts, walletType, inventory, true);
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <h3>Sorry, we&apos;re having difficulty with AfterPay.</h3>
      <p>Please wait while we redirect you to a secure site to complete your purchase with Credit Card or PayPal.</p>
    </div>
  );
};

export default AfterpayUnavailableModal;
