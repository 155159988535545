import React from 'react';
import propTypes from 'prop-types';
import ThemeButton from 'components/ThemeButton';

import './FourOhFourContent.scss';

const FourOhFourContent = (props) => {
  const { primaryColor, showGoToHomepage } = props;

  return (
    <div className="fourohfour row content">
      <h1 style={ { color: primaryColor || '#000000' } }>404!</h1>
      <h4 className="mb2">Page not found</h4>
      <p className="mb2">Somehow you have come to a page that does not exist.</p>
      {showGoToHomepage && (
        <ThemeButton elemType="routerLink" link="/">Go to homepage</ThemeButton>
      )}
    </div>
  );
};

const { string, bool } = propTypes;

FourOhFourContent.propTypes = {
  primaryColor: string,
  showGoToHomepage: bool
};

FourOhFourContent.defaultProps = {
  primaryColor: null,
  showGoToHomepage: false
};

export default FourOhFourContent;
