
import React from 'react';
import propTypes from 'prop-types';
import { Icon } from '@springforcreators/propel-ui';
import tracker from 'utils/tracking';
import './ReportProduct.scss';

const ReportProduct = ({ listingSlug }) => {
  const reportLink = `https://teespring.com/policies/report/${listingSlug}`;

  return (
    <div className="report__wrapper mb2 mt2">
      <a
        href={ reportLink }
        target="_blank"
        rel="noreferrer"
        onClick={ () => tracker.track('product_page.report_product.clicked', { listingSlug })
        }
      >
        <Icon name="AlertCircle" size={ 14 } />
        {' '}
        Report this product
      </a>
    </div>
  );
};

const {
  string
} = propTypes;

ReportProduct.propTypes = {
  listingSlug: string.isRequired
};

export default ReportProduct;
