import './RefundPolicyButton.scss';
import React from 'react';
import { Button } from '@springforcreators/propel-ui';

export const RefundPolicy = () => {
  return (
    <Button
      className="refund-policy-button"
    >
      <div className="refund-policy-button-text">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 3V16L12 23L3 16V3C3 3 6 1 12 1C18 1 21 3 21 3Z" stroke="#01BA61" strokeWidth="1.5" strokeLinejoin="round" />
          <path d="M7 11L10 14L17 7" stroke="#01BA61" strokeWidth="1.5" strokeLinejoin="round" />
        </svg>
        &nbsp;
        <div>30 Day Free Return Policy.</div>
        {' '}
        <div className="refund-policy-link-text">Learn more</div>
      </div>
    </Button>
  );
};

export default RefundPolicy;
