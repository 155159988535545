import React from 'react';
import { InputField, Icon } from '@springforcreators/propel-ui';
import './SupportSearch.scss';

export const SupportSearch = () => {
  return (
    <div className="supportsearch">
      <Icon name="Search" size={ 18 } />
      <InputField
        placeholder="Search our topics"
      />
    </div>
  );
};

export default SupportSearch;
