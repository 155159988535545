import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectField } from '@springforcreators/propel-ui';
import {
  string, shape, func, arrayOf, number
} from 'prop-types';
import { bpProps } from 'utils/responsiveUtils';
import tracker from 'utils/tracking';

import './SizeOptions.scss';

const SizeOptions = ({
  listingSlug, sizeOptions, onChangeHandler
}) => {
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));
  const [selectedSize, setSelectedSize] = useState({ index: 0, id: sizeOptions[0]?.id });
  const selectFieldLabel = bpIsLT('mobileLg') ? 'Select size' : '';
  const selectFieldDefaultValue = sizeOptions[selectedSize.index]?.label;

  const onSizeOptionChange = async (newSizeOptionIndex) => {
    const newSize = sizeOptions[newSizeOptionIndex];
    tracker.track('size_options.quick_add_button.size.clicked', {
      listingSlug,
      sizeId: newSize.id,
      size: newSize.label
    });

    await onChangeHandler(newSize);
    setSelectedSize({ index: newSizeOptionIndex, id: sizeOptions[newSizeOptionIndex].id });
  };

  useEffect(async () => {
    if (selectedSize.id && selectedSize.id !== sizeOptions[selectedSize.index]?.id && sizeOptions.length) {
      let initialIndex = sizeOptions.findIndex(size => size.id === selectedSize.id);
      if (initialIndex === -1) {
        initialIndex = 0;
      }
      await onChangeHandler(sizeOptions[initialIndex]);
      setSelectedSize({ index: initialIndex, id: sizeOptions[initialIndex].id });
    }
  }, [sizeOptions]);

  if (!sizeOptions.length) return (<></>);
  return (
    <SelectField
      className="sizeoption"
      name="sizeoption"
      label={ selectFieldLabel }
      defaultValue={ selectFieldDefaultValue }
      items={ sizeOptions }
      onChange={ onSizeOptionChange }
    />
  );
};

SizeOptions.propTypes = {
  listingSlug: string.isRequired,
  sizeOptions: arrayOf(shape({ id: number.isRequired, label: string.isRequired }).isRequired).isRequired,
  onChangeHandler: func.isRequired
};

export default SizeOptions;
