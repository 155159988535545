const trackPartnersCookieInParams = partnersCookie => ({
  partner: (partnersCookie && partnersCookie.partner) || null
});

const initializeTiktokPixelHelper = () => {
  if (!document.querySelector('#tiktok-pixel')) {
    const script = document.createElement('script');
    script.src = `https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=${process.env.TIKTOK_TRACKING_PIXEL_ID}`;
    script.id = 'tiktok-pixel';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
};

const removeTiktokPixel = () => {
  const script = document.querySelector('#tiktok-pixel');
  if (script) {
    document.body.removeChild(script);
  }
};

export default {
  trackPartnersCookieInParams,
  initializeTiktokPixelHelper,
  removeTiktokPixel
};
