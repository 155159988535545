import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import ProductTile from 'components/ProductTile';
import Loader from 'components/Loader';
import ThemeWrapper from 'containers/ThemeWrapper';
import MainLayout from 'containers/MainLayout';
import { bpProps } from 'utils/responsiveUtils';
import tracker from 'utils/tracking';
import sanitizeHtml from 'sanitize-html';
import { connect } from 'react-redux';
import './AboutPage.scss';

export const AboutPage = (props) => {
  const {
    storeProducts,
    isFetching,
    content,
    location,
    storeData,
    getStyles
  } = props;

  const { aboutText, heroImage } = content.about;
  const { products } = storeProducts;
  let tiles = [];

  if (products && products.length) {
    tiles = products.slice(0, 4).map((product) => {
      return <ProductTile product={ product } key={ product.id } />;
    });
  }

  tracker.track('about_page.viewed');

  return (
    <MainLayout location={ location } storeData={ storeData }>
      <div className="about-page-wrapper">
        <div
          className="about-page"
          style={ {
            ...getStyles('about.bgStyles'),
            ...getStyles('about.textStyles')
          } }
        >
          <div className="about-page-text">
            <h1 style={ getStyles('about.titleStyles') }>About</h1>
            { aboutText && aboutText.map(p => (
              <Fragment key={ p.id }>
                { p.text && (
                  <p
                    key={ p.id }
                    dangerouslySetInnerHTML={ {
                      __html: sanitizeHtml(
                        p.text,
                        {
                          allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
                          allowedAttributes: { img: ['src', 'alt'] },
                          allowedSchemes: ['data', 'http', 'https']
                        }
                      )
                    } }
                  />
                ) }
                { p.list && (
                  <ol>
                    { p.list.map(li => <li key={ li.id }>{ li.text }</li>) }
                  </ol>
                ) }
              </Fragment>
            )) }
          </div>
          { heroImage && <img src={ heroImage } className="about-image" alt="" /> }
          <h2 style={ getStyles('about.titleStyles') }>Latest releases</h2>
        </div>
        <div className="about-page-tiles">
          <div className="product-tile-grid">
            { tiles }
            { isFetching && <Loader /> }
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const {
  array,
  bool,
  shape,
  string,
  arrayOf,
  object,
  number,
  func
} = propTypes;

AboutPage.propTypes = {
  isFetching: bool.isRequired,
  storeProducts: shape({
    products: array
  }).isRequired,
  content: shape({
    about: shape({
      heroImage: string,
      aboutText: arrayOf(shape({
        id: string,
        text: string,
        list: arrayOf(shape({ id: string, text: string }))
      }))
    })
  }).isRequired,
  storeData: shape({
    banner_url: string,
    collections: array,
    description: string,
    link_color: string,
    logo_height: number,
    logo_url: string,
    logo_width: number,
    name: string,
    social_identities: object,
    theme_color: string,
    url: string,
    use_logo: bool,
    location: object
  }).isRequired,
  location: shape({
    pathname: string,
    hash: string,
    search: string,
    state: object
  }).isRequired,
  getStyles: func.isRequired
};

const mapStateToProps = state => ({
  ...bpProps(state)
});

export default ThemeWrapper(connect(mapStateToProps)(AboutPage), ['content.about', 'styles.about']);
