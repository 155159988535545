const states = [
  {
    id: 'ak',
    name: 'ak',
    label: 'AK',
    fullName: 'Alaska'
  },
  {
    id: 'al',
    name: 'al',
    label: 'AL',
    fullName: 'Alabama'
  },
  {
    id: 'ar',
    name: 'ar',
    label: 'AR',
    fullName: 'Arkansas'
  },
  {
    id: 'az',
    name: 'az',
    label: 'AZ',
    fullName: 'Arizona'
  },
  {
    id: 'ca',
    name: 'ca',
    label: 'CA',
    fullName: 'California'
  },
  {
    id: 'co',
    name: 'co',
    label: 'CO',
    fullName: 'Colorado'
  },
  {
    id: 'ct',
    name: 'ct',
    label: 'CT',
    fullName: 'Connecticut'
  },
  {
    id: 'de',
    name: 'de',
    label: 'DE',
    fullName: 'Delaware'
  },
  {
    id: 'dc',
    name: 'dc',
    label: 'DC',
    fullName: 'District Of Columbia'
  },
  {
    id: 'fl',
    name: 'fl',
    label: 'FL',
    fullName: 'Florida'
  },
  {
    id: 'ga',
    name: 'ga',
    label: 'GA',
    fullName: 'Georgia'
  },
  {
    id: 'hi',
    name: 'hi',
    label: 'HI',
    fullName: 'Hawaii'
  },
  {
    id: 'ia',
    name: 'ia',
    label: 'IA',
    fullName: 'Iowa'
  },
  {
    id: 'id',
    name: 'id',
    label: 'ID',
    fullName: 'Idaho'
  },
  {
    id: 'il',
    name: 'il',
    label: 'IL',
    fullName: 'Illinois'
  },
  {
    id: 'in',
    name: 'in',
    label: 'IN',
    fullName: 'Indiana'
  },
  {
    id: 'ks',
    name: 'ks',
    label: 'KS',
    fullName: 'Kansas'
  },
  {
    id: 'ky',
    name: 'ky',
    label: 'KY',
    fullName: 'Kentucky'
  },
  {
    id: 'la',
    name: 'la',
    label: 'LA',
    fullName: 'Louisiana'
  },
  {
    id: 'ma',
    name: 'ma',
    label: 'MA',
    fullName: 'Massachusetts'
  },
  {
    id: 'md',
    name: 'md',
    label: 'MD',
    fullName: 'Maryland'
  },
  {
    id: 'me',
    name: 'me',
    label: 'ME',
    fullName: 'Maine'
  },
  {
    id: 'mi',
    name: 'mi',
    label: 'MI',
    fullName: 'Michigan'
  },
  {
    id: 'mn',
    name: 'mn',
    label: 'MN',
    fullName: 'Minnesota'
  },
  {
    id: 'mo',
    name: 'mo',
    label: 'MO',
    fullName: 'Missouri'
  },
  {
    id: 'ms',
    name: 'ms',
    label: 'MS',
    fullName: 'Mississippi'
  },
  {
    id: 'mt',
    name: 'mt',
    label: 'MT',
    fullName: 'Montana'
  },
  {
    id: 'nc',
    name: 'nc',
    label: 'NC',
    fullName: 'North Carolina'
  },
  {
    id: 'nd',
    name: 'nd',
    label: 'ND',
    fullName: 'North Dakota'
  },
  {
    id: 'ne',
    name: 'ne',
    label: 'NE',
    fullName: 'Nebraska'
  },
  {
    id: 'nh',
    name: 'nh',
    label: 'NH',
    fullName: 'New Hampshire'
  },
  {
    id: 'nj',
    name: 'nj',
    label: 'NJ',
    fullName: 'New Jersey'
  },
  {
    id: 'nm',
    name: 'nm',
    label: 'NM',
    fullName: 'New Mexico'
  },
  {
    id: 'nv',
    name: 'nv',
    label: 'NV',
    fullName: 'Nevada'
  },
  {
    id: 'ny',
    name: 'ny',
    label: 'NY',
    fullName: 'New York'
  },
  {
    id: 'oh',
    name: 'oh',
    label: 'OH',
    fullName: 'Ohio'
  },
  {
    id: 'ok',
    name: 'ok',
    label: 'OK',
    fullName: 'Oklahoma'
  },
  {
    id: 'or',
    name: 'or',
    label: 'OR',
    fullName: 'Oregon'
  },
  {
    id: 'pa',
    name: 'pa',
    label: 'PA',
    fullName: 'Pennsylvania'
  },
  {
    id: 'ri',
    name: 'ri',
    label: 'RI',
    fullName: 'Rhode Island'
  },
  {
    id: 'sc',
    name: 'sc',
    label: 'SC',
    fullName: 'South Carolina'
  },
  {
    id: 'sd',
    name: 'sd',
    label: 'SD',
    fullName: 'South Dakota'
  },
  {
    id: 'tn',
    name: 'tn',
    label: 'TN',
    fullName: 'Tennessee'
  },
  {
    id: 'tx',
    name: 'tx',
    label: 'TX',
    fullName: 'Texas'
  },
  {
    id: 'ut',
    name: 'ut',
    label: 'UT',
    fullName: 'Utah'
  },
  {
    id: 'va',
    name: 'va',
    label: 'VA',
    fullName: 'Virginia'
  },
  {
    id: 'vt',
    name: 'vt',
    label: 'VT',
    fullName: 'Vermont'
  },
  {
    id: 'wa',
    name: 'wa',
    label: 'WA',
    fullName: 'Washington'
  },
  {
    id: 'wi',
    name: 'wi',
    label: 'WI',
    fullName: 'Wisconsin'
  },
  {
    id: 'wv',
    name: 'wv',
    label: 'WV',
    fullName: 'West Virginia'
  },
  {
    id: 'wy',
    name: 'wy',
    label: 'WY',
    fullName: 'Wyoming'
  },
  {
    id: 'aa',
    name: 'aa',
    label: 'AA',
    fullName: 'AA'
  },
  {
    id: 'ap',
    name: 'ap',
    label: 'AP',
    fullName: 'AP'
  },
  {
    id: 'ae',
    name: 'ae',
    label: 'AE',
    fullName: 'AE'
  },
  {
    id: 'gu',
    name: 'gu',
    label: 'GU',
    fullName: 'Guam'
  },
  {
    id: 'as',
    name: 'as',
    label: 'AS',
    fullName: 'American Samoa'
  },
  {
    id: 'mh',
    name: 'mh',
    label: 'MH',
    fullName: 'Marshall Islands'
  },
  {
    id: 'pw',
    name: 'pw',
    label: 'PW',
    fullName: 'Palau'
  },
  {
    id: 'pr',
    name: 'pr',
    label: 'PR',
    fullName: 'Puerto Rico'
  },
  {
    id: 'vi',
    name: 'vi',
    label: 'VI',
    fullName: 'Virgin Islands'
  },
  {
    id: 'mp',
    name: 'mp',
    label: 'MP',
    fullName: 'Northern Mariana Islands'
  },
  {
    id: 'fm',
    name: 'fm',
    label: 'FM',
    fullName: 'Federated States Of Micronesia'
  }
];

states.sort((a, b) => {
  const nameA = a.label.toUpperCase();
  const nameB = b.label.toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
});

export default states;
