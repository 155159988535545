import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { NFT_PRODUCT_ID } from '../constants';

export const generateBaseSku = (data) => {
  const {
    itemGroupId,
    colorId,
    sizeId
  } = data;
  const sku = [itemGroupId, colorId, sizeId].join('_');
  return sku;
};

export const generateSkuWithQuantity = (data) => {
  const { quantity } = data;
  const baseSku = generateBaseSku(data);
  return `${baseSku}_${quantity}`;
};

/**
 * Returns the price value that matches the passed sizeId
 * @param {Object} variant - Product listing variant
 * @param {Number} sizeId - Id that represents the currently active size
 * @returns {string} - Returns the price value associated with the passed sizeId
 */
export const getPriceFromSize = (variant, sizeId) => {
  const sizes = get(variant, 'availableSizesWithId');
  const activeSize = sizes && sizes.find(size => size.id === sizeId);
  return get(activeSize, 'price');
};

/**
 * Returns the values within the sku
 * @param {string} baseSku - Sku from the current selected listing
 * @returns {array} - Returns an array of values within the sku
 */
export const getSkuValues = (baseSku) => {
  const sku = {};
  if (!isEmpty(baseSku)) {
    [sku.campaignRootId, sku.productId, sku.colorId, sku.sizeId, sku.quantity] = baseSku.split('_');
  }
  return sku;
};

export const isDigitalProduct = item => get(item, 'selectedSize.label', '') === 'Digital';

export const isNFTProduct = product => get(product, 'variantData.productId') === NFT_PRODUCT_ID;
