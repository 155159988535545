import React from 'react';
import { useSelector } from 'react-redux';
import { node } from 'prop-types';
import { ToasterProvider } from '@springforcreators/propel-ui';
import NotificationToaster from './NotificationToaster';
import './Toaster.scss';

const Toaster = ({ children }) => {
  const { notifications } = useSelector(state => state?.toast);

  return (
    <div className="bs__notifications">
      <ToasterProvider defaultPosition="top-right">
        <NotificationToaster notifications={ notifications } />
        {children}
      </ToasterProvider>
    </div>
  );
};

Toaster.propTypes = {
  children: node.isRequired
};

export default Toaster;
