import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';

function RedirectWithPreprocess({ redirectPath, beforeRedirect, ...rest }) {
  let updatedProps = {};
  if (beforeRedirect) {
    const modifiedProps = beforeRedirect(rest) || {};
    updatedProps = Object.assign(rest, updatedProps, modifiedProps);
  }

  return (
    <Route
      { ...updatedProps }
      render={ (props) => {
        return (
          <Redirect
            { ...props }
            to={ {
              pathname: redirectPath,
              state: {
                params: rest.computedMatch.params
              }
            } }
          />
        );
      }
      }
    />
  );
}

const {
  func,
  string
} = propTypes;

RedirectWithPreprocess.defaultProps = {
  beforeRedirect: null
};

RedirectWithPreprocess.propTypes = {
  beforeRedirect: func,
  redirectPath: string.isRequired
};

export default RedirectWithPreprocess;
