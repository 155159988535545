import { arrayOf, shape } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveModal } from 'redux/actions';
import tracker from 'utils/tracking';
import CheckoutCartInfo from '../CheckoutCartInfo';
import './CheckoutCartInfoModal.scss';

const CheckoutCartInfoModal = (props) => {
  const { cartLineItems } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    // Scroll to bottom of modal scroll container to keep promocode CTA in view
    const modalScrollbox = document.querySelector('.mobile-cart-summary-modal .pr_modal__inner');
    modalScrollbox.scrollTop = modalScrollbox.scrollHeight;
    tracker.track('checkout.cart_summary.viewed');
  }, []);

  return (
    <div className="checkoutcartinfo__modal">
      <div className="checkoutcartinfo__modal__toggle">
        <span className="checkoutcartinfo__modal__cartlineitems">{ `Your cart (${cartLineItems.length})` }</span>
        <button
          type="button"
          className="btn"
          onClick={ () => dispatch(setActiveModal(null)) }
        >
          Close
        </button>
      </div>

      <CheckoutCartInfo { ...props } />
    </div>
  );
};

CheckoutCartInfoModal.propTypes = {
  cartLineItems: arrayOf(shape({})).isRequired
};

export default CheckoutCartInfoModal;
