import React, { useEffect, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import {
  SelectField,
  InputField,
  AddressSuggestionModal,
  useAddressValidation,
  ModalConductor,
  Alert,
  Button
} from '@springforcreators/propel-ui';
import {
  fetchDeliveryOptions,
  updateCheckout,
  setActiveModal,
  setUpdatingCheckout
} from 'redux/actions';
import 'react-phone-number-input/style.css';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import debounce from 'lodash/debounce';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import { Elements } from '@stripe/react-stripe-js';
import { cartMatchesCheckout, getCountryCode, getStateCode } from 'utils/checkoutUtils';
import { bpProps } from 'utils/responsiveUtils';
import states from 'utils/statesList';
import stripePromise from 'utils/stripeUtils';
import countries from 'utils/allCountriesList';
import propTypes from 'prop-types';
import AfterpayUnavailableModal from 'components/AfterpayUnavailableModal';
import tracker from 'utils/tracking';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './CheckoutDetails.scss';
import CheckoutDetailsAddress from './CheckoutDetailsAddress';
import LegacyCheckoutButton from './LegacyCheckoutButton';

/**
 * Maps the customer data object to the flat value object consumed by react-hook-form
 * @param {Object} customer - Customer data object
 * @returns {Object} Returns a values object
 */
const mapCustomerDataToFields = customer => ({
  firstname: customer?.firstName || '',
  lastname: customer?.lastName || '',
  email: customer?.email || '',
  address: customer?.address?.address1 || '',
  address2: customer?.address?.address2 || '',
  city: customer?.address?.city || '',
  zip: customer?.address?.zip || '',
  state: customer?.address?.state || '',
  country: customer?.address?.country || 'United States',
  phone: customer?.phone || ''
});

const mapValuesToAddr = values => ({
  address: values?.address,
  address2: values?.address2,
  city: values?.city,
  state: states.find(state => state.label === values?.state || state.name === values?.state)?.fullName,
  zip: values?.zip,
  countryAbbrv: countries.find(country => country.label === values?.country)?.name
});

const getStateField = (state, field = 'label') => {
  if (!state) return state;
  return states.find(s => s.label === state || s.fullName === state)[field];
};

const schema = yup.object({
  firstname: yup.string().required('Please enter your first name'),
  lastname: yup.string().required('Please enter your last name'),
  email: yup.string().email('Please enter a valid email').required('Please enter a valid email'),
  address: yup.string().required('Please enter your street address'),
  address2: yup.string(),
  city: yup.string().required('Please enter your city'),
  zip: yup.string().required('Please enter your zipcode'),
  state: yup.string().required('Please select your state'),
  country: yup
    .string()
    .required()
    .oneOf(
      ['United States'],
      'We noticed you\'re outside of the United States. A few more steps are needed for international delivery. Click the button below.'
    ),
  phone: yup
    .string()
    .required('Please enter your phone number')
    .nullable()
    .test(
      'isPossiblePhoneNumber',
      'Please enter a valid phone number',
      value => isPossiblePhoneNumber(value)
    )
}).required();

const CheckoutDetails = ({
  activeSteps,
  customer,
  setShowAddressMessage,
  setShowPromoCode,
  addressHasBeenEntered,
  setAddressHasBeenEntered
}) => {
  const {
    setValue,
    handleSubmit,
    register,
    formState,
    getValues,
    control,
    trigger
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: mapCustomerDataToFields(customer)
  });

  const { setClass } = useSelector(state => ({ ...bpProps(state) }));

  const [countryIsUSA, setCountryIsUSA] = useState(getValues('country') === 'United States');

  const {
    checkout, userCart, deliveryOptions, stores
  } = useSelector(state => state);
  const activeModal = useSelector(state => state.activeModal);
  const {
    onAddressSuggestionConfirmation,
    performAddressValidation,
    addressSuggestions,
    validationError
  } = useAddressValidation();
  const { checkoutId } = useParams();

  const dispatch = useDispatch();
  const dispatchSetActiveModal = data => dispatch(setActiveModal(data));

  if (has(userCart, 'region')) delete userCart.region;

  const getDeliveryOptions = async (stateName, countryName) => {
    if (!deliveryOptions.isFetching) {
      await dispatch(fetchDeliveryOptions(checkout.id ?? checkoutId, stores.slug, stateName, countryName, setAddressHasBeenEntered));
    }
  };

  const validateAddress = async (values) => {
    await dispatch(setUpdatingCheckout(true));
    const validationResults = await performAddressValidation(mapValuesToAddr(values));

    // Address was confirmed valid & has not changed since the last request
    if (!validationResults) {
      return true;
    }

    const { error, isValid, status } = validationResults;
    // In the event of a smarty streets error, force valid so checkout is not blocked
    return { isValid: error ? true : isValid, status };
  };

  useEffect(async () => {
    if (validationError) {
      tracker.track('checkout.delivery_address_validation_error.triggered');
      await dispatch(setUpdatingCheckout(false));
    }
  }, [validationError]);

  useEffect(() => {
    if (addressSuggestions.length > 0) {
      tracker.track('checkout.delivery_address_validation.triggered');
      dispatchSetActiveModal('address-suggestion-modal');
    }
  }, [addressSuggestions]);

  const cartDoesNotMatchCheckout = !cartMatchesCheckout(userCart, checkout);

  useEffect(() => {
    const stateName = customer?.address?.state;
    const countryName = customer?.address?.country;
    const zip = customer?.address?.zip;

    if (stateName && countryName && zip) {
      setShowAddressMessage(false);
      setShowPromoCode(true);
      getDeliveryOptions(stateName, countryName);
    }

    // If we load an existing address from the checkout state, mark as entered
    if (
      customer?.address
      && Object.keys(customer?.address)
        .filter(key => key !== 'address2')
        .every(key => customer?.address[key].length > 0)
    ) {
      setAddressHasBeenEntered(true);
      setCountryIsUSA(countryName === 'United States');
    }
  }, [customer, cartDoesNotMatchCheckout]);

  const onSubmit = async (values, event, forceValid = false) => {
    const isValid = await trigger();

    if (forceValid || isValid) {
      const address = forceValid ? { isValid: true } : await validateAddress(values, 'submit');

      const countryName = values?.country;
      const state = states.find(st => st.label === values?.state)?.label;

      const customerDetails = {
        firstName: values?.firstname,
        lastName: values?.lastname,
        email: values?.email,
        phone: values?.phone,
        address: {
          address1: values?.address,
          address2: values?.address2,
          city: values?.city,
          state,
          zip: values?.zip,
          country: countryName,
          stateCode: getStateCode(countryName, state),
          countryCode: getCountryCode(countryName)
        }
      };
      const addressHasNotChanged = isEqual({ ...customerDetails, id: checkout?.customer?.id }, checkout?.customer);

      if (addressHasNotChanged) setAddressHasBeenEntered(true);

      if ((address.isValid || forceValid) && !addressHasNotChanged) {
        dispatch(updateCheckout({
          customer: customerDetails,
          state: 'pending'
        }, false));
      }

      if (!address.status === 'invalidWithoutSuggestion' || addressHasNotChanged) await dispatch(setUpdatingCheckout(false));
    }
  };

  const onEmailBlurHandler = () => {
    const values = getValues();

    // Removes extra white space onBlur for email
    setValue('email', values.email.trim(), {
      shouldValidate: true
    });
  };

  const onConfirmHandler = (selectedAddress, originalSelected, isCancelled) => {
    if (isCancelled) {
      tracker.track(`checkout_cancel_address_suggestion.clicked`);
      dispatch(setUpdatingCheckout(false));
      return;
    } else {
      tracker.track(`checkout_${originalSelected ? 'original' : 'suggested'}_address.clicked`);
    }

    onAddressSuggestionConfirmation(selectedAddress);
    dispatchSetActiveModal({ id: null });

    // Loop through each form value and set according to the selected Address
    Object.keys(selectedAddress).forEach((key) => {
      if (key === 'state') {
        const selectedState = states.find(s => s.fullName === selectedAddress[key]);
        // Set the value of the state dropdown to the correct state
        setValue(key, selectedState?.label, { shouldValidate: true });
      } else {
        setValue(key, selectedAddress[key], { shouldValidate: true });
      }
    });

    onSubmit(getValues(), null, true);
  };

  const debouncedOnConfirm = debounce(onConfirmHandler, 300, { leading: true, trailing: false });

  const hasValue = value => typeof value === 'string' && value.length > 0;
  const phoneNumberValidator = value => (hasValue(value) && isPossiblePhoneNumber(value)) || 'Please enter a valid phone number';

  return (
    <Elements stripe={ stripePromise }>
      <ModalConductor
        activeModal={ activeModal }
        setActiveModal={ dispatchSetActiveModal }
        modals={ [
          {
            id: 'address-suggestion-modal',
            header: 'Verify your address',
            onClose: () => debouncedOnConfirm(mapValuesToAddr(getValues()), true, true),
            node: (
              <AddressSuggestionModal
                // Pass the form state in as the original address
                originalAddress={ mapValuesToAddr(getValues()) }
                addressSuggestions={ addressSuggestions }
                onConfirm={ debouncedOnConfirm }
              />
            )
          },
          {
            id: 'afterpay-unavailable-modal',
            className: 'afterpay-unavailable-modal',
            node: (
              <AfterpayUnavailableModal />
            )
          }
        ] }
      />

      <div className={ `checkoutsection ${activeSteps?.includes('details') ? 'is-active' : ''}` }>
        <div className="checkoutsection__heading">
          <span>1</span>
          <h3>Customer details</h3>
        </div>

        <div className="checkoutsection__inner">
          { addressHasBeenEntered
            ? <CheckoutDetailsAddress setAddressHasBeenEntered={ setAddressHasBeenEntered } />
            : (
              <form
                className={ `checkout-details__form grid ${checkout?.updatingCheckout ? 'is-disabled' : ''}` }
                onSubmit={ handleSubmit(onSubmit) }
              >
                <div className="col-12">
                  <InputField
                    name="email"
                    label="Email address"
                    defaultValue={ getValues('email') }
                    type="text"
                    required={ true }
                    register={ register }
                    onBlur={ onEmailBlurHandler }
                    error={ formState?.errors?.email?.message }
                    autoComplete="email"
                  />
                </div>

                <div className={ setClass({ default: 'col-6', tabletMd: 'col-12' }) }>
                  <InputField
                    name="firstname"
                    label="First name"
                    defaultValue={ getValues('firstname') }
                    type="text"
                    required={ true }
                    register={ register }
                    error={ formState?.errors?.firstname?.message }
                    autoComplete="given-name"
                  />
                </div>

                <div className={ setClass({ default: 'col-6', tabletMd: 'col-12' }) }>
                  <InputField
                    name="lastname"
                    label="Last name"
                    defaultValue={ getValues('lastname') }
                    type="text"
                    required={ true }
                    register={ register }
                    error={ formState?.errors?.lastname?.message }
                    autoComplete="family-name"
                  />
                </div>

                { validationError && (
                  <div className="col-12 mb2">
                    <Alert status="error">
                      { validationError }
                    </Alert>
                  </div>
                ) }

                <div className="col-12">
                  <InputField
                    name="address"
                    label="Street address"
                    defaultValue={ getValues('address') }
                    type="text"
                    required={ true }
                    register={ register }
                    error={ formState?.errors?.address?.message }
                    autoComplete="address-line1"
                  />
                </div>

                <div className="col-12">
                  <InputField
                    name="address2"
                    label="Apt / suite / building etc (Optional)"
                    defaultValue={ getValues('address2') }
                    type="text"
                    register={ register }
                    error={ formState?.errors?.address2?.message }
                    autoComplete="address-line2"
                  />
                </div>

                <div className={ setClass({ default: 'col-6', tabletMd: 'col-12' }) }>
                  <InputField
                    name="city"
                    label="City / Town"
                    defaultValue={ getValues('city') }
                    type="text"
                    required={ true }
                    register={ register }
                    error={ formState?.errors?.city?.message }
                    autoComplete="address-level2"
                  />
                </div>

                <div className={ setClass({ default: 'col-6', tabletMd: 'col-12' }) }>
                  <SelectField
                    name="state"
                    label="Select state"
                    defaultValue={ getStateField(getValues('state')) }
                    items={ states }
                    type="text"
                    required={ true }
                    register={ register }
                    error={ formState?.errors?.state?.message }
                    autoComplete="address-level1"
                  />
                </div>

                <div className={ setClass({ default: 'col-6', tabletMd: 'col-12' }) }>
                  <InputField
                    name="zip"
                    label="Zip code"
                    defaultValue={ getValues('zip') }
                    type="text"
                    required={ true }
                    register={ register }
                    error={ formState?.errors?.zip?.message }
                    autoComplete="postal-code"
                  />
                </div>

                <div className={ setClass({ default: 'col-6', tabletMd: 'col-12' }) }>
                  <SelectField
                    name="country"
                    label="Select country"
                    defaultValue={ getValues('country') }
                    onChange={ (index, event) => {
                      setCountryIsUSA(event.label === 'United States');
                    } }
                    items={ countries }
                    type="text"
                    required={ true }
                    register={ register }
                    error={ formState?.errors?.country?.message }
                    autoComplete="country"
                  />

                  { !countryIsUSA && <LegacyCheckoutButton /> }
                </div>

                <div className="col-12 pr_inputgroup">
                  <div className="form__phoneinput">
                    {/* eslint-disable-next-line */}
                    <label className="pr_inputfield__label is-required" htmlFor="phone">
                      Phone number
                    </label>
                    <PhoneInputWithCountry
                      className={ `${formState?.errors?.phone ? 'has-error' : ''}` }
                      name="phone"
                      defaultCountry="US"
                      control={ control }
                      rules={ { validate: phoneNumberValidator } }
                      international={ true }
                      withCountryCallingCode={ true }
                      smartCaret={ true }
                    />
                  </div>
                  { formState?.errors?.phone && <span className="pr_form__error">{ formState?.errors?.phone?.message }</span> }
                </div>

                <Button
                  className="mb1"
                  type="submit"
                  loading={ checkout?.updatingCheckout }
                  fullWidth={ true }
                  onClick={ () => {
                    const errorMessages = Object.keys(formState?.errors)
                      .map(key => formState?.errors[key]?.message);
                    tracker.track('checkout.delivery_address.clicked', { errorMessages });
                  } }
                >
                  Deliver to this address
                </Button>
              </form>
            )
          }
        </div>
      </div>
    </Elements>
  );
};

const {
  func,
  string,
  shape,
  bool,
  arrayOf
} = propTypes;
CheckoutDetails.propTypes = {
  activeSteps: arrayOf(string).isRequired,
  customer: shape({
    firstName: string,
    lastName: string,
    email: string,
    address: shape({
      address1: string,
      address2: string,
      city: string,
      state: string,
      zip: string,
      country: string
    })
  }),
  setShowAddressMessage: func.isRequired,
  setShowPromoCode: func.isRequired,
  addressHasBeenEntered: bool.isRequired,
  setAddressHasBeenEntered: func.isRequired
};

CheckoutDetails.defaultProps = {
  customer: {}
};

export default memo(CheckoutDetails);
