import React from 'react';
import propTypes from 'prop-types';
import './DropHeading.scss';

export const DropHeading = (props) => {
  const { title, body } = props;

  return (
    <div className="heading__block">
      <h3>{title}</h3>
      <div className="heading__content">
        {body}
      </div>
    </div>
  );
};

DropHeading.propTypes = {
  title: propTypes.string,
  body: propTypes.string
};

DropHeading.defaultProps = {
  title: '',
  body: ''
};

export default DropHeading;
