import React, { useState, useEffect } from 'react';
import './PromotionalBanner.scss';
import propTypes from 'prop-types';
import PromotionalBannerItem from './PromotionalBannerItem';

const PromotionalBanner = ({ promoCodeList }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = () => {
      setIndex((prevIndex) => {
        if (prevIndex === promoCodeList.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    };
    setInterval(timer, 7000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="promocode_container">
      <PromotionalBannerItem promoCode={ promoCodeList[index] } />
    </div>
  );
};

const { array } = propTypes;

PromotionalBanner.propTypes = {
  promoCodeList: array.isRequired
};

export default PromotionalBanner;
