import React, { useState } from 'react';
import {
  InputField,
  Button,
  Alert
} from '@springforcreators/propel-ui';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import fetch from 'cross-fetch';
import { bpProps } from 'utils/responsiveUtils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { API_HOST } from '../../../constants';
import './RecoverOrder.scss';

const schema = yup.object({
  email: yup.string().email('Please enter a valid email').required('Please enter a valid email')
}).required();

export const RecoverOrderPage = (props) => {
  const { handleSubmit, register, formState } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const { bpIsGT, storeId } = props;

  async function onSubmit(values) {
    setLoading(true);
    const res = await fetch(`${API_HOST}/v1/orders/recover?email=${values.email}&slug=${storeId}`, {
      method: 'POST'
    });
    const data = await res.json();

    if (data.success) {
      setConfirmationMessage({
        status: 'success',
        message: `<strong>Success!</strong> We have sent an email to ${values.email} detailing your orders during the past 60 days.`
      });
    } else {
      setConfirmationMessage({
        status: 'error',
        message: data.error
      });
    }

    setLoading(false);
  }

  const crumbs = [
    { id: 'support', label: 'Support', path: '/support' }
  ];

  if (bpIsGT('mobileLg')) {
    crumbs.push({
      id: 'recover-order-number',
      label: 'Recover order number',
      path: '/support/track/recover'
    });
  }

  return (
    <div className="recover-page">
      {confirmationMessage && (
        <Alert status={ confirmationMessage.status }>
          <div dangerouslySetInnerHTML={ { __html: confirmationMessage.message } } />
        </Alert>
      )}
      <form className="recover-page__content" onSubmit={ handleSubmit(onSubmit) }>
        <h2 className="mb1">Recover order number</h2>
        <p className="mb3">Enter the email address you used to place your order to recover all orders placed in the last 60 days.</p>
        <div className="mb3">
          <InputField
            type="email"
            name="email"
            label="Email Address"
            required={ true }
            register={ register }
            error={ formState?.errors?.email?.message }
          />
        </div>
        <Button
          className="mb1 btn--icon"
          type="submit"
          icon="ArrowRight"
          loading={ loading }
        >
          Recover orders
        </Button>
        <p className="text-left">
          Having problems recovering your order?&nbsp;
          <Link to="/contact">Contact Us</Link>
        </p>
      </form>
    </div>
  );
};

const { func, string } = propTypes;

RecoverOrderPage.propTypes = {
  storeId: string.isRequired,
  bpIsGT: func.isRequired
};

const mapStateToProps = state => ({
  ...bpProps(state)
});

export default connect(mapStateToProps)(RecoverOrderPage);
