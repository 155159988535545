import React, { Component } from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  shape,
  string
} from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { listingSlugFromUrl } from 'utils/generalUtils';
import { generateSearchResults } from 'utils/searchUtils';
import SearchInput from 'components/SearchInput';
import Loader from 'components/Loader';
import ProductTile from 'components/ProductTile';
import Pagination from 'components/Pagination';
import ThemeWrapper from 'containers/ThemeWrapper';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { bpProps } from 'utils/responsiveUtils';
import tracker from 'utils/tracking';
import withTracker from 'routes/withTracker';
import './SearchPage.scss';

class SearchPage extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.basePath = location.pathname;
    this.lazyLoadStarted = false;

    // Initialization for fuze fuzzy search (not related to teespring "fuze")
    this.fuseOptions = {
      shouldSort: true,
      includeScore: false,
      tokenize: true,
      maxPatternLength: 12,
      minMatchCharLength: 3,
      keys: [
        {
          name: 'name',
          weight: 6
        }, {
          name: 'url',
          getFn: product => listingSlugFromUrl(product?.url || ''),
          weight: 5
        }, {
          name: 'productName',
          weight: 4
        }, {
          name: 'collections.name',
          weight: 3
        }, {
          name: 'collections.slug',
          weight: 2
        }, {
          name: 'productGroupName',
          weight: 1
        }],
      ...get(props, 'meta.searchOptions')
    };

    const searchTerm = this.getSearchTermFromUrl();

    this.state = {
      products: [],
      searchTerm,
      searchResultActive: searchTerm && searchTerm.length > 0,
      dataLoaded: false
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { storeProducts } = nextProps;
    const { products } = storeProducts;
    window.products = prevState.products;
    if (products.length !== prevState.products) {
      return {
        products,
        dataLoaded: true
      };
    }
    return null;
  }

  componentDidMount() {
    document.querySelector('.search-box').focus();
    tracker.track('search_page.viewed');
  }

  componentDidUpdate() {
    const { storeProducts } = this.props;

    // Loads all pages of products up to a set limit
    if (storeProducts.products.length && !this.lazyLoadStarted) {
      this.lazyLoadStarted = true;
      this.loadAllProducts();
    }
  }

  /**
   * Returns the search term at key 'searchterm' from the URL params
   * @return {string} Search string
   */
  getSearchTermFromUrl() {
    const { location } = this.props;
    const query = location.search;
    const queryParams = queryString.parse(query);
    const searchTerm = queryParams.searchterm ? queryParams.searchterm : '';
    return searchTerm;
  }

  /**
   * Updates the search term in state and in the URL to make the page bookmarkable
   * @param  {string} term The search term
   */
  updateSearchTerm(term) {
    const search = term.length ? `?searchterm=${term}` : '';
    const { history } = this.props;

    history.replace({
      pathname: this.basePath,
      search
    });
  }

  /**
   * Responds to the onChange event for the search field. Updates state and the URL.
   * @param  {Object} event DOM event object
   */
  handleSearchChange(event) {
    const { value } = event.target;
    this.setState({
      searchTerm: value
    }, () => {
      this.updateSearchTerm(value);
    });
  }

  /**
   * Responds to the onSubmit event for the search field.
   * @param  {string} searchTerm The term that the user input
   */
  handleSearchSubmit(searchTerm) {
    if (searchTerm) this.handleSearchChange({ target: { value: searchTerm } });
    this.setState({ searchResultActive: true });
  }

  handleClose(event) {
    event.preventDefault();

    tracker.track('search_page.close_button.clicked');

    const { location, history } = this.props;
    // 'default' is attempted future proofing for v6 of the router
    if (!location?.key || location.key === 'default') {
      // We are at the inital loaded page, or something is wonky, go home
      history.push('/');
    } else {
      // Navigate back
      history.go(-1);
    }
  }

  /**
   * Loads all pages up to a set maximum
   * @param  {number} pagesLoaded recurse depth control
   */
  loadAllProducts(pagesLoaded = 0) {
    const { storeProducts, productCacheLoaded } = this.props;
    if (productCacheLoaded) {
      return;
    }
    const { next } = storeProducts;
    const maxPages = 50;
    const morePages = Boolean(next) && pagesLoaded < maxPages;
    if (morePages) {
      this.loadNextPage().then(() => {
        this.loadAllProducts(pagesLoaded + 1);
      });
    }
  }

  /**
   * Loads next page of items
   * @return {Promise} Returns the request promise
   */
  loadNextPage() {
    const {
      storeProducts, fetchProducts
    } = this.props;

    const { page } = storeProducts;
    return fetchProducts(page + 1);
  }

  render() {
    const {
      isFetching,
      meta,
      getStyles,
      storeData,
      localizationData
    } = this.props;

    const {
      products,
      dataLoaded,
      searchTerm,
      searchResultActive
    } = this.state;
    const searchClasses = searchResultActive ? 'search-result-active' : '';
    let tiles = [];
    let searchResults = [];
    let autoCompleteItems = [];

    if (dataLoaded) {
      const offset = 2;
      const rawSearchResults = generateSearchResults(products, this.fuseOptions, searchTerm, offset);

      searchResults = rawSearchResults.map(x => x.item);

      tiles = searchResults.map((product, i) => {
        return (
          <ProductTile
            product={ product }
            buttonText={ product.price }
            key={ product.id }
            list="Search"
            position={ i }
          />
        );
      });

      const searchKeys = get(meta, 'searchOptions.keys');

      autoCompleteItems = searchResults.map((product) => {
        return {
          label: searchKeys && searchKeys.length > 0 ?
            searchKeys.map(key => product[key]).join(' - ') :
            `${product.name} - ${product.productName}`,
          value: product.name,
          id: product.id
        };
      });
    }

    return (
      <div
        ref={ (el) => { this.container = el; } }
        className={ `search-content ${searchClasses}` }
        style={ {
          ...getStyles('search.bgStyles'),
          ...getStyles('search.textStyles')
        } }
      >
        <div className="search-controls">
          <Link
            to="/"
            className="search-close"
            style={ getStyles('search.closeBtnStyles') }
            onClick={ this.handleClose }
          >
            Close
          </Link>
          <div className="search-heading">
            <h2 style={ getStyles('search.titleStyles') }>Search</h2>
            <p>Type keywords to search</p>
          </div>
          <div className="search-filters">
            <SearchInput
              items={ autoCompleteItems }
              onSearchChange={ this.handleSearchChange }
              onSearchSubmit={ this.handleSearchSubmit }
              searchValue={ searchTerm }
              searchResultActive={ searchResultActive }
            />
          </div>
        </div>
        <div className="search-results">
          <div className="product-tile-grid">
            { tiles.length === 0 &&
              !isFetching &&
              (
                <p>
                  No results for &quot;
                  {searchTerm}
                  &quot;
                </p>
              )
            }
            { searchResultActive && (
              <Pagination
                items={ tiles }
                anchor=".search-content"
                scrollContainer={ this.container }
                products={ searchResults }
                list="Search"
                storeName={ get(storeData, 'name') }
                sellerId={ get(storeData, 'sellerId') }
                currency={ get(localizationData, 'buyer_currency') }
              />
            ) }
            { isFetching && <Loader /> }
          </div>
          <div className="product-tile-grid-actions" />
        </div>
        <div className="search-bottom-spacer" style={ { backgroundColor: getStyles('search.bgStyles.backgroundColor') } } />
      </div>
    );
  }
}

SearchPage.defaultProps = {};

SearchPage.propTypes = {
  fetchProducts: func.isRequired,
  history: shape({
    length: number,
    action: string,
    location: shape({
      pathname: string,
      search: string,
      hash: string,
      key: string
    })
  }).isRequired,
  isFetching: bool.isRequired,
  location: shape({
    hash: string,
    pathname: string,
    search: string,
    state: object
  }).isRequired,
  productCacheLoaded: bool.isRequired,
  storeProducts: shape({
    products: arrayOf(object).isRequired
  }).isRequired,
  storeData: shape({
    banner_url: string,
    collections: array,
    description: string,
    link_color: string,
    logo_height: number,
    logo_url: string,
    logo_width: number,
    name: string,
    sellerId: number,
    social_identities: object,
    theme_color: string,
    url: string,
    use_logo: bool
  }).isRequired,
  meta: shape({
    searchOptions: shape({
      keys: arrayOf(string)
    })
  }).isRequired,
  localizationData: shape({}).isRequired,
  getStyles: func.isRequired
};

const mapStateToProps = state => ({
  localizationData: state.localizationData,
  productCacheLoaded: state.productCacheLoaded,
  ...bpProps(state)
});

export default connect(mapStateToProps)(withTracker(withRouter(ThemeWrapper(SearchPage, ['styles.search', 'meta']))));
