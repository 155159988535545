import amplitude from 'amplitude-js';

const BASE_EVENT_NAME = 'whitelabel';

function logger() {
  return amplitude.getInstance();
}

function identifyUser(userProperties) {
  logger().setUserProperties(userProperties);
}

function deviceId() {
  return logger().options.deviceId;
}

function setDeviceId(id) {
  return logger().setDeviceId(id);
}

function track(event, params, context) {
  if (!window.location.hash.includes('#preview=true')) {
    logger().logEvent(
      `${BASE_EVENT_NAME}.${event}`,
      { ...params, ...context }
    );
  }
}

function init() {
  return logger().init(
    process.env.REACT_APP_AMPLITUDE_API_KEY, null,
    {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true
    }
  );
}

export default {
  track,
  init,
  identifyUser,
  deviceId,
  setDeviceId
};
