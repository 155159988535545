const getSlugFromUrl = (url) => {
  const urlStringArray = url.split('.');
  if (urlStringArray.length > 1 && urlStringArray[0] === 'www') {
    return process.env.REACT_APP_WWW_DOMAIN_SLUG ? process.env.REACT_APP_WWW_DOMAIN_SLUG : 'spring-78';
  } else {
    return urlStringArray[0];
  }
};
export const getStoreSlug = () => {
  const fromLocalStorage = window.localStorage.getItem('STORE_ID');
  return (
    fromLocalStorage || // Set using store-manifest.js (dynamically-generated at deploy, custom domains only)
    process.env.REACT_APP_STORE_ID || // Legacy custom domain env var, injected during domain-specific full builds
    getSlugFromUrl(window.location.hostname) // For creator-spring sites (subdomain always === slug)
  ).toLowerCase();
};
export const store = {
  slug: getStoreSlug()
};

export default store;
