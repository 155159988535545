import { Component } from 'react';
import {
  object,
  shape,
  string,
  node
} from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  location: shape({
    hash: string,
    pathname: string,
    search: string,
    state: object
  }).isRequired,
  children: node.isRequired
};

export default withRouter(ScrollToTop);
