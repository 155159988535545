/* eslint-disable no-unused-expressions, no-plusplus */
import get from 'lodash/get';

export const localStorageAPIAvailable = () => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

function lastElementOf(array) {
  return array.slice(-1)[0];
}

/**
 * Returns the listing slug from the URL received from the API.
 *
 * URL examples:
 * - /listing-slug?pid=624&cid=102511
 * - /es/listing-slug?tsmac=premstore
 * - /listing-slug
 *
 * @param {String} url - The listing URL
 * @returns {String} The listing slug
 */
export const listingSlugFromUrl = (url) => {
  const urlPath = url.split('?')[0];
  return lastElementOf(urlPath.split('/'));
};

/**
 * Gets the product id from the pid query param.
 *
 * @param {String} url - Example: /es/listing-slug?tsmac=store&pid=624&sid=front
 * @returns {Integer} the product id
 */
export const productIdFromUrl = (url) => {
  return parseInt(url.match(/pid=(\d+)/)[1]);
};

export const isValidDate = (d) => {
  return !Number.isNaN(d.getTime());
};

export const externalLinkRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export const isExternalUrl = (url) => {
  return externalLinkRegex.test(url);
};

export const getErrors = (name, formState) => {
  const err = get(formState.errors, `['${name}'].message`);
  return err ? [err] : [];
};

export const getProductIdsAndCount = (userCart) => {
  delete userCart.region;
  return Object.values(userCart)?.reduce((acc, curr) => {
    const productId = curr?.productId;
    const quantity = curr?.quantity;
    const slug = curr?.slug;
    // eslint-disable-next-line
    const productIdentifier = productId + '_' + slug;
    if (!acc.productIds.includes(productIdentifier)) acc.productIds.push(productIdentifier);

    if (productIdentifier in acc.count) {
      acc.count[productIdentifier] = {
        ...acc.count[productIdentifier],
        quantity: acc.count[productIdentifier].quantity + quantity
      };
    } else {
      acc.count[productIdentifier] = {
        ...acc.count[productIdentifier],
        quantity
      };
    }
    return acc;
  },
  { productIds: [], count: {} });
};
