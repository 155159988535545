import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Button } from '@springforcreators/propel-ui';
import { setCheckoutLoading } from 'redux/actions';
import { redirectToCheckout, getCartSize, cartItemsWithQty } from 'utils/cartUtils';
import tracker from 'utils/tracking';
import { bpProps } from 'utils/responsiveUtils';
import get from 'lodash/get';
import RefundPolicy from 'components/RefundPolicyButton';
import CheckoutButton from 'components/CheckoutButton';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Waypoint } from 'react-waypoint';
import { motion, AnimatePresence } from 'framer-motion';
import {
  useCheckout,
  useCartItemValidation,
  usePaymentRequest,
  usePaymentRequestOptions
} from 'hooks';
import { HIDE_AFTERPAY } from '../../constants';
import './CartSummary.scss';

export const CartSummary = (props) => {
  const {
    cartItems,
    total,
    userCart,
    cookies,
    cartProducts
  } = props;

  const storeId = useSelector(state => get(state, 'stores.slug'));
  const localizationData = useSelector(state => get(state, 'localizationData'));
  const previewMode = useSelector(state => get(state, 'themeData.previewMode'));
  const { checkout, inventory } = useSelector(state => state);
  const dispatch = useDispatch();

  const [loadingCheckout, setLoadingCheckout] = useState(null);
  const affiliate = cookies.get('affiliate');

  const [showAltPayBtnInline, setShowAltPayBtnInline] = useState(true);
  const [stickyMenu, setStickyMenu] = useState(false);
  const [ctaScrolledTo, setCtaScrolledTo] = useState(false);
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));
  const { paymentRequest, walletType } = usePaymentRequest();
  const disableCheckout = previewMode || getCartSize(userCart) <= 0;
  const options = usePaymentRequestOptions(paymentRequest, {
    height: '50px',
    borderRadius: '12px'
  });
  const isButtonDisabled = checkout?.isLoading;
  useEffect(() => {
    // Close stickymenu on scroll
    const scrollEvent = () => { if (stickyMenu) setStickyMenu(false); };
    document.addEventListener('scroll', scrollEvent);
    return () => { document.removeEventListener('scroll', scrollEvent); };
  }, [stickyMenu]);

  const ctaScrollHandler = (event) => {
    const { currentPosition, previousPosition } = event;
    const showCta = (currentPosition === 'below' && previousPosition === 'inside')
      || (currentPosition === 'above' && previousPosition === 'inside');

    const hideCta = (currentPosition === 'inside' && previousPosition === 'below')
      || (currentPosition === 'inside' && previousPosition === 'above');

    if (showCta) {
      setCtaScrolledTo(false);
      setShowAltPayBtnInline(false);
    }

    if (hideCta) {
      setCtaScrolledTo(true);
      setShowAltPayBtnInline(true);
    }
  };

  const cartItemsWithQuantity = cartItemsWithQty(cartItems);
  const { usCheckoutOnly } = useCheckout();
  const { validateCartBeforeAction } = useCartItemValidation();

  const stripeCheckout = () => {
    redirectToCheckout('stripe', cartItemsWithQuantity, storeId, localizationData, affiliate, userCart, cartProducts, walletType, inventory);
  };

  const paypalCheckout = () => {
    redirectToCheckout('paypal', cartItemsWithQuantity, storeId, localizationData, affiliate, userCart, cartProducts, walletType, inventory);
  };

  const checkoutBtns = (
    <>
      { usCheckoutOnly
        ? (
          <CheckoutButton
            className={ `cart-checkout-summary-btn ${disableCheckout || isButtonDisabled ? ' is-disabled' : ''}` }
            lineItems={ cartItemsWithQuantity }
            trackingParam="cart_page.checkout.clicked"
            paymentMethod="card"
            fullWidth={ true }
          >
            <span>Checkout</span>
          </CheckoutButton>
        ) : (
          <Button
            type="submit"
            btnType="primary"
            className="cart-checkout-summary-btn"
            fullWidth={ true }
            disabled={ disableCheckout || isButtonDisabled }
            onClick={ () => {
              dispatch(setCheckoutLoading(true));
              tracker.track('cart_page.checkout.clicked');
              setLoadingCheckout('card');
              validateCartBeforeAction(stripeCheckout);
            } }
            loading={ loadingCheckout === 'card' }
          >
            Checkout
          </Button>
        )
      }
      { usCheckoutOnly
        ? (
          <>
            <CheckoutButton
              className={ `cart-checkout-summary-btn cart-summary-btn-paypal${disableCheckout || isButtonDisabled ? ' is-disabled' : ''}` }
              lineItems={ cartItemsWithQuantity }
              trackingParam="cart_page.paypal_checkout.clicked"
              paymentMethod="paypal"
              fullWidth={ true }
              btnType="secondary"
            >
              <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" className={ disableCheckout || isButtonDisabled ? ' disabled-btn' : '' } alt="PayPal" />
            </CheckoutButton>
          </>
        ) : (
          <Button
            type="submit"
            className="cart-checkout-summary-btn cart-summary-btn-paypal"
            fullWidth={ true }
            btnType="secondary"
            disabled={ disableCheckout || isButtonDisabled }
            style={ { backgroundColor: 'white', borderColor: 'white' } }
            onClick={ () => {
              dispatch(setCheckoutLoading(true));
              tracker.track('cart_page.paypal_checkout.clicked');
              setLoadingCheckout('paypal');
              validateCartBeforeAction(paypalCheckout);
            } }
            loading={ loadingCheckout === 'paypal' }
          >
            <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" className={ disableCheckout || isButtonDisabled ? ' disabled-btn' : '' } alt="PayPal" />
          </Button>
        )
      }

      { !HIDE_AFTERPAY && usCheckoutOnly && (
        <CheckoutButton
          className={ `cart-checkout-summary-btn cart-summary-btn-afterpay${disableCheckout || isButtonDisabled ? ' is-disabled' : ''}` }
          lineItems={ cartItemsWithQuantity }
          trackingParam="cart_page.stripe_afterpay.clicked"
          paymentMethod="afterpay"
        >
          <img src="https://teespring-ass.s3.amazonaws.com/branded_stores/images/Afterpay.svg" className={ disableCheckout || isButtonDisabled ? ' disabled-btn' : '' } alt="Afterpay" />
        </CheckoutButton>
      ) }
    </>
  );

  const altpayBtn = showBtn => (
    <CheckoutButton
      className={ `altpaybutton${disableCheckout || isButtonDisabled ? ' is-disabled' : ''} ${walletType}` }
      lineItems={ cartItemsWithQuantity }
      trackingParam={ `cart_page.stripe_${walletType}.clicked` }
      paymentMethod={ walletType }
      customStyles={ {
        backgroundImage: `url(/assets/${walletType}_${disableCheckout ? 'light_' : ''}cta_logo.svg)`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20%'
      } }
    >
      { showBtn && (
        <PaymentRequestButtonElement
          options={ options }
          onClick={ (event) => { event.preventDefault(); } }
        />
      ) }
    </CheckoutButton>
  );

  const checkoutSticky = (
    <>
      <AnimatePresence>
        {(stickyMenu) && (
          <React.Fragment key="stickymenu__fragment">
            <motion.button
              type="button"
              className="stickymenu__overlay"
              key="stickymenu__overlay"
              custom="stickymenu__overlay"
              onClick={ () => setStickyMenu(false) }
              initial={ { opacity: 0 } }
              animate={ { opacity: 1 } }
              exit={ { opacity: 0 } }
              transition={ { duration: 0.2 } }
            />
            <motion.div
              className="stickymenu"
              key="stickymenu"
              custom="stickymenu"
              initial={ { marginBottom: '-100%' } }
              animate={ { marginBottom: '0%' } }
              exit={ { marginBottom: '-100%' } }
              transition={ { duration: 0.3 } }
            >
              { usCheckoutOnly && paymentRequest && altpayBtn(!showAltPayBtnInline) }
              { checkoutBtns }
            </motion.div>
          </React.Fragment>
        ) }
      </AnimatePresence>

      <div className="checkout__sticky">
        { usCheckoutOnly
          ? (
            <CheckoutButton
              className={ `cart-checkout-summary-btn${disableCheckout || isButtonDisabled ? ' is-disabled' : ''}` }
              lineItems={ cartItemsWithQuantity }
              trackingParam="cart_page.checkout.clicked"
              paymentMethod="card"
              fullWidth={ true }
            >
              <span>Checkout</span>
            </CheckoutButton>
          ) : (
            <Button
              type="submit"
              btnType="primary"
              className="cart-checkout-summary-btn"
              fullWidth={ true }
              disabled={ disableCheckout || isButtonDisabled }
              onClick={ () => {
                dispatch(setCheckoutLoading(true));
                tracker.track('cart_page.checkout.clicked');
                setLoadingCheckout('card');
                validateCartBeforeAction(stripeCheckout);
              } }
              loading={ loadingCheckout === 'card' }
            >
              Checkout
            </Button>
          )
        }
        <RefundPolicy />

      </div>
    </>
  );

  return (
    <div className="cart-summary-wrap">
      <div className="cart-summary-label">Summary</div>
      <div className="cart-summary">
        <div className="cart-summary-rows">
          <div className="cart-summary-row">
            <span className="sub-total">Subtotal</span>
            { total }
          </div>
          <div className="cart-summary-row paragraph-s subtext">
            Shipping & taxes are calculated at
            checkout
          </div>

        </div>
        <div className="cart-summary-actions">
          <Waypoint
            onEnter={ ctaScrollHandler }
            onLeave={ ctaScrollHandler }
          >
            <div className="cart-summary-action-btns">
              { checkoutBtns }
              { usCheckoutOnly && paymentRequest && altpayBtn(showAltPayBtnInline) }
              <RefundPolicy />
            </div>
          </Waypoint>

          <AnimatePresence initial={ false }>
            { (!disableCheckout && (bpIsLT('mobileLg') && !ctaScrolledTo)) && (
              <motion.div
                className="checkout__btns--mobile"
                initial={ { marginBottom: '-100%' } }
                animate={ { marginBottom: '0%' } }
                exit={ { marginBottom: '-100%' } }
                transition={ { duration: 0.5 } }
              >
                { checkoutSticky }
              </motion.div>
            ) }
          </AnimatePresence>
        </div>
      </div>
      <p className="cart-summary-note">
        <span className="label-s strong">Do you have a promo code? </span>
        <br />
        <span className="paragraph-s">Enter it at the next step.</span>
      </p>
    </div>
  );
};

const {
  arrayOf,
  shape,
  instanceOf,
  number
} = propTypes;

CartSummary.propTypes = {
  cartItems: arrayOf(shape({})).isRequired,
  cookies: instanceOf(Cookies).isRequired,
  total: shape({}).isRequired,
  userCart: shape({}).isRequired,
  cartProducts: shape({}).isRequired,
  totalInt: number.isRequired
};

export default withCookies(CartSummary);
