import './QuickAddOutOfStockModal.scss';

const QuickAddOutOfStockModal = () => {
  return (
    <>
      <span>This product is out of stock.</span>
    </>
  );
};

export default QuickAddOutOfStockModal;
