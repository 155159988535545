/* eslint-disable camelcase */
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ThemeWrapper from 'containers/ThemeWrapper';
import TweenLite from 'gsap/umd/TweenLite';
import { listingSlugFromUrl, productIdFromUrl } from 'utils/generalUtils';
import { listingUrlParts } from 'utils/urlGeneration';
import { bpProps } from 'utils/responsiveUtils';
import { connect } from 'react-redux';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import { PRODUCT_CLICK, pushEvent } from '../../utils/tracking/gtm';
import '../ProductTile/ProductTile.scss';

let slideshow;

export const ProductTileCollections = (props) => {
  const {
    product,
    list,
    position,
    localizationData,
    storeName,
    sellerId
  } = props;

  const {
    id,
    title,
    price,
    url,
    product_name,
    images
  } = product;

  const { getStyles, themeData } = useGlobalProps();
  const { layout } = themeData;

  const [activeImage, setActiveImage] = useState('default');

  // TODO: We should update the API to return the listing slug and the product id
  const listingSlug = listingSlugFromUrl(url);
  const productId = productIdFromUrl(url);

  const { pathname, search } = listingUrlParts(listingSlug, productId);

  const containerRef = useRef();

  const primaryIndex = images.findIndex(img => img.primary);

  const eventBody = {
    currencyCode: get(localizationData, 'buyer_currency'),
    click: {
      actionField: {
        list
      },
      products: [{
        name: get(product, 'title'),
        id: get(product, 'external_id'),
        price: get(product, 'price'),
        brand: storeName,
        category: get(product, 'product_name'),
        position: position + 1,
        dimension8: sellerId,
        dimension9: get(product, 'external_id')
      }]
    }
  };

  useEffect(() => {
    TweenLite.from(containerRef.current, 1, { opacity: 0, y: 50 });
  }, []);

  let activeSide;

  const changeImage = () => {
    setActiveImage(activeSide);
    activeSide = (activeSide >= images.length - 1 ? 0 : activeSide + 1);
  };

  const startSlideshow = () => {
    if (!isEmpty(images)) {
      activeSide = images.length > 1 ? 1 : 0;
      if (activeSide === primaryIndex) {
        activeSide = 0;
      }
      slideshow = setInterval(changeImage, 1000);
      changeImage();
    }
  };

  const endSlideshow = () => {
    clearInterval(slideshow);
    setActiveImage('default');
  };

  const productClick = () => {
    pushEvent(PRODUCT_CLICK, eventBody);
  };

  return (
    <div
      ref={ containerRef }
      className={ `product-tile ${get(layout, 'productList.grid')}` }
    >
      <Link
        to={ { pathname, search, state: { productId: id, list } } }
        className="product-tile-link-wrapper"
        onClick={ productClick }
      >
        <div
          className="product-tile-image"
          style={ { backgroundColor: getStyles('productList.tileBgColor') } }
          onMouseEnter={ startSlideshow }
          onMouseLeave={ endSlideshow }
        >
          <div
            className={ `product-tile-image-default ${activeImage === 'default' ? 'active' : ''}` }
            style={ { backgroundImage: `url(${images[primaryIndex].url})` } }
          />
          { images.map((img, idx) => (
            <div
              key={ img.url }
              className={ `product-tile-additional-image ${activeImage === idx ? 'active' : ''}` }
              style={ { backgroundImage: `url(${img.url})` } }
            />
          )) }
        </div>
        <div className="product-tile-details">
          <strong className="product-tile-title">{ title }</strong>
          <p className="product-tile-product">{ product_name }</p>
          <strong className="product-tile-price">{ price }</strong>
        </div>
      </Link>
    </div>
  );
};

const {
  number,
  shape,
  string,
  bool,
  node,
  oneOfType,
  func
} = propTypes;

ProductTileCollections.propTypes = {
  product: shape({
    image: string,
    additionalImages: shape({
      side: shape({
        src: string
      })
    }),
    daysLeft: number,
    id: number,
    name: string,
    price: oneOfType([string, node]),
    productName: string,
    timeLeft: string,
    url: string
  }).isRequired,
  layout: shape({
    productList: shape({
      grid: string,
      pagination: bool
    })
  }).isRequired,
  getStyles: func.isRequired,
  localizationData: shape({}).isRequired,
  storeName: string,
  list: string.isRequired,
  position: oneOfType([string, number]).isRequired,
  sellerId: number.isRequired
};

ProductTileCollections.defaultProps = {
  storeName: ''
};

const mapStateToProps = state => ({
  ...bpProps(state),
  localizationData: state.localizationData,
  storeName: state.stores.name,
  sellerId: state.stores.sellerId
});

export default ThemeWrapper(connect(mapStateToProps)(ProductTileCollections), ['layout.productList', 'styles.productList']);
