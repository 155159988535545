import {
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react';
import {
  string, func, shape, number, arrayOf
} from 'prop-types';
import { useSelector } from 'react-redux';
import { SelectField, Icon } from '@springforcreators/propel-ui';
import map from 'lodash/map';
import get from 'lodash/get';
import { bpProps } from 'utils/responsiveUtils';
import { scrollToSpecificHorizontalValue } from 'utils/scrollTo';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import VariantSelector from 'components/VariantSelector';
import { Waypoint } from 'react-waypoint';
import './ColorOptions.scss';
import tracker from 'utils/tracking';

const ColorOptions = ({
  listingSlug,
  productId,
  colorOptions,
  onChangeHandler
}) => {
  const { getStyles } = useGlobalProps();
  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));
  const [colorOptionIndex, setColorOptionIndex] = useState(0);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const colorOptionItems = map(colorOptions, item => ({
    id: get(item, 'variationId'),
    label: get(item, 'color'),
    hex: get(item, 'attributes.hex')
  }));

  const colorOptionsRef = useRef(null);
  const controlsRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const showOptions = colorOptions.length > 1;
  const isDesktopView = bpIsGT('mobileLg');

  // If the the color options overflows the container, show the right arrow on mount
  useEffect(() => {
    if (colorOptionsRef.current) {
      const { width } = colorOptionsRef.current.getBoundingClientRect();
      setShowRightArrow(width > controlsRef.current.offsetWidth);
    }
  }, []);

  const triggerArrowLeft = useCallback((event) => {
    setShowLeftArrow(event.currentPosition !== 'inside');
  }, []);
  const triggerArrowRight = useCallback((event) => {
    setShowRightArrow(event.currentPosition !== 'inside');
  }, []);

  const onColorOptionChange = useCallback(async (newColorOptionIndex) => {
    const newColor = colorOptions[newColorOptionIndex];

    tracker.track('color_options.quick_add_button.color.clicked', {
      listingSlug,
      productId,
      variationId: newColor?.variationId,
      color: newColor?.color
    });

    await onChangeHandler(newColor);
    setColorOptionIndex(newColorOptionIndex);
  }, [colorOptions, listingSlug, productId, onChangeHandler]);

  const triggerScroll = useCallback((trigger) => {
    const scrollContainer = scrollContainerRef.current;
    const scrollDiff = trigger === 'right' ?
      controlsRef.current.offsetWidth - 100 :
      -controlsRef.current.offsetWidth + 100;

    scrollToSpecificHorizontalValue(scrollDiff, scrollContainer);
  }, []);

  const triggerScrollLeft = useCallback(() => {
    triggerScroll('left');
  }, [triggerScroll]);

  const triggerScrollRight = useCallback(() => {
    triggerScroll('right');
  }, [triggerScroll]);

  const colorSelector = isDesktopView ? (
    <VariantSelector
      ref={ colorOptionsRef }
      type="color"
      items={ colorOptionItems }
      onChange={ onColorOptionChange }
      activeStyles={ {
        borderColor: getStyles('listing.highlightColor') || '#000000'
      } }
      activeIndex={ colorOptionIndex }
    />
  ) : (
    <>
      <div className="color__swatch" style={ { backgroundColor: colorOptionItems[colorOptionIndex].hex } } />
      <SelectField
        name="coloroption"
        label="Select color"
        defaultValue={ colorOptionItems[colorOptionIndex].label }
        items={ colorOptionItems }
        onChange={ onColorOptionChange }
      />
    </>
  );

  return showOptions && (
    <div className="color__options__wrapper">
      { isDesktopView && (
        <div ref={ controlsRef } className="color__options__controls">
          { showLeftArrow && (
            <button
              type="button"
              data-testid="color_options_left"
              className="color__options__arrow left"
              onClick={ triggerScrollLeft }
            >
              <Icon name="ChevronLeft" />
            </button>
          ) }

          { showRightArrow && (
            <button
              type="button"
              data-testid="color_options_right"
              className="color__options__arrow right"
              onClick={ triggerScrollRight }
            >
              <Icon name="ChevronRight" />
            </button>
          ) }
        </div>
      ) }

      <div className="color__options__scroller" ref={ scrollContainerRef }>
        <div
          className={ `listing__options ${ isDesktopView ? 'color__options' : '' }` }
          data-testid="product__tile_color__options"
          role="list"
        >
          <Waypoint
            horizontal={ true }
            scrollableAncestor={ scrollContainerRef.current }
            onEnter={ triggerArrowLeft }
            onLeave={ triggerArrowLeft }
          />
          <div>
            { colorSelector }
          </div>
          <Waypoint
            horizontal={ true }
            scrollableAncestor={ scrollContainerRef.current }
            onEnter={ triggerArrowRight }
            onLeave={ triggerArrowRight }
          />
        </div>
      </div>
    </div>
  );
};

ColorOptions.propTypes = {
  listingSlug: string.isRequired,
  productId: number.isRequired,
  colorOptions: arrayOf(shape({ variationId: number.isRequired, color: string.isRequired }).isRequired).isRequired,
  onChangeHandler: func.isRequired
};

export default ColorOptions;
