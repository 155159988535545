import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@springforcreators/propel-ui';
import { bpProps } from 'utils/responsiveUtils';
import { getCartSize } from 'utils/cartUtils';
import tracker from 'utils/tracking';
import './CartPill.scss';
import { setActiveModal } from 'redux/actions';

const CartPill = () => {
  const { userCart } = useSelector(state => state);
  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));
  const cartSize = getCartSize(userCart) || 0;
  const dispatch = useDispatch();

  const desktopContents = (
    <>
      Cart
      <span>&#183;</span>
      <span className="cart__counter">{` ${cartSize > 99 ? '99+' : cartSize}`}</span>
    </>
  );
  const mobileContents = (
    <>
      {
        cartSize > 0 && (
          <span className="cart__counter">{` ${cartSize > 99 ? '99+' : cartSize}`}</span>
        )
      }
    </>
  );

  const handleClick = () => {
    tracker.track('header.cart.clicked');
    dispatch(setActiveModal(null));
  };

  return (
    <Link
      to="/cart"
      className="cart__pill pr_btn pr_btn--md btn--secondary label-s"
      onClick={ handleClick }
    >
      <Icon name="Cart" size={ 26 } />
      {
        bpIsGT('mobileLg') ? desktopContents : mobileContents
      }
    </Link>
  );
};

export default CartPill;
