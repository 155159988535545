import { SET_THEME_DATA, SET_PREVIEW_MODE } from 'redux/actions';

/**
 * Updates the theme data
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export default (state = { previewMode: false }, action) => {
  switch (action.type) {
    case SET_THEME_DATA:
      return {
        ...state,
        ...action.data
      };
    case SET_PREVIEW_MODE:
      return {
        ...state,
        previewMode: true
      };
    default:
      return state;
  }
};
