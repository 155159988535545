import React from 'react';

const DigitalDeliveryDetails = () => (
  <>
    <p className="mb2">
      {' '}
      A link to download your purchase will be available once your order is processed, which may take a few minutes to complete.
      Please check your inbox for two emails: one confirming your purchase and another containing a link to download your digital product.
      {' '}
    </p>
  </>
);

export default DigitalDeliveryDetails;
