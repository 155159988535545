/*eslint-disable camelcase*/
import React from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';

const Price = (props) => {
  const { value } = props;
  const localizationData = useSelector(state => state.localizationData);
  const { buyer_locale, buyer_currency } = localizationData;
  const priceStr = parseFloat(value).toLocaleString(buyer_locale, { style: 'currency', currency: buyer_currency });

  return (
    <span className="price">{ priceStr }</span>
  );
};

const {
  number, string, oneOfType
} = propTypes;
Price.propTypes = {
  value: oneOfType([number, string]).isRequired
};

export default Price;
