import React from 'react';
import MainLayout from 'containers/MainLayout';
import ThemeWrapper from 'containers/ThemeWrapper';
import propTypes from 'prop-types';
import get from 'lodash/get';
import { bpProps } from 'utils/responsiveUtils';
import { connect } from 'react-redux';
import tracker from 'utils/tracking';
import { clearHttpError } from 'redux/actions';
import FourOhFourContent from 'components/FourOhFourContent';

export const FourOhFourPage = (props) => {
  const {
    location,
    storeData,
    getStyles,
    hasHttpError,
    dispatchClearHttpError
  } = props;

  React.useEffect(() => {
    tracker.track('branded_stores.404error.viewed', { path: location.pathname });
  }, [location.pathname]);

  React.useEffect(() => {
    if (hasHttpError) {
      dispatchClearHttpError();
    }
  }, [hasHttpError]);

  const primaryColor = getStyles('primaryColor') || '#000000';

  return (
    <MainLayout location={ location } storeData={ storeData }>
      <FourOhFourContent primaryColor={ primaryColor } showGoToHomepage={ true } />
    </MainLayout>
  );
};

const { shape, func, bool } = propTypes;
FourOhFourPage.propTypes = {
  location: shape({}).isRequired,
  storeData: shape({}).isRequired,
  getStyles: func.isRequired,
  hasHttpError: bool.isRequired,
  dispatchClearHttpError: func.isRequired
};

const mapStateToProps = state => ({
  ...bpProps(state),
  hasHttpError: get(state, 'httpError.status') !== ''
});

const mapDispatchToProps = dispatch => ({
  dispatchClearHttpError: () => dispatch(clearHttpError())
});

export default ThemeWrapper(connect(mapStateToProps, mapDispatchToProps)(FourOhFourPage), 'styles.primaryColor');
