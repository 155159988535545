import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon } from '@springforcreators/propel-ui';
import { bpProps } from 'utils/responsiveUtils';
import './CheckoutHeader.scss';

const CheckoutHeader = () => {
  const { meta, content } = useSelector(state => get(state, 'themeData'));
  const { getStyles, bpIsGT } = useSelector(state => ({ ...bpProps(state) }));
  const logoSrc = getStyles('header.logo', content);

  const logoContent = logoSrc && logoSrc !== 'null' // When there is no image passed in to the store preview
    ? (
      <img
        src={ logoSrc }
        alt={ get(meta, 'storeName') }
        style={ { maxHeight: getStyles('header.logo.maxHeight') } }
      />
    )
    : (
      <h3 style={ getStyles('header.textStyles') }>
        { get(meta, 'storeName') }
      </h3>
    );

  return (
    <header
      className="checkout__header"
      style={ {
        ...getStyles('header.bgStyles'),
        ...getStyles('header.textStyles')
      } }
    >
      <div className="checkout__headerinner">
        <Link className="checkout__header__backlink" to="/cart">
          <Icon name="ChevronLeft" size={ 13 } className="mr1" />
          { bpIsGT('mobileLg') && <>Continue shopping</> }
        </Link>
        <Link className="checkout__header__logo" to="/">{ logoContent }</Link>
      </div>
    </header>
  );
};

export default CheckoutHeader;
