import React from 'react';
import get from 'lodash/get';
import Price from 'components/Price';
import propTypes from 'prop-types';
import './CheckoutCartItem.scss';
import { isDigitalProduct } from 'utils/productUtils';

const CheckoutCartItem = ({
  primaryImages,
  title,
  productType,
  quantityInCart,
  price,
  selectedSize,
  colorName
}) => {
  const lines = [];

  if (selectedSize) lines.push({ label: 'Size', value: selectedSize?.label });
  if (colorName) lines.push({ label: 'Color', value: colorName });

  return (
    <div className="checkout__cartitem">
      <div className="checkout__cartitem__info">
        <img src={ get(primaryImages, 'thumblarge') } alt={ title } />
        <div className="cartitem__info">
          <p className="checkout__cartitem__title typ--bold">{ title }</p>
          <p className="typ--mediumgrey">{ productType }</p>
          {!isDigitalProduct({ selectedSize }) && (
            <>
              <div className="cartitem__lines">
                { lines.map(line => (
                  <span className="cartitem__line" key={ line.label }>
                    { `${line.label}: `}
                    <span>{ line.value }</span>
                  </span>
                )) }
              </div>

              <span className="cartitem__qty">
                <div>
                  Quantity:
                  <span>{ ` ${quantityInCart}` }</span>
                </div>

                <Price
                  value={ price }
                />
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const { shape, string, number } = propTypes;
CheckoutCartItem.propTypes = {
  primaryImages: shape({
    thumblarge: string
  }).isRequired,
  title: string.isRequired,
  productType: string.isRequired,
  quantityInCart: number.isRequired,
  price: string.isRequired,
  selectedSize: shape({
    label: string
  }).isRequired,
  colorName: string.isRequired
};

export default CheckoutCartItem;
