/* eslint-disable consistent-return, jsx-a11y/no-static-element-interactions, no-unused-vars, camelcase */
import {
  useEffect, useRef, useState, Fragment
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import propTypes, { func } from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ThemeWrapper from 'containers/ThemeWrapper';
import TweenLite from 'gsap/umd/TweenLite';
import { getProductIdsAndCount, listingSlugFromUrl, productIdFromUrl } from 'utils/generalUtils';
import { listingUrlParts } from 'utils/urlGeneration';
import { bpProps } from 'utils/responsiveUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModal, setActiveProductTile, fetchCartListings, fetchListingData, fetchListingInventoryCount
} from 'redux/actions';
import { Button } from '@springforcreators/propel-ui';
import tracker from 'utils/tracking';
import { PRODUCT_CLICK, pushEvent } from '../../utils/tracking/gtm';
import removeCurrencySymbol from '../../lib';
import ProductOptions from './ProductOptions';
import './ProductTile.scss';

let slideshow;

export const ProductTile = (props) => {
  const {
    product,
    layout,
    list,
    position,
    disableQuickAdd,
    onClick,
    showProductType,
    showListingTitle,
    shortenText
  } = props;

  const {
    price
  } = product;

  const id = product?.id || product?.productId;
  const name = product?.name || product?.title;
  const url = product.url.match(/pid=(\d+)/) ? product.url : `${product.url}?pid=${id}`;
  const productName = product?.productName || product?.productType;
  const imageUrl = product?.imageUrl || product?.thumbnail?.[product?.thumbnail?.primary]?.src;
  const additionalImages = product?.additionalImages || product?.images;

  const {
    activeModal, storeListings, checkout, userCart, localizationData, stores, themeData
  } = useSelector(state => state);
  const { getStyles, bpIsGT } = useSelector(state => ({ ...bpProps(state) }));

  const { buyer_locale, buyer_currency } = localizationData;

  const dispatch = useDispatch();
  const [showProductOptions, setShowProductOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({
    ...additionalImages,
    front: {
      src: imageUrl
    }
  });
  const [activeImage, setActiveImage] = useState('front');
  // TODO: We should update the API to return the listing slug and the product id
  const listingSlug = listingSlugFromUrl(url);
  const productId = productIdFromUrl(url);
  const { pathname: path } = useLocation();
  const { previewMode } = themeData;
  const [listingDetails, setListingDetails] = useState({ primaryProduct: [] });

  const { productIds, count } = getProductIdsAndCount(userCart);
  const productIdentifier = `${productId}_${listingSlug}`;

  const { pathname, search } = listingUrlParts(listingSlug, productId);

  const priceWithCurrency = typeof price === 'string' && ['$', '£', '€'].some(currSymbol => price.includes(currSymbol)) ?
    price :
    parseFloat(price).toLocaleString(buyer_locale, {
      style: 'currency',
      currency: buyer_currency,
      currencyDisplay: 'symbol'
    });

  const itemAddedToCart = productIds.includes(productIdentifier);

  const containerRef = useRef();

  const sides = additionalImages ?
    Object.keys(additionalImages).filter(side => additionalImages[side].src) :
    null;

  const eventBody = {
    currencyCode: get(localizationData, 'buyer_currency'),
    click: {
      actionField: {
        list
      },
      products: [
        {
          name: get(product, 'name'),
          id: get(product, 'listingId'),
          price: removeCurrencySymbol(get(product, 'price')),
          brand: stores?.name,
          category: get(product, 'productName'),
          position: position + 1,
          dimension8: stores?.sellerId,
          dimension9: get(product, 'listingId')
        }
      ]
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      TweenLite.from(containerRef.current, 1, { opacity: 0, y: 50 });
    }
  }, []);

  useEffect(() => {
    if (activeModal?.id === 'quick-add-confirmation') {
      setShowProductOptions(false);

      if (!checkout?.isLoading) {
        const timeout = setTimeout(() => {
          dispatch(setActiveModal(null));
        }, 6000);

        return () => clearTimeout(timeout);
      }
    }
  }, [activeModal, checkout?.isLoading]);

  useEffect(() => {
    if (storeListings?.index !== position) {
      setShowProductOptions(false);
    }
  }, [storeListings?.index, showProductOptions, position]);

  const productClick = () => {
    pushEvent(PRODUCT_CLICK, eventBody);
    onClick();
  };

  const formattedProductName = get(product, 'productName', '')
    .toLowerCase()
    .split(' ')
    .join('-');

  const onColorChange = async (newColor) => {
    if (newColor?.images && newColor.images.length) {
      setImage({
        ...additionalImages,
        front: newColor.images[0],
        back: newColor.images[1] ?? null
      });
    }
  };

  const handleQuickAddClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    tracker.track('product_tile.quick_add_button.clicked', {
      listingSlug,
      productId
    });
    dispatch(setActiveProductTile(position));

    const listingDetail = await fetchListingData(listingSlug, productId, localizationData, stores?.slug, previewMode);
    if (listingDetail?.primaryProduct) {
      listingDetail.primaryProduct = listingDetail.primaryProduct.filter(prod => prod);
      if (listingDetail.primaryProduct.length === 0) {
        delete listingDetail.primaryProduct;
      }
    }
    setListingDetails(listingDetail);
    if (!isEmpty(userCart)) await dispatch(fetchCartListings(userCart));
  };

  useEffect(async () => {
    const defaultVariant = listingDetails?.primaryProduct[0];

    if (isLoading && defaultVariant) {
      await onColorChange(defaultVariant);
      if (defaultVariant.availableSizesWithId.length > 0) {
        await dispatch(
          fetchListingInventoryCount(
            defaultVariant.variationId,
            defaultVariant.availableSizesWithId[0].id,
            listingDetails
          )
        );
      }
      setShowProductOptions(true);
      setIsLoading(false);
    }
  }, [isLoading, listingDetails]);

  let activeSide;

  const changeImage = () => {
    setActiveImage(sides[activeSide]);
    activeSide = (activeSide + 1) % sides.length;
  };

  const startSlideshow = () => {
    if (sides?.length) {
      activeSide = sides.length > 1 ? 1 : 0;
      if (!isEmpty(additionalImages) && additionalImages[sides[activeSide]].src === imageUrl) {
        activeSide = 0;
      }
      slideshow = setInterval(changeImage, 1000);
      changeImage();
    }
  };

  const endSlideshow = () => {
    clearInterval(slideshow);
    setActiveImage('front');
  };

  const quickAddToCartBtn = (
    <Button
      style={ { backgroundColor: 'var(--blue)' } }
      onClick={ e => handleQuickAddClick(e) }
      className="product-tile-quick-add-to-cart-btn pr_btn--icononly"
      iconSize={ bpIsGT('mobileMd') ? 24 : 24 }
      loading={ isLoading }
      btnType={ `${isLoading || itemAddedToCart ? 'primary' : 'secondary'}` }
      size={ bpIsGT('mobileMd') ? 'lg' : 'md' }
    >
      {count[productIdentifier]?.quantity ? (
        <span className="product-tile-cart-item-count">
          {count[productIdentifier].quantity}
        </span>
      ) : null}
      <img alt="add icon" src="/assets/add-icon.svg" />
    </Button>
  );

  return (
    <>
      {image.front.src &&
        (
          <div
            ref={ containerRef }
            className={ `product-tile ${get(layout, 'productList.grid')}` }
          >
            <>
              <Link
                to={ { pathname, search, state: { productId: id, list } } }
                className="product-tile-link-wrapper"
                onClick={ productClick }
                data-cy={ `product-tile-link-wrapper-${formattedProductName}` }
              >
                <div
                  className="product-tile-image"
                  style={ { backgroundColor: getStyles('productList.tileBgColor') } }
                  onMouseEnter={ startSlideshow }
                  onMouseLeave={ endSlideshow }
                >
                  {path.includes('search') || showProductOptions || disableQuickAdd ? null : quickAddToCartBtn}
                  <div
                    className={ `product-tile-image-default ${
                      activeImage === 'front' ? 'active' : ''
                    }` }
                    style={ { backgroundImage: `url(${image.front.src})` } }
                    aria-label="product-image"
                  />
                  {additionalImages && (
                    <Fragment>
                      {sides.map(side => (
                        <div
                          key={ side }
                          className={ `product-tile-additional-image ${
                            side === activeImage ? 'active' : ''
                          }` }
                          style={ {
                            backgroundImage: `url(${image[side]?.src})`
                          } }
                        />
                      ))}
                    </Fragment>
                  )}
                </div>
                { showProductOptions && !!listingDetails && (
                  <ProductOptions
                    productId={ productId }
                    listingSlug={ listingSlug }
                    onColorChange={ onColorChange }
                    list={ list }
                    setShowProductOptions={ setShowProductOptions }
                    listingDetails={ listingDetails }
                  />
                )}
                { !showProductOptions && (
                  <div className="product-tile-details">
                    <div className="product-tile-info">
                      { showListingTitle && <span className={ `product-tile-title ${ shortenText ? 'shortened-text' : ''} ` }>{name}</span> }
                      { showProductType && <span className={ `product-tile-category ${ shortenText ? 'shortened-text' : ''} ` }>{productName}</span> }
                    </div>
                    <span className="product-tile-price">{ priceWithCurrency }</span>
                  </div>
                )}
              </Link>
            </>
          </div>
        )
      }
    </>
  );
};

const {
  number, shape, string, bool, node, oneOfType
} = propTypes;

ProductTile.propTypes = {
  product: shape({
    imageUrl: string,
    additionalImages: shape({
      side: shape({
        src: string
      })
    }),
    daysLeft: number,
    id: number,
    name: string,
    price: oneOfType([string, node]),
    productName: string,
    timeLeft: string,
    url: string
  }).isRequired,
  layout: shape({
    productList: shape({
      grid: string,
      pagination: bool
    })
  }).isRequired,
  list: string.isRequired,
  position: oneOfType([string, number]).isRequired,
  disableQuickAdd: bool,
  onClick: func,
  showProductType: bool,
  showListingTitle: bool,
  shortenText: bool
};

ProductTile.defaultProps = {
  disableQuickAdd: false,
  onClick: () => {},
  showProductType: true,
  showListingTitle: true,
  shortenText: false
};

export default ThemeWrapper((ProductTile), [
  'layout.productList',
  'styles.productList'
]);
