import React from 'react';
import { Accordion, Icon } from '@springforcreators/propel-ui';
import ThemeButton from 'components/ThemeButton';
import { Link, useParams } from 'react-router-dom';
import { useSupport } from '../../hooks/useSupport';
import './SupportSidenav.scss';

export const SupportSidenav = () => {
  const { categories } = useSupport();
  const { postSlug } = useParams();

  return (
    <div className="support__sidenav">
      <nav className="support__nav">
        { categories.map((category) => {
          let defaultOpen = false;
          if (category.posts.some(post => post.slug === postSlug)) {
            defaultOpen = true;
          }

          return (
            <Accordion
              className="support__nav__category"
              defaultOpen={ defaultOpen }
              key={ category.databaseId }
              title={ (
                <div className="support__nav__category__title">
                  <Icon name={ category.icon } size={ 16 } />
                  { category.name }
                </div>
              ) }
              closedIcon="Plus"
              openIcon="X"
              content={ (
                <ul>
                  { category.posts.map((post) => {
                    const isActive = post.slug === postSlug;
                    return (
                      <li key={ post.databaseId } className={ isActive ? 'is-active' : '' }>
                        <Link to={ `/support/${post.slug}` }>
                          { post.title }
                        </Link>
                      </li>
                    );
                  }) }
                </ul>
              ) }
            />
          );
        }) }
      </nav>

      <div className="mt6">
        <ThemeButton
          className="btn--icon mb1"
          link="/track"
          elemType="routerLink"
          btnType="secondary"
        >
          Track order
          <Icon name="ArrowRight" size={ 16 } />
        </ThemeButton>

        <ThemeButton
          className="btn--icon"
          link="/contact"
          elemType="routerLink"
          btnType="secondary"
        >
          Contact support
          <Icon name="ArrowRight" size={ 16 } />
        </ThemeButton>
      </div>
    </div>
  );
};

export default SupportSidenav;
