/* eslint no-underscore-dangle: 0 */
import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import isEmpty from 'lodash/isEmpty';
import requestPaymentIntent from 'lib/paymentIntent';
import { setPaymentMethod } from 'redux/actions/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeWalletType } from 'utils/checkoutUtils';

const usePaymentRequest = () => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { checkout, stores } = useSelector(state => state);
  const [paymentIntentData, setPaymentIntentData] = useState(null);
  const [customerWalletDetails, setCustomerWalletDetails] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const [walletType, setWalletType] = useState(null);

  const onPaymentMethod = async ({ complete, paymentMethod, ...data }) => {
    const paymentIntentResponse = await requestPaymentIntent(
      { checkout, stores },
      paymentMethod.id,
      null,
      {
        email: data.payerEmail,
        name: data.payerName,
        address: paymentMethod.billing_details.address,
        paymentMethod: walletType
      }
    );

    if (!isEmpty(paymentIntentResponse)) {
      dispatch(setPaymentMethod(paymentMethod.type));
      const customerWallet = {
        ...checkout?.customer,
        address: {
          address1: paymentMethod.billing_details.address.line1,
          address2: paymentMethod.billing_details.address.line2,
          city: paymentMethod.billing_details.address.city,
          state: paymentMethod.billing_details.address.state,
          zip: paymentMethod.billing_details.address.postal_code,
          country: 'US'
        },
        email: data.payerEmail,
        firstName: data.payerName.split(' ')[0],
        lastName: data.payerName.split(' ')[1]
      };
      setPaymentIntentData(paymentIntentResponse);
      setCustomerWalletDetails(customerWallet);
      complete('success');
    }
  };

  useEffect(async () => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: checkout?.costs?.total?.currency?.toLowerCase() ?? 'usd',
        total: {
          label: 'Total cost',
          amount: checkout?.costs?.total?.value ?? 1
        },
        requestPayerName: true,
        requestPayerEmail: true
      });
      setPaymentRequest(pr);
      const walletReady = await pr.canMakePayment();
      const buttonType = sanitizeWalletType(pr?._buttonTypeName);
      if (['google-pay', 'apple-pay'].includes(buttonType) && walletReady) {
        setCanMakePayment(true);
        setWalletType(buttonType);
      }
    }
  }, [stripe, paymentRequest, checkout.costs]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', onPaymentMethod);
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', onPaymentMethod);
      }
    };
  }, [paymentRequest, onPaymentMethod]);

  return {
    paymentRequest: canMakePayment ? paymentRequest : null, customerWalletDetails, paymentIntentData, walletType
  };
};

export default usePaymentRequest;
