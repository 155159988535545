import { Component } from 'react';
import { node } from 'prop-types';
import { TrackJS } from 'trackjs';

class ErrorBoundary extends Component {
  // ErrorBoundary needs this method implemented otherwise we get a console error;
  static getDerivedStateFromError() {}

  componentDidCatch(err) {
    TrackJS.track(err);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired
};

export default ErrorBoundary;
