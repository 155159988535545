/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { getCookie, setCookie } from 'utils/cookiesUtils';
import { localStorageAPIAvailable } from '../generalUtils';

const canUseLocalStorage = localStorageAPIAvailable();

export const getLocalizationDetails = () => {
  const buyerLocalization = getCookie('buyerLocalization');
  return isEmpty(buyerLocalization) ? {} : JSON.parse(buyerLocalization);
};

export const usBasedBuyer = () => {
  const localeCache = getLocalizationDetails();
  return get(localeCache, 'buyer_region', '') === 'USA' && get(localeCache, 'fulfillment_region', '') === 'USA' && get(localeCache, 'buyer_currency', '') === 'USD';
};

export const getAvailableLocations = () => {
  if (canUseLocalStorage) {
    const cache = window.localStorage.getItem('buyerLocalization');
    return isEmpty(cache) ? {} : JSON.parse(cache);
  } else {
    return {};
  }
};

export const setLocalizationDetails = ({
  buyer_region,
  buyer_currency,
  buyer_locale,
  available_locales,
  available_currencies,
  available_regions,
  fulfillment_region
}) => {
  const localstorageCache = getAvailableLocations();

  if (isEmpty(localstorageCache) && canUseLocalStorage) {
    window.localStorage.setItem(
      'buyerLocalization',
      JSON.stringify({
        available_locales,
        available_currencies,
        available_regions
      })
    );
  }

  setCookie(
    'buyerLocalization',
    JSON.stringify({
      buyer_region,
      buyer_currency,
      buyer_locale,
      fulfillment_region
    })
  );
};

export const defaultLocalization = {
  buyer_locale: 'en',
  buyer_currency: 'USD',
  buyer_region: 'USA',
  available_locales: [
    {
      local_code: 'en',
      local_name: 'English (USA)'
    },
    {
      local_code: 'de',
      local_name: 'Deutsch'
    },
    {
      local_code: 'nl',
      local_name: 'Nederlands'
    },
    {
      local_code: 'fr',
      local_name: 'Français'
    },
    {
      local_code: 'it',
      local_name: 'Italiano'
    },
    {
      local_code: 'es',
      local_name: 'Español'
    },
    {
      local_code: 'pt-BR',
      local_name: 'Português do Brasil'
    },
    {
      local_code: 'en-GB',
      local_name: 'English (UK)'
    }
  ],
  available_currencies: [
    {
      currency: 'USD',
      symbol: '$'
    },
    {
      currency: 'GBP',
      symbol: '£'
    },
    {
      currency: 'EUR',
      symbol: '€'
    },
    {
      currency: 'AUD',
      symbol: '$'
    },
    {
      currency: 'CAD',
      symbol: '$'
    }
  ],
  available_regions: [
    {
      region: 'USA',
      fulfillment_region: 'USA'
    },
    {
      region: 'EU',
      fulfillment_region: 'EUR'
    },
    {
      region: 'Canada',
      fulfillment_region: 'USA'
    },
    {
      region: 'Australia',
      fulfillment_region: 'USA'
    },
    {
      region: 'Rest of the world',
      fulfillment_region: 'USA'
    }
  ]
};

export const isLocalizationCached = () => !!isEmpty(getLocalizationDetails());
