/*eslint-disable react/button-has-type*/
import React from 'react';
import get from 'lodash/get';
import propTypes from 'prop-types';
import { Button } from '@springforcreators/propel-ui';
import ThemeWrapper from 'containers/ThemeWrapper';
import { bpProps } from 'utils/responsiveUtils';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const defaultBtnStyles = {
  color: '#FFFFFF',
  backgroundColor: '#000000',
  borderColor: '#000000'
};

export const ThemeButton = (props) => {
  const {
    className,
    children,
    type,
    onClick,
    styles,
    btnType,
    link,
    elemType,
    disabled
  } = props;

  const { getStyles } = useSelector(state => ({ ...bpProps(state) }));

  let defaultStyles = defaultBtnStyles;

  if (btnType === 'secondary') {
    defaultStyles = {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      borderColor: '#FFFFFF'
    };
  }

  if (btnType === 'simple') {
    defaultStyles = {
      color: get(styles, 'typography.linkStyles.color') || '#000000',
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    };
  }

  let elem = (
    <Button
      className={ `btn ${className}` }
      type={ type }
      onClick={ onClick }
      style={ getStyles(`button[${btnType}]`) || defaultStyles }
      disabled={ disabled }
    >
      { children }
    </Button>
  );

  if (elemType === 'routerLink') {
    elem = (
      <Link
        to={ link }
        className={ `btn ${className}` }
        type={ type }
        onClick={ onClick }
        style={ getStyles(`button[${btnType}]`) || defaultStyles }
      >
        { children }
      </Link>
    );
  }

  return elem;
};

const {
  node,
  string,
  func,
  shape,
  oneOf,
  bool
} = propTypes;
ThemeButton.propTypes = {
  className: string,
  children: node.isRequired,
  type: string,
  onClick: func,
  styles: shape({
    bgColor: string,
    textColor: string
  }).isRequired,
  btnType: oneOf(['primary', 'secondary', 'simple']),
  elemType: string,
  link: string,
  disabled: bool
};

ThemeButton.defaultProps = {
  className: '',
  type: 'button',
  onClick: null,
  btnType: 'primary',
  elemType: 'button',
  disabled: false,
  link: ''
};

export default ThemeWrapper(ThemeButton, 'styles');
