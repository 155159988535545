import React, { useState } from 'react';
import propTypes from 'prop-types';
import './SizeChartModal.scss';

const SizeChartModal = (props) => {
  const {
    sizeChartData,
    sizes,
    dimensions,
    measurements,
    sizeChartUrl
  } = props;

  const [activeUnit, setActiveUnit] = useState(0);

  const filteredDimensions = dimensions.filter((dimension) => {
    return sizeChartData[sizes[0]][dimension][measurements[0]].length > 0;
  });

  const measurementToggle = (
    <div className="sizechart__measurements">
      { measurements.map((measurement, i) => (
        <button
          type="button"
          className={ `sizechart__measurement-btn ${activeUnit === i ? 'active' : ''}` }
          onClick={ () => setActiveUnit(i) }
          key={ measurement }
        >
          { measurement === 'inches' ? 'IN' : 'CM' }
        </button>
      ))}
    </div>
  );

  const sizeChartTable = (
    <div className="sizechart__container mt2">
      <table className="sizechart__table">
        <thead>
          <tr>
            <th>Size</th>
            { filteredDimensions.map(dimension => (
              <th key={ dimension }>{ dimension }</th>
            ))}
          </tr>
        </thead>
        <tbody>
          { sizes.map(size => (
            <tr key={ size }>
              <td>{ size }</td>
              { filteredDimensions.map(dimension => (
                <td key={ `${dimension}-${size}` }>
                  { sizeChartData[size][dimension][measurements[activeUnit]] }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="sizechart-modal">
      { sizeChartUrl
        ? <img src={ sizeChartUrl } alt="Size Chart" />
        : (
          <>
            <h4 className={ `bs-modal__heading typ--center mb2 ` }>
              Sizing Guide
            </h4>
            { measurementToggle }
            { sizeChartTable }
          </>
        )
      }
    </div>
  );
};

const { shape, array, string } = propTypes;
SizeChartModal.propTypes = {
  sizeChartData: shape({}).isRequired,
  sizes: array.isRequired,
  dimensions: array.isRequired,
  measurements: array.isRequired,
  sizeChartUrl: string.isRequired
};

export default SizeChartModal;
