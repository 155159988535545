import get from 'lodash/get';

export default props => `
  .btn,
  .btn--primary {
    background-color: ${get(props, 'primary.backgroundColor') || '#000000'};
    color: ${get(props, 'primary.color') || '#FFFFFF'};
    border-color: ${get(props, 'primary.borderColor') || '#000000'};
  }

  .btn--secondary {
    background-color: ${get(props, 'secondary.backgroundColor') || '#FFFFFF'};
    color: ${get(props, 'secondary.color') || '#000000'};
    border-color: ${get(props, 'secondary.borderColor') || '#000000'};
  }
`;
