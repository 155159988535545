import React from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useSupport } from '../hooks/useSupport';
import './SupportArticle.scss';

export const SupportArticle = () => {
  const { posts } = useSupport();
  const { postSlug } = useParams();
  const post = posts.find(singlePost => singlePost.slug === postSlug);

  return (
    <article className="support__article">
      <h1 className="mb3">{ post.title }</h1>

      <div className="article__content">
        { parse(post.content) }
      </div>
    </article>
  );
};

export default SupportArticle;
