/* eslint-disable camelcase */
import { useDispatch, useSelector } from 'react-redux';
import { fetchCartListings, isFetchingForUnavailability, setActiveModal } from 'redux/actions';
import get from 'lodash/get';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { isUnavailableItem, pruneCart } from 'utils/cartUtils';
import { API_HOST } from '../constants';
import tracker from '../utils/tracking';

const useCartItemValidation = () => {
  const dispatch = useDispatch();
  const {
    userCart, localizationData, inventory, cartProducts
  } = useSelector(state => state);
  const { slug } = useSelector(state => state.stores);

  const cartHasAllValidItems = async () => {
    const cartSlugs = Object.keys(pruneCart(userCart)).map(async (item) => {
      try {
        const listingSlug = get(userCart[item], 'slug');
        const listing = cartProducts[listingSlug];

        // Fetch cart listings to check availability without dispatching & updating state
        const { buyer_locale, buyer_region, buyer_currency } = localizationData;
        const url = `${API_HOST}/v1/listings?slug=${listingSlug}&currency=${buyer_currency}&region=${buyer_region}&country_code=${buyer_locale}&storeSlug=${slug}&productId=${userCart[item]?.productId}`;

        const res = await fetch(url);

        if (!res.ok) return false;

        const { primaryProduct, visibility } = await res.json();
        const cartProduct = find(primaryProduct, ({ productId, variationId }) => productId === userCart[item].productId && variationId === userCart[item]?.colorId);
        cartProduct.visibility = visibility;
        const isAvailable = !isUnavailableItem(userCart[item], cartProduct, listing, inventory);
        return isAvailable;
      } catch (e) {
        // Caught unavailable listing - therefore item is not available
        return false;
      }
    });

    const cartListingAvailability = await Promise.all(cartSlugs);
    return !includes(cartListingAvailability, false);
  };

  const validateCartBeforeAction = async (callback) => {
    const isValidCart = await cartHasAllValidItems();

    if (isValidCart) {
      callback();
    } else {
      // Fetch listings with unavailable items to trigger updates
      dispatch(isFetchingForUnavailability(true));
      await dispatch(fetchCartListings(userCart, localizationData));
      dispatch(isFetchingForUnavailability(false));
      dispatch(setActiveModal(null));
      tracker.track('checkout.inactive.viewed');
    }
  };

  return {
    validateCartBeforeAction
  };
};

export default useCartItemValidation;
