import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import TrackJSLogger from './middlewares/trackjsLogger';
import rootReducer from './reducers/rootReducer';

/* eslint-disable-next-line no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [TrackJSLogger, thunkMiddleware];
export const storeMiddlewares = applyMiddleware(...middleware);

export default function configureStore() {
  return createStore(
    rootReducer,
    composeEnhancers(storeMiddlewares)
  );
}
