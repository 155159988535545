import { func } from 'prop-types';
import React from 'react';
import { Button } from '@springforcreators/propel-ui';
import { useSelector } from 'react-redux';
import tracker from 'utils/tracking';
import './CheckoutDetailsAddress.scss';

const CheckoutDetailsAddress = ({ setAddressHasBeenEntered }) => {
  const { customer } = useSelector(state => state.checkout);

  return (
    <div className="address">
      <div className="address__header">
        <h3>Delivery information</h3>
        <Button
          type="button"
          btnType="secondary"
          onClick={ () => {
            tracker.track('checkout.delivery_address.edit');
            setAddressHasBeenEntered(false);
          } }
        >
          Edit
        </Button>
      </div>
      <div className="address__body">
        <p>{ customer?.email }</p>
        <p>{ `${customer?.firstName} ${customer?.lastName}` }</p>
        <p>{ `${customer?.address?.address1} ${customer?.address?.address2}` }</p>
        <p>{ customer?.address?.city }</p>
        <p>{ `${customer?.address?.state} ${customer?.address?.zip},` }</p>
        <p>{ customer?.address?.country }</p>
        <p>{ customer?.phone }</p>
      </div>
    </div>
  );
};

CheckoutDetailsAddress.propTypes = {
  setAddressHasBeenEntered: func.isRequired
};

export default CheckoutDetailsAddress;
