import React from 'react';
import { string } from 'prop-types';

const ReturnPolicy = ({ linkText }) => (
  <a
    href="https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations/"
    target="_blank"
    rel="noopener noreferrer"
    alt="Return Policy"
  >
    { linkText }
  </a>
);

ReturnPolicy.propTypes = {
  linkText: string.isRequired
};

export default ReturnPolicy;
