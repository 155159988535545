import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@springforcreators/propel-ui';
import { useCartItemValidation, usePaymentRequest } from 'hooks';
import { withCookies, Cookies } from 'react-cookie';
import tracker from 'utils/tracking';
import { getCartLineItems, cartItemsWithQty, redirectToCheckout } from 'utils/cartUtils';
import propTypes from 'prop-types';
import get from 'lodash/get';
import './LegacyCheckoutButton.scss';

export const LegacyCheckoutButton = ({ cookies, title, className }) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { userCart, cartProducts, inventory } = useSelector(state => state);
  const storeID = useSelector(state => get(state, 'stores.slug'));
  const localizationData = useSelector(state => get(state, 'localizationData'));
  const cartItems = getCartLineItems(userCart, cartProducts, inventory);
  const cartItemsWithQuantity = cartItemsWithQty(cartItems);
  const { validateCartBeforeAction } = useCartItemValidation();
  const affiliate = cookies.get('affiliate');
  const { walletType } = usePaymentRequest();

  const stripeCheckout = () => {
    redirectToCheckout('stripe', cartItemsWithQuantity, storeID, localizationData, affiliate, userCart, cartProducts, walletType, inventory);
  };

  return (
    <Button
      type="button"
      data-testid="legacy-checkout-button"
      className={ className }
      loading={ isRedirecting }
      onClick={ () => {
        setIsRedirecting(true);
        tracker.track('checkout_page.afterpay-invalid-country-redirect.clicked');
        validateCartBeforeAction(stripeCheckout);
      } }
    >
      { title }
    </Button>

  );
};

const {
  instanceOf
} = propTypes;

LegacyCheckoutButton.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
  title: instanceOf(String),
  className: instanceOf(String)
};
LegacyCheckoutButton.defaultProps = {
  title: 'Complete my purchase',
  className: 'legacycheckoutbtn mt1'
};

export default withCookies(LegacyCheckoutButton);
