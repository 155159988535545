import React from 'react';
import { Helmet } from 'react-helmet';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

export const SEO = ({
  meta, content, data, image, title, description
}) => {
  const { seoTitle, storeName, storeUrl } = meta ?? {};
  const url = storeUrl ?? window.location.href;
  const metaDescription = description || `Shop from ${storeName} to support you favorite creator! See all the available product with additional colors and styles at ${storeName}.`;
  const previewImage = image || get(content, 'heroBanner.containerBg');

  const metaData = {
    description: metaDescription,
    keywords: get(seoTitle, 'keywords'),
    'og:type': 'website',
    'og:url': url,
    'og:site_name': title,
    'og:image': previewImage,
    'og:description': metaDescription,
    'twitter:domain': url,
    'twitter:site': url,
    'twitter:card': 'summary_large_image',
    'twitter:creator': '',
    'twitter:description': metaDescription,
    'twitter:title': title,
    'twitter:image': previewImage,
    'og:title': title
  };

  const mergedData = { ...metaData, ...data };

  return (
    <Helmet>
      <title>{ title }</title>
      { Object.keys(mergedData)
        .map(key => <meta key={ key } property={ key } content={ mergedData[key] } />) }
    </Helmet>
  );
};

const { shape, string } = propTypes;
SEO.propTypes = {
  meta: shape({
    seoTitle: shape({
      title: string,
      description: string
    }),
    storeName: string,
    storeUrl: string
  }),
  content: shape({
    heroBanner: shape({ containerBg: string })
  }).isRequired,
  data: shape({
    name: string,
    property: string,
    content: string
  }),
  image: string,
  title: string,
  description: string
};

SEO.defaultProps = {
  meta: {},
  data: {},
  image: undefined,
  title: undefined,
  description: undefined
};

const mapStateToProps = state => ({
  meta: get(state, 'themeData.meta'),
  content: get(state, 'themeData.content')
});

export default connect(mapStateToProps)(SEO);
