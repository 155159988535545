import React, { useEffect, Fragment } from 'react';
import { manageZendeskVisibility } from 'utils/zendeskUtils';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { bpProps } from 'utils/responsiveUtils';
import ListingPageWrapper from './ListingPageWrapper';

const ListingPage = (props) => {
  const {
    listingDetails,
    listingGallery,
    relatedProducts,
    privateListing,
    setClass
  } = props;
  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));
  useEffect(() => {
    manageZendeskVisibility(false);
  }, [bpIsGT('mobileLg')]);
  return (
    <Fragment>
      <div className="private-listing">
        { privateListing }
      </div>
      <div className="listing-content">
        { listingGallery }

        <div className={ setClass({ default: 'col--5', desktopSm: 'col--6', tabletSm: ' ' }) }>
          { listingDetails }
        </div>
      </div>

      { relatedProducts }
    </Fragment>
  );
};

ListingPage.defaultProps = {};
const { node, func } = propTypes;
ListingPage.propTypes = {
  listingDetails: node.isRequired,
  listingGallery: node.isRequired,
  relatedProducts: node.isRequired,
  privateListing: node.isRequired,
  setClass: func.isRequired
};

export default ListingPageWrapper(ListingPage);

