// eslint-disable-next-line no-unused-vars
import TweenLite from 'gsap/umd/TweenLite';
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin';
// eslint-disable-next-line no-unused-vars
const gsapPlugins = [ScrollToPlugin];

/**
 * Triggers a scrolling animation to a specific element, using gsap
 * @param {string} selector String representing selector
 * @param {Object} event Event that triggers scrollTo
 * @param {node} scrollContainer DOM element represnting container to scroll
 * @param {number} duration Seconds to animate scroll over
 */
export function scrollTo(selector, event, scrollContainer = document.scrollingElement, duration = 0.5) {
  if (event) event.preventDefault();
  const elem = document.querySelector(selector);

  if (elem) {
    const scrollPos = elem.offsetTop;
    // Make sure duration is sane
    const clampedDuration = Math.max(+duration || 0, 0) || 0;
    // Run the scroll tween, disabling immedate rendering for zero duration, could also use TweenLite.set, but this keeps a common call.
    // see: https://greensock.com/forums/topic/6376-using-greensock-to-change-classes-without-tweening-styles/?do=findComment&comment=23032
    TweenLite.to(scrollContainer, clampedDuration, { scrollTo: { y: scrollPos }, immediateRender: clampedDuration > 0 });
  }
}

export function scrollToSpecificHorizontalValue(scrollDistance, scrollContainer = document.scrollingElement) {
  const currentScrollPos = scrollContainer.scrollLeft;
  TweenLite.to(scrollContainer, 0.5, { scrollTo: { x: currentScrollPos + scrollDistance } });
}
