import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import some from 'lodash/some';
import propTypes from 'prop-types';
import { setActiveModal, removeFromCart } from 'redux/actions';
import './UnavailableItemsModal.scss';

export const UnavailableItemsModal = (props) => {
  const {
    items, storeId, dispatchSetActiveModal, dispatchRemoveFromCart
  } = props;

  useEffect(() => {
    items?.forEach((item) => {
      dispatchRemoveFromCart(storeId, item.sku);
    });
  }, [dispatchRemoveFromCart, items, storeId]);

  const hasItemFromUnavailableListing = some(items, item => item.fromUnavailableListing);

  return (
    <>
      <p className="mb2">
        <strong className="typ--caps typ--bold">Attention:</strong>
        <br />
        <span className="typ--b3">
          {
            hasItemFromUnavailableListing
              ? 'Some items were removed from your cart because they are not available at this time.'
              : 'The following item(s) were removed from your cart because they are not available at this time.'
          }
        </span>
      </p>

      { !hasItemFromUnavailableListing
        && (
          <ol className="typ--b3 mb3 unavailable-items__list">
            { items?.map(item => (
              <li className="unavailable-items__item" key={ item.sku }>
                <div className="typ--bold">{item.title ? item.title : item.slug}</div>
                {item.productType && <div>{item.productType}</div>}
              </li>
            ))}
          </ol>
        )}

      <button
        type="button"
        className="btn"
        onClick={ () => dispatchSetActiveModal(null) }
      >
        OK
      </button>
    </>
  );
};

const {
  arrayOf, shape, func, string
} = propTypes;

UnavailableItemsModal.propTypes = {
  items: arrayOf(shape({})).isRequired,
  dispatchSetActiveModal: func.isRequired,
  dispatchRemoveFromCart: func.isRequired,
  storeId: string.isRequired
};

const mapDispatchToProps = dispatch => ({
  dispatchSetActiveModal: (id, props) => dispatch(setActiveModal(id, props)),
  dispatchRemoveFromCart: (storeId, sku) => dispatch(removeFromCart(storeId, sku))
});

export default connect(null, mapDispatchToProps)(UnavailableItemsModal);
