/*eslint-disable camelcase, no-unused-expressions*/
import { cartContentIds, getPersistantCart } from 'utils/cartUtils';
import tracker from 'utils/tracking';
import { usBasedBuyer } from 'utils/localizationUtils';
import get from 'lodash/get';
import { USE_MINERVA, GA360_TRACKING_IDS, HIDE_AFTERPAY } from '../../constants';
import { getCookie } from '../cookiesUtils';

export const CHECKOUT_HOST = process.env.REACT_APP_CHECKOUT_HOST ? process.env.REACT_APP_CHECKOUT_HOST : 'https://teespring.com';

export const getRandomIntInclusive = (min, max) => {
  const roundedMin = Math.ceil(min);
  const roundedMax = Math.floor(max);
  return Math.floor(Math.random() * (roundedMax - roundedMin + 1)) + roundedMin; //The maximum is inclusive and the minimum is inclusive
};

export const getSocialLinksFromIds = (socialIdentities) => {
  const socialPaths = {
    facebook: { basePath: 'http://faceboook.com/' },
    instagram: { basePath: 'http://instagram.com/' },
    twitter: { basePath: 'http://twitter.com/' },
    youtube: { basePath: 'http://youtube.com/' }
  };

  const socialLinks = Object.keys(socialIdentities).map((network) => {
    const networkData = {
      network
    };
    networkData.path = `${socialPaths[network].basePath}${socialIdentities[network]}`;
    return networkData;
  });

  return socialLinks;
};

export const addStyleLinkToHeader = (path) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = path;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};

export const injectCustomCSS = (css) => {
  const node = document.createElement('style');
  document.head.appendChild(node);
  node.innerHTML = css;
};

export const addFaviconLinkToHeader = (faviconHref) => {
  if (faviconHref) {
    const link = window.document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = faviconHref;
    const head = window.document.getElementsByTagName('head')[0];
    head.appendChild(link);
  }
};

const findGaTrackingId = gaTracker => GA360_TRACKING_IDS.includes(gaTracker.get('trackingId'));

export const getLinkerParam = () => {
  if (!window.ga?.length) {
    return '';
  }

  const gaTracker = window.ga?.getAll().find(findGaTrackingId) || window.ga?.getAll()[0] || null;
  return gaTracker ? gaTracker.get('linkerParam').split('=')[1] : '';
};

export const themedCheckoutURL = (paymentMethod, skuData, storeId, localizationData, affiliate, userCart, cartProducts, walletType, inventory, disableAfterpay = false) => {
  const checkoutUrl = `${CHECKOUT_HOST}/buy/express_checkout`;
  const { buyer_currency, buyer_locale } = localizationData;
  const shippingParam = USE_MINERVA ? 'minerva' : getCookie('shipping');
  const persistedCart = getPersistantCart(storeId);
  const isUSCart = get(persistedCart, 'region') === 'USA';

  const params = {
    currency: buyer_currency,
    locale: buyer_locale,
    payment_method: paymentMethod,
    ship_method: 1,
    skus: skuData,
    store_id: storeId,
    store_url: document.location.origin,
    aid: affiliate,
    device_id: tracker.deviceId(),
    _ga: getLinkerParam(),
    content_ids: cartContentIds(userCart, cartProducts, inventory),
    shipping: usBasedBuyer() ? shippingParam : ''
  };

  if (usBasedBuyer() && isUSCart) {
    const methods = [];

    if (!HIDE_AFTERPAY && !disableAfterpay) methods.push('afterpay');
    if (walletType) methods.push(walletType);

    params.method = methods.join(',');
  }

  const urlParams = Object.entries(params)
    .filter(entry => !!entry[1])
    .map(([param, value]) => `${param}=${encodeURIComponent(value)}`)
    .join('&');

  return `${checkoutUrl}?${urlParams}`;
};

/**
 * Converts a hash string into a object
 * @param {*} hash  Hash string. ie #key1=value1&key2=value etc
 * @return {Object} Returns an object of key value pars that match the hash params
 */
export const convertHashToParams = (hash) => {
  if (!hash) return {};

  const hashPairs = hash.replace('#', '').split('&');
  const data = {};
  hashPairs.forEach((pair) => {
    const keyValues = pair.split('=');
    data[keyValues[0]] = unescape(keyValues[1]);
  });

  return data;
};
