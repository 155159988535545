/* eslint camelcase: 0, import/no-cycle: 0, no-await-in-loop: 0, no-plusplus: 0, no-use-before-define: 0 */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { convertToCents } from 'utils/moneyUtils';
import requestPaymentIntent from 'lib/paymentIntent';
import { getCartLineItems, getCartLineItemsTotal } from 'utils/cartUtils';
import { getCookie, setCookie, deleteCookie } from 'utils/cookiesUtils';
import { encrypt, isCheckoutComplete } from 'utils/checkoutUtils';
import { CHECKOUT_HOST_V2, PAYMENT_HOST } from '../../constants';
import {
  fetchCartListings,
  IS_FETCHING_CART_LISTINGS,
  FINISH_FETCHING_CART_LISTINGS
} from './listings';
import { destroyCart } from '.';
import { addToast } from './toast';
import { clearDeliveryOptions } from './deliveryOptions';

export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_ORDER_COST = 'SET_ORDER_COST';
export const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT';
export const SET_CHECKOUT_ERROR = 'SET_CHECKOUT_ERROR';
export const SET_STRIPE_ERROR = 'SET_STRIPE_ERROR';
export const CREATE_CHECKOUT_ERROR = 'CREATE_CHECKOUT_ERROR';
export const CREATE_CHECKOUT_SUCCESS = 'CREATE_CHECKOUT_SUCCESS';
export const RESET_CHECKOUT_STATE = 'RESET_CHECKOUT_STATE';
export const FETCHING_CHECKOUT_DATA = 'FETCHING_CHECKOUT_DATA';
export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const SET_PROMO_CODE_ERROR = 'SET_PROMO_CODE_ERROR';
export const SET_UPDATING_CHECKOUT = 'SET_UPDATING_CHECKOUT';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_CHECKOUT_LOADING = 'SET_CHECKOUT_LOADING';

export const setPayment = payment => ({
  type: SET_PAYMENT,
  data: payment
});

// Helpers
const getCheckout = async (checkoutId, slug) => {
  const url = `${CHECKOUT_HOST_V2}/checkout?id=${checkoutId}&storeSlug=${slug}`;
  const data = await (await fetch(url)).json();
  return data;
};

const getPayment = (paymentId, checkoutId) => async (dispatch) => {
  try {
    const data = await (
      await fetch(
        `${PAYMENT_HOST}/v1/payment?paymentId=${paymentId}&checkoutId=${checkoutId}`
      )
    ).json();
    dispatch(setPayment(data));
    if (data.state === 'failed') {
      dispatch(
        addToast('Payment failed, please try again.', 'danger', {
          timeout: 6000,
          position: 'top'
        })
      );
    }
  } catch (err) {
    throw new Error(`An error occured: ${err.message}`);
  }
};

const sleep = async () => {
  return new Promise(resolve => setTimeout(resolve, 1000));
};

export const setOrderCost =
  (customer = {}) => async (dispatch, getState) => {
    try {
      const {
        userCart, localizationData, cartProducts, checkout, inventory
      } =
        getState();

      if (!(customer?.zip || checkout?.customer?.address?.zip)) {
        const cartLineItems = getCartLineItems(
          userCart,
          cartProducts,
          inventory
        );
        const totalAmount = getCartLineItemsTotal(cartLineItems);

        dispatch({
          type: SET_ORDER_COST,
          data: {
            total: {
              value: convertToCents(totalAmount),
              currency: localizationData?.buyer_currency
            }
          }
        });
      }
    } catch (err) {
      throw new Error(err);
    }
  };

export const createCheckout = lineItems => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_UPDATING_CHECKOUT,
      data: true
    });
    const { slug } = getState().stores;
    const trafficSource = getCookie('utm_source');
    const affiliate = getCookie('affiliate');

    const res = await fetch(`${CHECKOUT_HOST_V2}/checkout`, {
      method: 'POST',
      body: JSON.stringify({
        lineItems,
        storeSlug: slug,
        trafficSource,
        affiliate
      })
    });
    const data = await res.json();
    if (isEmpty(data)) {
      dispatch(addToast('Error checking out! Please try again.', 'danger'));
    }
    dispatch({
      type: UPDATE_CHECKOUT,
      data
    });

    if (!isEmpty(data)) {
      await dispatch(setOrderCost());
    }
    dispatch({ type: CREATE_CHECKOUT_SUCCESS });
  } catch (err) {
    dispatch(addToast(`Error checking out: ${err?.message}`, 'danger'));
    dispatch({ type: CREATE_CHECKOUT_ERROR, error: err?.message });
    throw new Error(err);
  } finally {
    dispatch({
      type: SET_UPDATING_CHECKOUT,
      data: false
    });
    setTimeout(() => {
      dispatch({
        type: SET_CHECKOUT_LOADING,
        data: false
      });
    }, 1000);
  }
};

export const pollCheckout = (checkoutId, storeSlug) => async (dispatch) => {
  let counter = 1;

  while (counter < 5) {
    await sleep();
    const checkout = await getCheckout(checkoutId, storeSlug);

    if (checkout.state === 'success') {
      dispatch({
        type: UPDATE_CHECKOUT,
        data: checkout
      });
      dispatch({ type: FINISH_FETCHING_CART_LISTINGS });
      break;
    } else {
      counter++;
    }
  }
};

export const resetCheckout = () => ({
  type: RESET_CHECKOUT_STATE
});

/**
 * Gets checkout data using the checkout id and store slug
 * @param {string} checkoutId  The checkout id
 * @param {string} slug   The store slug
 * @param {string} id   The decrypted payment id
 * @return {function}     The dispatch function
 */
export const fetchCheckout =
  (checkoutId, slug, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: IS_FETCHING_CART_LISTINGS });

      if (id) {
        await dispatch(getPayment(id, checkoutId));
        const { checkout } = getState();
        if (get(checkout, 'payment.state') !== 'failed') {
          return await dispatch(pollCheckout(checkoutId, slug));
        }
      }

      const { userCart, localizationData } = getState();
      const data = await getCheckout(checkoutId, slug);
      const payment = getCookie('payment');

      dispatch({
        type: UPDATE_CHECKOUT,
        data: { ...data, payment: payment ? JSON.parse(payment) : {} }
      });

      if (isCheckoutComplete(data.state)) {
        return dispatch({ type: FINISH_FETCHING_CART_LISTINGS });
      } else {
        return await dispatch(fetchCartListings(userCart, localizationData));
      }
    } catch (err) {
      throw new Error(`An error occured: ${err.message}`);
    }
  };

export const setUpdatingCheckout = updatingCheckout => async (dispatch) => {
  dispatch({
    type: SET_UPDATING_CHECKOUT,
    data: updatingCheckout
  });
};

export const updateCheckout =
  valuesToUpdate => async (dispatch, getState) => {
    try {
      const { checkout, stores } = getState();
      let payload = {};
      if (!('marketingOptIn' in valuesToUpdate)) {
        dispatch({
          type: FETCHING_CHECKOUT_DATA,
          data: true
        });
        payload = {
          storeSlug: stores?.slug,
          promoCode: checkout?.promoCode,
          ...valuesToUpdate
        };
      } else {
        payload = {
          storeSlug: stores?.slug,
          marketingOptIn: valuesToUpdate.marketingOptIn
        };
      }
      dispatch({
        type: SET_UPDATING_CHECKOUT,
        data: true
      });

      const currentDeliveryOption = checkout?.deliveryOption;
      if (valuesToUpdate.deliveryOption) {
        dispatch({
          type: UPDATE_CHECKOUT,
          data: valuesToUpdate
        });
      }
      const res = await fetch(`${CHECKOUT_HOST_V2}/v1/checkout`, {
        method: 'PUT',
        body: JSON.stringify({
          id: checkout?.id,
          data: payload
        })
      });

      const updatedCheckout = await res.json();

      if (res.status !== 200) {
        dispatch({
          type: UPDATE_CHECKOUT,
          data: { deliveryOption: currentDeliveryOption }
        });
      }

      const errors =
        updatedCheckout?.message?.errors || updatedCheckout?.errors || [];
      if (errors.length > 0) {
        const promoCodeError = errors.find(e => e.source.pointer.includes('promoCode'));
        if (promoCodeError) {
          dispatch({
            type: SET_PROMO_CODE_ERROR,
            data: promoCodeError
          });
        }
      } else {
        dispatch({
          type: SET_PROMO_CODE_ERROR,
          data: ''
        });
      }

      // Customer data should only be pushed the store when
      // we are explicitly updating customer data
      if (!valuesToUpdate.customer) {
        delete updatedCheckout.customer;
      }

      dispatch({
        type: UPDATE_CHECKOUT,
        data: updatedCheckout
      });
    } catch (err) {
      throw new Error(err);
    } finally {
      dispatch({
        type: FETCHING_CHECKOUT_DATA,
        data: false
      });
      dispatch({
        type: SET_UPDATING_CHECKOUT,
        data: false
      });
      dispatch({
        type: SET_CHECKOUT_LOADING,
        data: false
      });
    }
  };

/**
 * Sets a checkout error
 * @param {string} error  An error message to display
 * @return {function}     The dispatch function
 */
export const setCheckoutError = error => async (dispatch) => {
  dispatch({
    type: SET_CHECKOUT_ERROR,
    data: error
  });
};

export const submitCheckout =
  (paymentToken = null, paymentId = null) => async (dispatch, getState) => {
    try {
      const { checkout, stores } = getState();
      const { payment } = checkout;
      const checkoutId = get(checkout, 'id');
      const storeSlug = get(stores, 'slug');
      const encryptedId = encrypt(get(payment, 'id'));
      const res = await fetch(`${CHECKOUT_HOST_V2}/v1/checkout/submit`, {
        method: 'POST',
        body: JSON.stringify({
          checkoutId,
          storeSlug,
          paymentToken: paymentToken ?? get(payment, 'paymentToken'),
          paymentId: paymentId ?? get(payment, 'id'),
          platform: get(checkout, 'platform'),
          returnUrl: `${window.location.href}?id=${encryptedId}`
        })
      });

      const {
        state, redirectUrl, displayMessage, message
      } = await res.json();

      if (!res.ok) {
        if (message === 'Stripe error') {
          dispatch({
            type: SET_STRIPE_ERROR,
            data: displayMessage
          });
        } else {
          dispatch(setCheckoutError(displayMessage));
        }
      } else if (redirectUrl) {
        dispatch({
          type: UPDATE_CHECKOUT,
          data: {
            state,
            redirectUrl
          }
        });
      } else {
        await dispatch(pollCheckout(checkoutId, storeSlug));
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

/**
 * Gets payment intent and adds it to checkout object
 * @param {string} paymentMethodId  The id returned from stripe when we create a paymentMethod
 * @param {string} currentPaymentToken  The paymentToken that exists when updating the payment
 * @return {function}     The dispatch function
 */
export const getPaymentIntent =
  (paymentMethodId, currentPaymentToken = null) => async (dispatch, getState) => {
    try {
      const state = getState();

      const { paymentToken, paymentId, meta } = await requestPaymentIntent(
        state,
        paymentMethodId,
        currentPaymentToken
      );
      const payment = { paymentToken, id: paymentId };

      setCookie('payment', JSON.stringify(payment));

      dispatch(setPayment({ ...payment, meta }));
    } catch (err) {
      throw new Error(`Error when getting payment intent: ${err.message}`);
    }
  };

/**
 * Sets the platform and paymentMethod
 * @param {string} paymentMethod  E.g. card | afterpay
 * @return {function}     The dispatch function
 */
export const setPaymentMethod = paymentMethod => (dispatch) => {
  const stripeMethods = [
    'card',
    'afterpay',
    'google-pay',
    'apple-pay',
    'basic-card'
  ];
  dispatch({
    type: SET_PAYMENT_METHOD,
    data: {
      platform: stripeMethods.includes(paymentMethod) ? 'stripe' : 'paypal',
      paymentMethod
    }
  });
};

/**
 * Clears checkout and destroys the cart
 * @return {function}     The dispatch function
 */
export const clearCheckout = () => async (dispatch, getState) => {
  const { checkout, stores } = getState();
  if (!isCheckoutComplete(get(checkout, 'state'))) return;
  await dispatch(destroyCart(get(stores, 'slug')));
  deleteCookie('payment');

  dispatch({
    type: CLEAR_CHECKOUT
  });
  dispatch(clearDeliveryOptions());
};

/**
 * Disables the checkout button
 * @param {boolean} isLoading  Whether the checkout isLoading
 * @return {function}     The dispatch function
 */
export const setCheckoutLoading = isLoading => (dispatch) => {
  dispatch({
    type: SET_CHECKOUT_LOADING,
    data: isLoading
  });
};
