import React, { useEffect } from 'react';
import MainLayout from 'containers/MainLayout';
import propTypes from 'prop-types';
import tracker from 'utils/tracking';
import './Home.scss';

function HomeWrapper(WrappedComponent) {
  const BaseTheme = (props) => {
    const {
      storeData,
      location
    } = props;

    useEffect(() => {
      document.querySelector('body').classList.add('home');

      return function cleanup() {
        document.querySelector('body').classList.remove('home');
      };
    });

    useEffect(() => {
      tracker.track('homepage.viewed');
    }, []);

    return (
      <MainLayout
        location={ location }
        storeData={ storeData }
      >
        <div className="home-content">
          <WrappedComponent { ...props } />
        </div>
      </MainLayout>
    );
  };

  const { shape } = propTypes;
  BaseTheme.propTypes = {
    location: shape({}).isRequired,
    storeData: shape({}).isRequired
  };

  return BaseTheme;
}

export default HomeWrapper;
