import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, InputField } from '@springforcreators/propel-ui';
import ThemeButton from 'components/ThemeButton';
import { useForm } from 'react-hook-form';
import './TrackOrderPage.scss';

export const TrackOrderPage = () => {
  const { handleSubmit, register, formState } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  function onSubmit(values) {
    // Post to backend in another ticket
    // Temp return for eslint
    return values;
  }

  return (
    <form className="track-order" onSubmit={ handleSubmit(onSubmit) }>
      <div className="order-tracking">
        <h1>Order tracking</h1>
        <p>
          To track your order, use the order number found in your confirmation
          email.
        </p>
        <div className="order-number">
          <h5 className="my2">Order number *</h5>

          <InputField
            type="text"
            name="track-ordernumber"
            label="#"
            ref={ register }
            error={ formState?.errors['track-ordernumber']?.message }
          />

          <Link to="/support/track-my-order" className="mt1 layout--block">Where do I find my order number?</Link>
          <ThemeButton className="btn--icon mt2 mb1" type="submit">
            Track order
            <Icon name="ArrowRight" size={ 16 } />
          </ThemeButton>
          <p>
            Having problems tracking your order?&nbsp;
            <Link to="/contact">Contact us</Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default TrackOrderPage;
