import { useMemo } from 'react';

const usePaymentRequestOptions = (paymentRequest, buttonStyles) => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: buttonStyles
      }
    }),
    [paymentRequest]
  );

  return options;
};

export default usePaymentRequestOptions;
