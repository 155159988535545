/*eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for*/

import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { updateCart, removeFromCart } from 'redux/actions';
import { bpProps } from 'utils/responsiveUtils';
import propTypes from 'prop-types';
import ThemeWrapper from 'containers/ThemeWrapper';
import QtyField from 'components/QtyField';
import { listingUrlParts } from 'utils/urlGeneration';
import tracker from 'utils/tracking';
import Price from 'components/Price';
import get from 'lodash/get';
import { pushEvent, REMOVE_FROM_CART } from 'utils/tracking/gtm';
import { isDigitalProduct, isNFTProduct } from 'utils/productUtils';
import './CartItem.scss';

export const CartItem = (props) => {
  const {
    item,
    storeId,
    userCart,
    bpIsGT,
    dispatchUpdateCart,
    dispatchRemoveFromCart,
    storeName,
    sellerId
  } = props;

  const { sku } = item;
  const { productId } = item.variantData;
  const { variationId: productVariationId } = item.variantData;
  const { slug } = userCart[sku];
  const { pathname, search } = listingUrlParts(slug, productId, productVariationId, get(item, 'selectedSize.id'));
  const { stores } = useSelector(state => state);
  const removeCartItem = () => {
    pushEvent(REMOVE_FROM_CART, {
      ...item,
      storeName,
      sellerId,
      storeId: stores.id,
      storeSlug: stores.slug
    });
    tracker.track(
      'cart_page.remove_item.clicked',
      { sku }
    );
    dispatchRemoveFromCart(storeId, sku);
  };

  const editCartItemQuantity = (value) => {
    tracker.track(
      'cart_page.quantity_dropdown.clicked',
      {
        updatedQty: value
      }
    );
    dispatchUpdateCart(item.sku, { ...userCart[item.sku], quantity: +value });
  };

  return (
    <li className="cart-item">
      <div className="cart-item-row">
        <div className="cart-item-img-container">
          <Link
            to={ { pathname, search, state: { sku } } }
            key={ sku }
          >
            <img className="cart-item-img" src={ get(item, 'primaryImages.thumblarge') } alt="" />
          </Link>
        </div>

        <div className="cart-item-info">
          <div className="cart-item-title">
            <Link
              to={ { pathname, search, state: { sku } } }
              key={ sku }
            >
              { item.title }
            </Link>
          </div>
          <div className="cart-item-product-name">
            { item.productType }
            {!isDigitalProduct(item) && (
              <div className="cart-item-variation">
                {`Size: ${get(item, 'selectedSize.label')}, Color: ${item.colorName}`}
              </div>
            )}
          </div>

          <button
            type="submit"
            className="typ--link cart-item-remove-btn"
            onClick={ removeCartItem }
          >
            Remove
          </button>
        </div>

        <div className="cart-item__controls">
          {!isNFTProduct(item) && bpIsGT('mobileLg') && (
            <QtyField
              id={ item.sku }
              value={ item.quantityInCart }
              onChange={ editCartItemQuantity }
            />
          )}

          <div className={ `cart-item-price${isNFTProduct(item) ? 'cart-item-price--left' : ''}` }>
            { bpIsGT('mobileLg') && (
              <div className="cart-item-price-label">Total</div>
            )}
            <Price value={ item.totalPrice } />
          </div>
        </div>
      </div>
      {!bpIsGT('mobileLg') && (
        <div className="cart-item-row">
          <QtyField
            id={ item.sku }
            value={ item.quantityInCart }
            onChange={ editCartItemQuantity }
            className="cart-item-qty"
          />
        </div>
      )}
    </li>
  );
};

const {
  shape,
  string,
  func,
  object,
  number,
  oneOfType
} = propTypes;

CartItem.propTypes = {
  item: shape({
    primaryImgPath: string,
    title: string,
    totalPrice: oneOfType([string, number]),
    productType: string,
    sku: string,
    quantityInCart: number,
    selectedSize: shape({
      id: number,
      label: string
    }),
    colorName: string
  }).isRequired,
  userCart: shape({
    object
  }).isRequired,
  dispatchRemoveFromCart: func.isRequired,
  dispatchUpdateCart: func.isRequired,
  storeId: string.isRequired,
  bpIsLT: func.isRequired,
  bpIsGT: func.isRequired,
  storeName: string.isRequired,
  sellerId: number.isRequired
};

const mapStateToProps = (state) => {
  return {
    storeId: state.stores.slug,
    userCart: state.userCart,
    ...bpProps(state),
    storeName: state.stores.name,
    sellerId: state.stores.sellerId
  };
};

export default connect(mapStateToProps, {
  dispatchUpdateCart: updateCart,
  dispatchRemoveFromCart: removeFromCart
})(ThemeWrapper(CartItem, []));
