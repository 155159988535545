import get from 'lodash/get';

const changeDefault = (selectedValue, defaultValue, newDefault) => {
  return selectedValue === defaultValue ? newDefault : selectedValue;
};

export default (props) => {
  const headerFontFamily = (headerNumber) => {
    const selectedFont = get(props, `h${headerNumber}.fontFamily`) || get(props, 'headingFontFamily');
    return changeDefault(selectedFont, 'Roboto Condensed, sans-serif', 'DM Sans, sans-serif');
  };

  return `
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Inter&display=swap');
  .App {
    font-size: 1.6rem;
    line-height: 1.712;
    font-family: Inter, sans-serif;
  }

  button {
    font-family: Inter, sans-serif;
  }

  h1,
  .typ--h1 {
    font-size: ${changeDefault(get(props, 'h1.fontSize'), '4.2rem', '1.8em') || '4.7rem'};
    line-height: ${get(props, 'h1.lineHeight') || '1.17021277'};
    font-weight: ${get(props, 'h1.fontWeight') || '600'};
    font-family: ${headerFontFamily(1)};
    color: ${get(props, 'h1.color') || 'inherit'};
    letter-spacing: 0;
    -webkit-text-fill-color: ${get(props, 'h1.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'h1.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'h1.webkitTextStrokeColor')};
  }

  h2,
  .typ--h2 {
    font-size: ${changeDefault(get(props, 'h2.fontSize'), '2.8rem', '1.5em') || '3.6rem'};
    line-height: ${get(props, 'h2.lineHeight') || '1.16216216'};
    font-weight: 500;
    font-family: ${headerFontFamily(2)};
    color: ${get(props, 'h2.color') || 'inherit'};
    letter-spacing: 0;
    -webkit-text-fill-color: ${get(props, 'h2.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'h2.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'h2.webkitTextStrokeColor')};
  }

  h3,
  .typ--h3 {
    font-size: ${get(props, 'h3.fontSize') || '3.6rem'};
    line-height: ${get(props, 'h3.lineHeight') || '1.36363636'};
    font-weight: ${get(props, 'h3.fontWeight') || '600'};
    font-family: ${headerFontFamily(3)};
    color: ${get(props, 'h3.color') || 'inherit'};
    letter-spacing: 0;
    -webkit-text-fill-color: ${get(props, 'h3.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'h3.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'h3.webkitTextStrokeColor')};
  }

  h4,
  .typ--h4 {
    font-size: ${get(props, 'h4.fontSize') || '2.4rem'};
    line-height: ${get(props, 'h4.lineHeight') || '1.16666667'};
    font-weight: ${get(props, 'h4.fontWeight') || '600'};
    font-family: ${headerFontFamily(4)};
    color: ${get(props, 'h4.color') || 'inherit'};
    letter-spacing: 0;
    -webkit-text-fill-color: ${get(props, 'h4.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'h4.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'h4.webkitTextStrokeColor')};
  }

  h5,
  .typ--h5 {
    font-size: ${get(props, 'h5.fontSize') || '1.8rem'};
    line-height: ${get(props, 'h5.lineHeight') || '1.66666667'};
    font-weight: ${get(props, 'h5.fontWeight') || '600'};
    font-family: ${headerFontFamily(5)};
    color: ${get(props, 'h5.color') || 'inherit'};
    letter-spacing: 0;
    -webkit-text-fill-color: ${get(props, 'h5.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'h5.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'h5.webkitTextStrokeColor')};
  }

  h6,
  .typ--h6 {
    font-size: ${get(props, 'h6.fontSize') || '1.6rem'};
    line-height: ${get(props, 'h6.lineHeight') || '1.66666667'};
    font-weight: ${get(props, 'h6.fontWeight') || '400'};
    font-family: ${headerFontFamily(6)};
    color: ${get(props, 'h6.color') || 'inherit'};
    letter-spacing: 0;
    -webkit-text-fill-color: ${get(props, 'h6.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'h6.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'h6.webkitTextStrokeColor')};
  }

  .typ--b1 {
    font-size: ${get(props, 'b1.fontSize') || '1.6rem'};
    line-height: ${get(props, 'b1.lineHeight') || '1.66666667'};
    font-weight: ${get(props, 'b1.fontWeight') || '400'};
    font-family: ${get(props, 'b1.fontFamily') || get(props, 'bodyFontFamily')};
    color: ${get(props, 'b1.color') || 'inherit'};
    letter-spacing: ${get(props, 'b1.letterSpacing') || 'normal'};
    -webkit-text-fill-color: ${get(props, 'b1.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'b1.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'b1.webkitTextStrokeColor')};
  }

  .typ--b2 {
    font-size: ${get(props, 'b2.fontSize') || '1.5rem'};
    line-height: ${get(props, 'b2.lineHeight') || '1.66666667'};
    font-weight: ${get(props, 'b2.fontWeight') || '400'};
    font-family: ${get(props, 'b2.fontFamily') || get(props, 'bodyFontFamily')};
    color: ${get(props, 'b2.color') || 'inherit'};
    letter-spacing: ${get(props, 'b2.letterSpacing') || 'normal'};
    -webkit-text-fill-color: ${get(props, 'b2.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'b2.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'b2.webkitTextStrokeColor')};
  }

  .typ--b3 {
    font-size: ${get(props, 'b3.fontSize') || '1.4rem'};
    line-height: ${get(props, 'b3.lineHeight') || '1.66666667'};
    font-weight: ${get(props, 'b3.fontWeight') || '400'};
    font-family: ${get(props, 'b3.fontFamily') || get(props, 'bodyFontFamily')};
    color: ${get(props, 'b3.color') || 'inherit'};
    letter-spacing: ${get(props, 'b3.letterSpacing') || 'normal'};
    -webkit-text-fill-color: ${get(props, 'b3.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'b3.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'b3.webkitTextStrokeColor')};
  }

  .typ--b4 {
    font-size: ${get(props, 'b4.fontSize') || '1.2rem'};
    line-height: ${get(props, 'b4.lineHeight') || '1.66666667'};
    font-weight: ${get(props, 'b4.fontWeight') || '400'};
    font-family: ${get(props, 'b4.fontFamily') || get(props, 'bodyFontFamily')};
    color: ${get(props, 'b4.color') || 'inherit'};
    letter-spacing: ${get(props, 'b4.letterSpacing') || 'normal'};
    -webkit-text-fill-color: ${get(props, 'b4.webkitTextFillColor')};
    -webkit-text-stroke-width: ${get(props, 'b4.webkitTextStrokeWidth')};
    -webkit-text-stroke-color: ${get(props, 'b4.webkitTextStrokeColor')};
  }

  .typ--link {
    color: ${get(props, 'linkStyles.color') || '#595959'};
    text-decoration: ${get(props, 'linkStyles.textDecoration') || 'underline'};
  }
`;
};
