/* eslint-disable import/no-cycle, camelcase */
import { pruneCart } from 'utils/cartUtils';
import { fetchListingInventoryCount } from 'redux/actions/inventory';
import { fetchProductBlanks, fetchProductBlank } from './products';
import { API_HOST } from '../../constants';
import { setOrderCost } from './checkout';
import { removeFromCart, updateFulfillmentDetails } from '.';

export const RECEIVE_LISTING = 'RECEIVE_LISTING';
export const REQUEST_LISTING = 'REQUEST_LISTING';
export const REMOVE_LISTING = 'REMOVE_LISTING';
export const RECEIVE_CART_PRODUCT = 'RECEIVE_CART_PRODUCT';
export const RECEIVE_LISTING_PRODUCTS = 'RECEIVE_LISTING_PRODUCTS';
export const FETCH_LISTINGS = 'FETCH_LISTINGS';
export const FETCH_LISTING_PRODUCTS = 'FETCH_LISTING_PRODUCTS';
export const IS_FETCHING_CART_LISTINGS = 'IS_FETCHING_CART_LISTINGS';
export const FINISH_FETCHING_CART_LISTINGS = 'FINISH_FETCHING_CART_LISTINGS';
export const IS_FETCHING_FOR_UNAVAILABILITY = 'IS_FETCHING_FOR_UNAVAILABILITY';
export const SET_PRODUCT_TILE_INDEX = 'SET_PRODUCT_TILE_INDEX';
const HTTP_ERROR = 'HTTP_ERROR';

/**
 * Fetch the v1/listing endpoint
 * @param  {string}   listingSlug The slug of the store from teespring.com
 * @param  {number}   productId      The product ID
 * @param  {object}   localizationData   Object representing currency, region and locale
 * @param  {string}   storeSlug The slug of the store from teespring.com
 * @param  {boolean}  previewMode boolean representing if the store is in previewMode
 * @return {function}         The dispatch function
 */
export const fetchListingData = async (
  listingSlug, productId, localizationData, storeSlug, previewMode
) => {
  const { buyer_locale, buyer_region, buyer_currency } = localizationData;
  // eslint-disable-next-line max-len
  let url = `${API_HOST}/v1/listings?slug=${listingSlug}&currency=${buyer_currency}&region=${buyer_region}&country_code=${buyer_locale}&storeSlug=${storeSlug}`;

  if (productId) url += `&productId=${productId}`;
  if (previewMode) url += `&visibility=any`;

  const res = await fetch(url);
  return res.json();
};

/**
 * Starting listing request
 * @return {function}         The dispatch function
 */
export const requestListing = () => {
  return {
    type: FETCH_LISTINGS,
    isFetching: true
  };
};

/**
 * Finish listing products request and mark as received
 * @param  {string}   listingSlug The slug of the store from teespring.com
 * @param  {object}   data      The data returned from the store API
 * @return {Function}           Dispatch function
 */
export const receiveListingProducts = (listingSlug, data) => (dispatch) => {
  dispatch({
    type: RECEIVE_LISTING_PRODUCTS,
    products_payload: data.products_payload,
    listingSlug
  });

  dispatch(fetchProductBlanks(data));
};

/**
 * Finish listing product request for the cart and mark as received
 * @param  {string}   listingSlug The slug of the store from teespring.com
 * @param  {object}   data      The data returned from the store API
 * @return {Function}           Dispatch function
 */
export const receiveCartProduct = (listingSlug, data) => (dispatch) => {
  if (!data?.primaryProduct) {
    return;
  }

  // Add title and listingId to product data
  const { title, listingId } = data;
  const extra = (title || listingId) ? {
    ...(title ? { title } : undefined),
    ...(listingId ? { listingId } : undefined)
  } : undefined;
  const productData = data.primaryProduct.map((product) => {
    return {
      ...product,
      ...extra
    };
  });

  dispatch({
    type: RECEIVE_CART_PRODUCT,
    product_payload: productData,
    listingSlug
  });
};

/**
 * Get listing products data
 * @param  {string}   listingSlug The slug of the listing from teespring.com
 * @param  {string}   storeSlug The slug of the store from teespring.com
 * @param  {Object}   localizationData Object representing currency, region and locale
 * @return {function}          The dispatch function
 */
export const fetchListingProducts = (
  listingSlug,
  storeSlug,
  localizationData
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_LISTING_PRODUCTS,
      isFetching: true
    });

    const { previewMode } = getState().themeData;
    const { storeListings } = getState();
    const { buyer_locale, buyer_region, buyer_currency } = localizationData;
    const apiVersion = API_HOST === 'https://commerce.teespring.com' ? '/v0' : '';

    // eslint-disable-next-line max-len
    let url = `${API_HOST}${apiVersion}/listing/${listingSlug}?store_slug=${storeSlug}&currency=${buyer_currency}&region=${buyer_region}&country_code=${buyer_locale}`;

    if (previewMode) url += `&visibility=any`;

    const res = await fetch(url);

    if (!res.ok) {
      dispatch({ type: HTTP_ERROR, error: { status: res.status } });
      dispatch({
        type: FETCH_LISTING_PRODUCTS,
        isFetching: false
      });
    } else {
      const data = await res.json();
      const storeListing = storeListings[listingSlug];
      dispatch(receiveListingProducts(listingSlug, data));
      if (storeListing.length) {
        const modifiedListing = {
          ...storeListing,
          primaryProduct: data?.products_payload
        };
        dispatch(receiveCartProduct(listingSlug, modifiedListing, 'fetchListingProducts'));
      }
    }
  } catch (err) {
    // If err received is due to listing becoming unavailable...
    if (err.message === 'Failed to fetch') {
      // Don't throw error or cart will break. Instead, store in redux and continue
      dispatch({ type: HTTP_ERROR, error: { name: err.name, message: err.message } });
    } else {
      throw new Error(`An error occured: ${err}`);
    }
  }
};

/**
 * Save listing and request for its products
 * @param  {string} listingSlug The slug of the store
 * @param  {object} data     The data returned from the store API
 * @return {[type]}          [description]
 */
export const receiveListing = (listingSlug, data) => async (dispatch) => {
  dispatch({
    type: RECEIVE_LISTING,
    listingSlug,
    listing: data
  });

  dispatch(fetchProductBlank(data));
};

const setErrorFetchingListing = () => ({
  type: HTTP_ERROR,
  error: {
    status: 404,
    name: 'ListingError',
    message: 'Listing not found'
  }
});

const setErrorFetchingProduct = () => ({
  type: HTTP_ERROR,
  error: {
    status: 404,
    name: 'ProductError',
    message: 'Product not found'
  }
});

/**
 * Fetch Listing
 * @param  {string}   listingSlug       The slug of the listing
 * @param  {string}   [productId]       The id of the primary product
 * @param  {object}   [cartItem]        Cart item to update inventory
 * @return {function}                   The dispatch function
 */
export const fetchListing = (
  listingSlug,
  productId,
  cartItem
) => async (dispatch, getState) => {
  try {
    // Check if we already have a listing stored in state, if we do get that listing instead
    dispatch(requestListing());
    const { previewMode } = getState().themeData;
    const { slug } = getState().stores;
    const { userCart, localizationData } = getState();
    const data = await fetchListingData(listingSlug, productId, localizationData, slug, previewMode);

    if (data?.errors) {
      if (!cartItem) {
        if (data.errors.product) {
          dispatch(setErrorFetchingProduct());
        } else {
          dispatch(setErrorFetchingListing());
        }
      } else {
        const listingSku = (Object.keys(userCart) ?? []).find(sku => userCart[sku].slug === listingSlug);
        dispatch(removeFromCart(slug, listingSku));
        window.location.reload();
      }
    } else {
      if (data?.primaryProduct) {
        data.primaryProduct = data.primaryProduct.filter(prod => prod);
        if (data.primaryProduct.length === 0) {
          delete data.primaryProduct;
        }
      }

      const listingSku = (Object.keys(userCart) ?? []).find(sku => userCart[sku].slug === listingSlug);
      dispatch(updateFulfillmentDetails(listingSku, { ...data.fulfillmentDetails }));

      if (cartItem) {
        await dispatch(fetchListingInventoryCount(cartItem.colorId, cartItem.sizeId, data));
      }

      const primaryProduct = data?.primaryProduct;
      const withAvailableSizesWithId = primaryProduct[0].availableSizesWithId.length > 0;
      if (primaryProduct && withAvailableSizesWithId) dispatch(receiveCartProduct(listingSlug, data));
      dispatch(receiveListing(listingSlug, data));
    }
  } catch (err) {
    throw new Error(`Unable to fetch listing: ${err}`);
  }
};

/**
 * Update Listing
 * @param  {string}   listingSlug       The slug of the listing
 * @param  {Number}   productId         The id of the product
 * @return {function}                   The dispatch function
 */
export const updateListing = (
  listingSlug,
  productId
) => async (dispatch) => {
  dispatch({
    type: REMOVE_LISTING,
    listingSlug
  });

  await dispatch(fetchListing(listingSlug, productId));
};

/**
 * Fetch cart product
 *
 * @param  {string}   listingSlug       The slug of the listing
 * @param  {string}   productId         The ID of the product to fetch
 * @return {function}                   The dispatch function
 */
export const fetchCartProduct = (
  listingSlug,
  productId
) => async (dispatch, getState) => {
  try {
    const { slug } = getState().stores;
    const { userCart, localizationData } = getState();
    const data = await fetchListingData(listingSlug, productId, localizationData, slug, false);

    if (data.errors) {
      const listingSku = (Object.keys(userCart) ?? []).find(sku => userCart[sku].slug === listingSlug);
      dispatch(removeFromCart(slug, listingSku));
      window.location.reload();
    } else {
      dispatch(receiveCartProduct(listingSlug, data));
    }
  } catch (err) {
    throw new Error(`An error occured: ${err}`);
  }
};

export const fetchCartListings = userCart => async (dispatch) => {
  try {
    dispatch({ type: IS_FETCHING_CART_LISTINGS });
    const listings = [];
    const skus = Object.keys(pruneCart(userCart)) || [];
    const cartSlugs = skus.map(async (item) => {
      const cartItem = userCart[item];
      const { slug, productId } = cartItem;

      if (listings.indexOf(slug) === -1) {
        listings.push(slug);
        await dispatch(fetchListing(slug, productId, cartItem));
      } else {
        await dispatch(fetchCartProduct(slug, productId));
      }
    });

    await Promise.all(cartSlugs);
    dispatch({ type: FINISH_FETCHING_CART_LISTINGS });
    await dispatch(setOrderCost());
  } catch (err) {
    throw new Error(`An error occured: ${err}`);
  }
};

/**
 * Update the fetching status triggered from checking unavailability
 * @param  {boolean}   isFetching         The fetching status
 * @return {function}         The dispatch function
 */
export const isFetchingForUnavailability = (isFetching) => {
  return {
    type: IS_FETCHING_FOR_UNAVAILABILITY,
    isFetchingForUnavailability: isFetching
  };
};

export const setActiveProductTile = (index) => {
  return {
    type: SET_PRODUCT_TILE_INDEX,
    index
  };
};

export const fetchCartItems = () => async (dispatch, getState) => {
  const { userCart } = getState();

  dispatch({ type: IS_FETCHING_CART_LISTINGS });

  const skus = Object.keys(pruneCart(userCart)) || [];
  const cartSlugs = skus.map(async (item) => {
    const cartItem = userCart[item];
    const { slug, productId } = cartItem;
    await dispatch(fetchCartProduct(slug, productId));
  });

  await Promise.all(cartSlugs);
  dispatch({ type: FINISH_FETCHING_CART_LISTINGS });
};
