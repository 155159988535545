import React, { useEffect, Fragment } from 'react';
import { manageZendeskVisibility } from 'utils/zendeskUtils';
import CategoryBanner from 'components/CategoryBanner';
// import HeroBanner from 'components/HeroBanner';
import ProductFeed from 'components/ProductFeed';
import { connect } from 'react-redux';
import get from 'lodash/get';
import propTypes from 'prop-types';

import HomeWrapper from './HomeWrapper';

const Home = () => {
  // const { location } = props;
  useEffect(() => {
    manageZendeskVisibility(true);
  }, []);
  return (
    <Fragment>
      {/* <HeroBanner location={ location } /> */}
      <div
        style={ {
          width: '100%',
          aspectRatio: '16/6',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center'
        } }
      >
        <video autoPlay={ true } loop={ true } muted={ true } id="background" width="100%">
          <source src="/assets/8681b8974b66fad0cb81a35dd7c8fa4e.webm" type="video/webm" />
        </video>
      </div>
      <CategoryBanner />
      <div
        style={ {
          textAlign: 'center'
        } }
      >
        <img width="480" src="/assets/animated-logo-v2.gif" alt="banner" />
      </div>
      <ProductFeed title="Featured Products" />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  styles: get(state, 'themeData.styles.heroBanner'),
  meta: get(state, 'themeData.meta.seo')
});

const { shape } = propTypes;
Home.propTypes = {
  location: shape({}).isRequired
};

export default HomeWrapper(connect(mapStateToProps)(Home));
