import { useSelector } from 'react-redux';
import { Loader } from '@springforcreators/propel-ui';
import { PayPalButtons, FUNDING } from '@paypal/react-paypal-js';
import propTypes from 'prop-types';
import './PaypalOption.scss';

const PaypalOption = ({
  onApprove,
  onCreate,
  onError,
  onClick,
  addressHasBeenEntered
}) => {
  const { checkout } = useSelector(state => state);
  const { updatingCheckout, fetchingCheckout } = checkout;
  const isDisabled = !addressHasBeenEntered || updatingCheckout;

  return (
    <div className={ `paypaloption mt2 ${isDisabled ? 'disabled-opacity' : ''}${fetchingCheckout ? 'is-fetching' : ''}` }>
      { fetchingCheckout && <div className="paypaloption__loader"><Loader /></div> }
      <PayPalButtons
        fundingSource={ FUNDING.PAYPAL }
        onClick={ onClick }
        createOrder={ onCreate }
        onApprove={ onApprove }
        onError={ onError }
        disabled={ isDisabled }
        className="mt2"
        forceReRender={ [checkout?.customer, checkout?.costs] }
      />
    </div>
  );
};

const { func, bool } = propTypes;
PaypalOption.propTypes = {
  onApprove: func.isRequired,
  onCreate: func.isRequired,
  onError: func.isRequired,
  onClick: func.isRequired,
  addressHasBeenEntered: bool.isRequired
};

export default PaypalOption;
