/* eslint-disable indent, implicit-arrow-linebreak */
import get from 'lodash/get';
import Decimal from 'decimal.js';

const decimalPlaces = num => (num ? (num).toString().length : 1);

export const convertToDollars = (amountInCents) => {
  return +new Decimal(amountInCents / 100).toPrecision(decimalPlaces(amountInCents));
};

export const convertToCents = amountInDollars =>
  Math.trunc(
    +new Decimal(amountInDollars * 100).toPrecision(
      decimalPlaces(amountInDollars)
    )
  );

export const formatMoneyObject = (
  key,
  costObject,
  { amountKey = true, shouldConvertToDollar = false }
) => {
  const nestedKey = get(costObject ?? {}, key);
  if (key === 'discount') {
    const percentageDiscount = nestedKey?.percentage ? { percentage: nestedKey?.percentage } : {};
    return {
      ...percentageDiscount,
      type: nestedKey?.type,
      value: shouldConvertToDollar
        ? convertToDollars(nestedKey?.value?.amount)
        : nestedKey?.value?.amount,
      currency: nestedKey?.value?.currency
    };
  } else {
    const price = amountKey
      ? {
          value: shouldConvertToDollar
            ? convertToDollars(nestedKey?.amount)
            : nestedKey?.amount
        }
      : {
          value: shouldConvertToDollar
            ? convertToDollars(nestedKey?.value)
            : nestedKey?.value
        };
    return {
      ...price,
      currency: nestedKey?.currency
    };
  }
};
