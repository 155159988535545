/* eslint-disable react/prop-types, import/no-dynamic-require */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { GlobalNotification } from 'components/GlobalNotification';
import GlobalStyle from 'components/GlobalStyle';
import BpConnect from 'containers/BpConnect';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import SEO from 'components/SEO';
import Loader from 'components/Loader';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { withCookies } from 'react-cookie';
import queryString from 'query-string';
import { setCookie } from 'utils/cookiesUtils';
import { getThemeConfig, getPreviewThemeData } from 'themes';

import * as actions from 'redux/actions';
import { allProductsArraySelector, allProductsSelector } from 'redux/selectors';
import { addFaviconLinkToHeader, addStyleLinkToHeader, injectCustomCSS } from 'utils/themeUtils';
import { bpProps } from 'utils/responsiveUtils';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import 'scrolling-element';
import router from 'routes';
import tracker, { tiktok } from 'utils/tracking';
import sanitizeHtml from 'sanitize-html';
import { initializeGTM, pushToDataLayer } from 'utils/tracking/gtm';
import { trackAffiliateIn } from 'utils/tracking/affiliate';
import Toaster from 'components/Toaster';
import { BreakpointProvider } from '@springforcreators/propel-ui';
import ErrorBoundary from './ErrorBoundary';
import { GlobalPropsProvider } from './GlobalPropsContext';
import store from '../store';
import { LAUNCHDARKLY_ID } from '../constants';

const storeSlug = store.slug;

const injectFontsToHtmlHead = (fonts) => {
  (fonts ?? []).forEach(path => addStyleLinkToHeader(path));
};

// Adds 'is-touch-device' class to html as soon as the first touchstart
// event is fired. At this point,  we can be reasonably assured the user
// is using touch to interact with the site.
const initTouch = () => {
  const htmlClasses = document.querySelector('html').classList;
  if (!htmlClasses.contains('is-touch-device')) {
    htmlClasses.add('is-touch-device');
  }
};

// Exporting the un-connectecd version for testing
export class App extends Component {
  constructor(props) {
    super(props);
    this.defaultStoreUrl = storeSlug;
    this.initialLoadComplete = false;

    this.state = {
      initialLoadComplete: false,
      initializedTrackers: false
    };
    this.container = React.createRef();
  }

  async componentDidMount() {
    const {
      setThemeData,
      loadPersistentCart,
      getBuyerLocalization,
      fetchAppleVerification
    } = this.props;

    loadPersistentCart(this.defaultStoreUrl);
    getBuyerLocalization();
    fetchAppleVerification();
    tracker.init();

    const queryParams = queryString.parse(window.location.search);

    const { iterableCampaignId, iterableTemplateId } = queryParams;

    if (iterableCampaignId) setCookie('iterableCampaignId', iterableCampaignId);
    if (iterableTemplateId) setCookie('iterableTemplateId', iterableTemplateId);

    const accessCode = queryParams.accessCode || '';

    const initThemeData = await getThemeConfig(storeSlug, accessCode);

    const { mergedData } = getPreviewThemeData(window.location, initThemeData);
    setThemeData(mergedData, true);

    injectFontsToHtmlHead(get(mergedData, 'styles.fonts'));
    addFaviconLinkToHeader(get(mergedData, 'content.favicon'));
    injectCustomCSS(get(mergedData, 'styles.customCSS'));

    this.setAffiliateTracking();

    window.addEventListener('touchstart', initTouch);
  }

  componentDidUpdate(prevProps) {
    const {
      isFetching,
      allProductsArray,
      localizationData,
      fetchStoreData,
      storeData,
      themeData
    } = this.props;

    const { initialLoadComplete, initializedTrackers } = this.state;

    if (localizationData !== prevProps.localizationData) {
      fetchStoreData(this.defaultStoreUrl, localizationData);
    }

    if (!isEmpty(storeData) && !isEmpty(themeData) && !initializedTrackers) {
      this.initializeTrackers();
    }

    if (allProductsArray.length > 0
      && isFetching === false
      && initialLoadComplete === false) {
      this.setState(
        {
          initialLoadComplete: true
        },
        function setStateCallback() {
          this.lazyLoadCollections();
        }
      );
    }
  }

  // Clean up to prevent memory leaks
  componentWillUnmount() {
    tiktok.removeTiktokPixel();
    window.removeEventListener('touchstart', initTouch);
  }

  setAffiliateTracking() {
    const { cookies } = this.props;
    const affiliateValue = queryString.parse(window.location.search).aid || '';
    if (typeof affiliateValue === 'object') {
      trackAffiliateIn(cookies, affiliateValue[0]);
    } else {
      trackAffiliateIn(cookies, affiliateValue);
    }
  }

  initializeTrackers() {
    this.identifyUserToTrack();
    this.initializeGoogleAnalytics();
    this.initializeFacebookPixel();
    this.initializeTiktokPixel();
    this.initGTM();
    this.setState({ initializedTrackers: true });
  }

  identifyUserToTrack() {
    const { storeData } = this.props;
    const userProperties = {
      sellerId: storeData.sellerId,
      whitelabelStoreId: storeData.storeId,
      whitelabelUrl: storeSlug,
      search: document.location.search || '',
      domainHost: document.location.hostname,
      userAgent: navigator?.userAgent
    };
    tracker.identifyUser(userProperties);
  }

  initGTM() {
    const { storeData } = this.props;
    pushToDataLayer({
      seller_id: storeData.sellerId
    });
    initializeGTM();
  }

  initializeGoogleAnalytics() {
    const {
      themeData,
      storeData
    } = this.props;
    const { marketingPixels } = storeData;

    if (process.env.REACT_APP_GA_ACCESS_TOKEN || get(themeData, 'meta.gaToken') || marketingPixels?.googleAnalytics) {
      const testMode = process.env.REACT_APP_ENV !== 'production';
      const gaOptions = { testMode };
      const gaAccounts = [];

      if (process.env.REACT_APP_GA_ACCESS_TOKEN) {
        gaAccounts.push({
          trackingId: process.env.REACT_APP_GA_ACCESS_TOKEN,
          gaOptions: { ...gaOptions, name: 'default' }
        });
      }

      if (get(themeData, 'meta.gaToken')) {
        gaAccounts.push({
          trackingId: themeData.meta.gaToken,
          gaOptions: { ...gaOptions, name: 'client' }
        });
      }

      if (marketingPixels?.googleAnalytics) {
        gaAccounts.push({
          trackingId: marketingPixels.googleAnalytics,
          gaOptions: { ...gaOptions, name: 'client' }
        });
      }

      ReactGA.initialize(gaAccounts);
      ReactGA.pageview(window.location.pathname, ['default', 'client']);
    } else if (process.env.REACT_APP_ENV !== 'production') {
      ReactGA.initialize('UA-0000000-0', { testMode: true });
    }
  }

  initializeFacebookPixel() {
    const {
      storeData
    } = this.props;
    const { marketingPixels } = storeData;
    const pixels = [];

    if (process.env.REACT_APP_TS_FACEBOOK_PIXEL) {
      pixels.push(process.env.REACT_APP_TS_FACEBOOK_PIXEL);
    } else if (marketingPixels.facebook) {
      pixels.push(marketingPixels.facebook);
    }

    if (pixels.length) {
      pixels.forEach(pixel => ReactPixel.init(pixel));
      ReactPixel.pageView();
    }
  }

  initializeTiktokPixel() {
    const { cookies } = this.props;
    const partnerCookie = cookies.get('_teespring_partner_attribution');
    if (partnerCookie && partnerCookie.partner === 'tiktok') {
      tiktok.initializeTiktokPixelHelper();
    }
  }

  async lazyLoadCollections() {
    const {
      storeData, storeId, fetchCollection, localizationData
    } = this.props;

    const { collections } = storeData;

    // eslint-disable-next-line no-restricted-syntax
    for (const collection of collections) {
      // If the collection has NOT already been loaded
      if (!collections[collection.slug]) {
        // eslint-disable-next-line no-await-in-loop
        await fetchCollection(storeId, collection.slug, true, localizationData);
      }
    }
  }

  render() {
    const passThroughProps = this.props;
    const {
      storeData, themeData, localizationData, getStyles
    } = this.props;
    const { content } = themeData;

    if (isEmpty(storeData) || isEmpty(themeData) || isEmpty(localizationData) || !storeData.collections) {
      return <Loader />;
    }

    const collectionsData = storeData ? storeData.collections : undefined;
    return (
      <React.Fragment>
        <SEO title={ storeData.name } />
        <ErrorBoundary>
          <GlobalStyle { ...themeData.styles } />
          <div ref={ this.container } className="App">
            <GlobalNotification />
            { get(content, 'notification') && (
              <div
                className="header-notification"
                style={ {
                  ...getStyles('notification.bgStyles'),
                  ...getStyles('notification.textStyles')
                } }
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={ { __html: sanitizeHtml(content.notification) } }
              />
            ) }
            <BpConnect />
            <GlobalPropsProvider value={ this.props }>
              <BreakpointProvider>
                <Toaster>
                  { router(passThroughProps, themeData, collectionsData) }
                </Toaster>
              </BreakpointProvider>
            </GlobalPropsProvider>
          </div>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

const {
  string,
  arrayOf,
  array,
  object,
  bool,
  func,
  number,
  shape,
  oneOfType
} = propTypes;

App.propTypes = {
  fetchStoreData: func.isRequired,
  loadPersistentCart: func.isRequired,
  getBuyerLocalization: func.isRequired,
  allProductsArray: arrayOf(object).isRequired,
  fetchCollection: func.isRequired,
  fetchAppleVerification: func.isRequired,
  isFetching: bool.isRequired,
  storeProducts: shape({
    products: array
  }).isRequired,
  storeId: string,
  storeData: shape({
    banner_url: string,
    collections: array,
    description: string,
    link_color: string,
    logo_height: number,
    logo_url: string,
    logo_width: number,
    name: string,
    social_identities: object,
    theme_color: string,
    url: string,
    use_logo: bool,
    storeId: oneOfType([string, number])
  }).isRequired,
  setThemeData: func.isRequired,
  themeData: shape({
    theme: shape({}),
    copy: shape({})
  }).isRequired
};

App.defaultProps = {
  storeId: storeSlug
};

const mapStateToProps = (state) => {
  const {
    isFetching,
    productCacheLoaded,
    stores,
    storeCollections,
    storeProducts,
    themeData,
    initThemeData,
    localizationData
  } = state;

  return {
    storeId: stores.slug,
    storeData: stores,
    storeProducts,
    allProducts: allProductsSelector(state),
    allProductsArray: allProductsArraySelector(state),
    storeCollections,
    isFetching,
    themeData,
    initThemeData,
    localizationData,
    productCacheLoaded,
    ...bpProps(state)
  };
};

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_ID,
  context: {
    kind: 'user',
    key: 1,
    email: 'stores@amaze.co',
    city: 'Springfield',
    device: 'browser',
    'operating system': 'Store'
  },
  options: {
    bootstrap: 'localStorage'
  }
})(connect(mapStateToProps, {
  ...actions
})(withCookies(App)));
