const countries = [
  {
    id: 'af',
    label: 'Afghanistan',
    name: 'AF',
    code: 'AFG'
  },
  {
    id: 'al',
    label: 'Albania',
    name: 'AL',
    code: 'ALB'
  },
  {
    id: 'ad',
    label: 'Andorra',
    name: 'AD',
    code: 'AND'
  },
  {
    id: 'ai',
    label: 'Anguilla',
    name: 'AI',
    code: 'AIA'
  },
  {
    id: 'ag',
    label: 'Antigua and Barbuda',
    name: 'AG',
    code: 'ATG'
  },
  {
    id: 'ar',
    label: 'Argentina',
    name: 'AR',
    code: 'ARG'
  },
  {
    id: 'am',
    label: 'Armenia',
    name: 'AM',
    code: 'ARM'
  },
  {
    id: 'aw',
    label: 'Aruba',
    name: 'AW',
    code: 'ABW'
  },
  {
    id: 'au',
    label: 'Australia',
    name: 'AU',
    code: 'AUS'
  },
  {
    id: 'at',
    label: 'Austria',
    name: 'AT',
    code: 'AUT'
  },
  {
    id: 'az',
    label: 'Azerbaijan',
    name: 'AZ',
    code: 'AZE'
  },
  {
    id: 'bs',
    label: 'Bahamas',
    name: 'BS',
    code: 'BHS'
  },
  {
    id: 'bh',
    label: 'Bahrain',
    name: 'BH',
    code: 'BHR'
  },
  {
    id: 'bd',
    label: 'Bangladesh',
    name: 'BD',
    code: 'BGD'
  },
  {
    id: 'bb',
    label: 'Barbados',
    name: 'BB',
    code: 'BRB'
  },
  {
    id: 'by',
    label: 'Belarus',
    name: 'BY',
    code: 'BLR'
  },
  {
    id: 'be',
    label: 'Belgium',
    name: 'BE',
    code: 'BEL'
  },
  {
    id: 'bz',
    label: 'Belize',
    name: 'BZ',
    code: 'BLZ'
  },
  {
    id: 'bm',
    label: 'Bermuda',
    name: 'BM',
    code: 'BMU'
  },
  {
    id: 'bt',
    label: 'Bhutan',
    name: 'BT',
    code: 'BTN'
  },
  {
    id: 'bo',
    label: 'Bolivia',
    name: 'BO',
    code: 'BOL'
  },
  {
    id: 'ba',
    label: 'Bosnia',
    name: 'BA',
    code: 'BIH'
  },
  {
    id: 'br',
    label: 'Brazil',
    name: 'BR',
    code: 'BRA'
  },
  {
    id: 'vg',
    label: 'British Virgin Islands',
    name: 'VG',
    code: 'VGB'
  },
  {
    id: 'bg',
    label: 'Bulgaria',
    name: 'BG',
    code: 'BGR'
  },
  {
    id: 'kh',
    label: 'Cambodia',
    name: 'KH',
    code: 'KHM'
  },
  {
    id: 'cm',
    label: 'Cameroon',
    name: 'CM',
    code: 'CMR'
  },
  {
    id: 'ca',
    label: 'Canada',
    name: 'CA',
    code: 'CAN'
  },
  {
    id: 'ic',
    label: 'Canary Islands',
    name: 'IC',
    code: ''
  },
  {
    id: 'bq',
    label: 'Caribbean Netherlands',
    name: 'BQ'
  },
  {
    id: 'ky',
    label: 'Cayman Islands',
    name: 'KY',
    code: 'CYM'
  },
  {
    id: 'ea',
    label: 'Ceuta',
    name: 'EA',
    code: 'EA'
  },
  {
    id: 'cl',
    label: 'Chile',
    name: 'CL',
    code: 'CHL'
  },
  {
    id: 'cn',
    label: 'China',
    name: 'CN',
    code: 'CHN'
  },
  {
    id: 'cx',
    label: 'Christmas Islands',
    name: 'CX',
    code: 'CXR'
  },
  {
    id: 'cc',
    label: 'Cocos Islands',
    name: 'CC',
    code: 'CCK'
  },
  {
    id: 'co',
    label: 'Colombia',
    name: 'CO',
    code: 'COL'
  },
  {
    id: 'ck',
    label: 'Cook Islands',
    name: 'CK',
    code: 'COK'
  },
  {
    id: 'cs',
    label: 'Coral Sea',
    name: 'Coral Sea',
    code: 'AUS'
  },
  {
    id: 'cr',
    label: 'Costa Rica',
    name: 'CR',
    code: 'CRI'
  },
  {
    id: 'bhr',
    label: 'Croatia',
    name: 'HR',
    code: 'HRV'
  },
  {
    id: 'cw',
    label: 'Curacao',
    name: 'CW',
    code: 'CUW'
  },
  {
    id: 'cy',
    label: 'Cyprus',
    name: 'CY',
    code: 'CYP'
  },
  {
    id: 'cz',
    label: 'Czech Republic',
    name: 'CZ',
    code: 'CZE'
  },
  {
    id: 'dk',
    label: 'Denmark',
    name: 'DK',
    code: 'DNK'
  },
  {
    id: 'dm',
    label: 'Dominica',
    name: 'DM',
    code: 'DMA'
  },
  {
    id: 'do',
    label: 'Dominican Republic',
    name: 'DO',
    code: 'DOM'
  },
  {
    id: 'ec',
    label: 'Ecuador',
    name: 'EC',
    code: 'ECU'
  },
  {
    id: 'sv',
    label: 'El Salvador',
    name: 'SV',
    code: 'SLV'
  },
  {
    id: 'ee',
    label: 'Estonia',
    name: 'EE',
    code: 'EST'
  },
  {
    id: 'fk',
    label: 'Falkland Islands',
    name: 'FK',
    code: 'FLK'
  },
  {
    id: 'fo',
    label: 'Faroe Islands',
    name: 'FO',
    code: 'FRO'
  },
  {
    id: 'fj',
    label: 'Fiji',
    name: 'FJ',
    code: 'FJI'
  },
  {
    id: 'fi',
    label: 'Finland',
    name: 'FI',
    code: 'FIN'
  },
  {
    id: 'fr',
    label: 'France',
    name: 'FR',
    code: 'FRA'
  },
  {
    id: 'gf',
    label: 'French Guiana',
    name: 'GF',
    code: 'GUF'
  },
  {
    id: 'pf',
    label: 'French Polynesia',
    name: 'PF',
    code: 'PYF'
  },
  {
    id: 'ge',
    label: 'Georgia',
    name: 'GE',
    code: 'GEO'
  },
  {
    id: 'de',
    label: 'Germany',
    name: 'DE',
    code: 'DEU'
  },
  {
    id: 'gi',
    label: 'Gibraltar',
    name: 'GI',
    code: 'GIB'
  },
  {
    id: 'gr',
    label: 'Greece',
    name: 'GR',
    code: 'GRC'
  },
  {
    id: 'gl',
    label: 'Greenland',
    name: 'GL',
    code: 'GRL'
  },
  {
    id: 'gd',
    label: 'Grenada',
    name: 'GD',
    code: 'GRD'
  },
  {
    id: 'gp',
    label: 'Guadeloupe',
    name: 'GP',
    code: 'GLP'
  },
  {
    id: 'gt',
    label: 'Guatemala',
    name: 'GT',
    code: 'GTM'
  },
  {
    id: 'gy',
    label: 'Guyana',
    name: 'GY',
    code: 'GUY'
  },
  {
    id: 'ht',
    label: 'Haiti',
    name: 'HT',
    code: 'HTI'
  },
  {
    id: 'hn',
    label: 'Honduras',
    name: 'HN',
    code: 'HND'
  },
  {
    id: 'hk',
    label: 'Hong Kong',
    name: 'HK',
    code: 'HKG'
  },
  {
    id: 'hu',
    label: 'Hungary',
    name: 'HU',
    code: 'HUN'
  },
  {
    id: 'is',
    label: 'Iceland',
    name: 'IS',
    code: 'ISL'
  },
  {
    id: 'in',
    label: 'India',
    name: 'IN',
    code: 'IND'
  },
  {
    id: 'id',
    label: 'Indonesia',
    name: 'ID',
    code: 'IDN'
  },
  {
    id: 'ie',
    label: 'Ireland',
    name: 'IE',
    code: 'IRL'
  },
  {
    id: 'il',
    label: 'Israel',
    name: 'IL',
    code: 'ISR'
  },
  {
    id: 'it',
    label: 'Italy',
    name: 'IT',
    code: 'ITA'
  },
  {
    id: 'jm',
    label: 'Jamaica',
    name: 'JM',
    code: 'JAM'
  },
  {
    id: 'jp',
    label: 'Japan',
    name: 'JP',
    code: 'JPN'
  },
  {
    id: 'jo',
    label: 'Jordan',
    name: 'JO',
    code: 'JOR'
  },
  {
    id: 'kz',
    label: 'Kazakhstan',
    name: 'KZ',
    code: 'KAZ'
  },
  {
    id: 'ki',
    label: 'Kiribati',
    name: 'KI',
    code: 'KIR'
  },
  {
    id: 'kw',
    label: 'Kuwait',
    name: 'KW',
    code: 'KWT'
  },
  {
    id: 'kg',
    label: 'Kyrgyzstan',
    name: 'KG',
    code: 'KGZ'
  },
  {
    id: 'lv',
    label: 'Latvia',
    name: 'LV',
    code: 'LVA'
  },
  {
    id: 'lb',
    label: 'Lebanon',
    name: 'LB',
    code: 'LBN'
  },
  {
    id: 'li',
    label: 'Liechtenstein',
    name: 'LI',
    code: 'LIE'
  },
  {
    id: 'lt',
    label: 'Lithuania',
    name: 'LT',
    code: 'LTU'
  },
  {
    id: 'lu',
    label: 'Luxembourg',
    name: 'LU',
    code: 'LUX'
  },
  {
    id: 'mo',
    label: 'Macao',
    name: 'MO',
    code: 'MAC'
  },
  {
    id: 'mk',
    label: 'Macedonia',
    name: 'MK',
    code: 'MKD'
  },
  {
    id: 'my',
    label: 'Malaysia',
    name: 'MY',
    code: 'MYS'
  },
  {
    id: 'mv',
    label: 'Maldives',
    name: 'MV',
    code: 'MDV'
  },
  {
    id: 'mt',
    label: 'Malta',
    name: 'MT',
    code: 'MLT'
  },
  {
    id: 'mq',
    label: 'Martinique',
    name: 'MQ',
    code: 'MTQ'
  },
  {
    id: 'mx',
    label: 'Mexico',
    name: 'MX',
    code: 'MEX'
  },
  {
    id: 'fm',
    label: 'Micronesia',
    name: 'FM',
    code: 'FSM'
  },
  {
    id: 'md',
    label: 'Moldova',
    name: 'MD',
    code: 'MDA'
  },
  {
    id: 'mc',
    label: 'Monaco',
    name: 'MC',
    code: 'MCO'
  },
  {
    id: 'mn',
    label: 'Mongolia',
    name: 'MN',
    code: 'MNG'
  },
  {
    id: 'me',
    label: 'Montenegro',
    name: 'ME',
    code: 'MNE'
  },
  {
    id: 'ms',
    label: 'Montserrat',
    name: 'MS',
    code: 'MSR'
  },
  {
    id: 'nr',
    label: 'Nauru',
    name: 'NR',
    code: 'NRU'
  },
  {
    id: 'np',
    label: 'Nepal',
    name: 'NP',
    code: 'NPL'
  },
  {
    id: 'nl',
    label: 'Netherlands',
    name: 'NL',
    code: 'NLD'
  },
  {
    id: 'nc',
    label: 'New Caledonia',
    name: 'NC',
    code: 'NCL'
  },
  {
    id: 'nz',
    label: 'New Zealand',
    name: 'NZ',
    code: 'NZL'
  },
  {
    id: 'ni',
    label: 'Nicaragua',
    name: 'NI',
    code: 'NIC'
  },
  {
    id: 'no',
    label: 'Norway',
    name: 'NO',
    code: 'NOR'
  },
  {
    id: 'om',
    label: 'Oman',
    name: 'OM',
    code: 'OMN'
  },
  {
    id: 'pk',
    label: 'Pakistan',
    name: 'PK',
    code: 'PAK'
  },
  {
    id: 'pa',
    label: 'Panama',
    name: 'PA',
    code: 'PAN'
  },
  {
    id: 'pg',
    label: 'Papua New Guinea',
    name: 'PG',
    code: 'PNG'
  },
  {
    id: 'py',
    label: 'Paraguay',
    name: 'PY',
    code: 'PRY'
  },
  {
    id: 'pe',
    label: 'Peru',
    name: 'PE',
    code: 'PER'
  },
  {
    id: 'ph',
    label: 'Philippines',
    name: 'PH',
    code: 'PHL'
  },
  {
    id: 'pn',
    label: 'Pitcairn Islands',
    name: 'PN',
    code: 'PCN'
  },
  {
    id: 'pl',
    label: 'Poland',
    name: 'PL',
    code: 'POL'
  },
  {
    id: 'pt',
    label: 'Portugal',
    name: 'PT',
    code: 'PRT'
  },
  {
    id: 'qa',
    label: 'Qatar',
    name: 'QA',
    code: 'QAT'
  },
  {
    id: 'ro',
    label: 'Romania',
    name: 'RO',
    code: 'ROU'
  },
  {
    id: 'ru',
    label: 'Russia',
    name: 'RU',
    code: 'RUS'
  },
  {
    id: 'ws',
    label: 'Samoa',
    name: 'WS',
    code: 'WSM'
  },
  {
    id: 'sa',
    label: 'Saudi Arabia',
    name: 'SA',
    code: 'SAU'
  },
  {
    id: 'rs',
    label: 'Serbia',
    name: 'RS',
    code: 'SRB'
  },
  {
    id: 'sg',
    label: 'Singapore',
    name: 'SG',
    code: 'SGP'
  },
  {
    id: 'sx',
    label: 'Sint Maarten',
    name: 'SX',
    code: 'SXM'
  },
  {
    id: 'sk',
    label: 'Slovakia',
    name: 'SK',
    code: 'SVK'
  },
  {
    id: 'si',
    label: 'Slovenia',
    name: 'SI',
    code: 'SVN'
  },
  {
    id: 'sb',
    label: 'Solomon Islands',
    name: 'SB',
    code: 'SLB'
  },
  {
    id: 'za',
    label: 'South Africa',
    name: 'ZA',
    code: 'ZAF'
  },
  {
    id: 'kr',
    label: 'South Korea',
    name: 'KR',
    code: 'KOR'
  },
  {
    id: 'es',
    label: 'Spain',
    name: 'ES',
    code: 'ESP'
  },
  {
    id: 'lk',
    label: 'Sri Lanka',
    name: 'LK',
    code: 'LKA'
  },
  {
    id: 'stc',
    label: 'St. Christopher',
    name: 'St. Christopher',
    code: 'KNA'
  },
  {
    id: 'ste',
    label: 'St. Eustatius',
    name: 'St. Eustatius',
    code: 'BES'
  },
  {
    id: 'stg',
    label: 'St. Georgia',
    name: 'St. Georgia',
    code: 'GEO'
  },
  {
    id: 'sth',
    label: 'St. Helena',
    name: 'St. Helena',
    code: 'SHN'
  },
  {
    id: 'kn',
    label: 'St. Kitts & Nevis',
    name: 'KN',
    code: 'KNA'
  },
  {
    id: 'lc',
    label: 'Saint Lucia',
    name: 'LC',
    code: 'LCA'
  },
  {
    id: 'pm',
    label: 'Saint Pierre and Miquelon',
    name: 'PM',
    code: 'SPM'
  },
  {
    id: 'vc',
    label: 'St. Vincent',
    name: 'VC',
    code: 'VCT'
  },
  {
    id: 'sr',
    label: 'Suriname',
    name: 'SR',
    code: 'SUR'
  },
  {
    id: 'se',
    label: 'Sweden',
    name: 'SE',
    code: 'SWE'
  },
  {
    id: 'ch',
    label: 'Switzerland',
    name: 'CH',
    code: 'CHE'
  },
  {
    id: 'tw',
    label: 'Taiwan',
    name: 'TW',
    code: 'TWN'
  },
  {
    id: 'tj',
    label: 'Tajikistan',
    name: 'TJ',
    code: 'TJK'
  },
  {
    id: 'th',
    label: 'Thailand',
    name: 'TH',
    code: 'THA'
  },
  {
    id: 'to',
    label: 'Tonga',
    name: 'TO',
    code: 'TON'
  },
  {
    id: 'tt',
    label: 'Trinidad',
    name: 'TT',
    code: 'TTO'
  },
  {
    id: 'tac',
    label: 'Tristan da Cunha',
    name: 'Tristan da Cunha',
    code: 'SHN'
  },
  {
    id: 'tn',
    label: 'Tunisia',
    name: 'TN',
    code: 'TUN'
  },
  {
    id: 'tr',
    label: 'Turkey',
    name: 'TR',
    code: 'TUR'
  },
  {
    id: 'tc',
    label: 'Turks and Caicos Islands',
    name: 'TC',
    code: 'TCA'
  },
  {
    id: 'tv',
    label: 'Tuvalu',
    name: 'TV',
    code: 'TUV'
  },
  {
    id: 'ua',
    label: 'Ukraine',
    name: 'UA',
    code: 'UKR'
  },
  {
    id: 'ae',
    label: 'United Arab Emirates',
    name: 'AE',
    code: 'ARE'
  },
  {
    id: 'gb',
    label: 'United Kingdom',
    name: 'GB',
    code: 'GBR'
  },
  {
    id: 'us',
    label: 'United States',
    name: 'US',
    code: 'USA'
  },
  {
    id: 'uy',
    label: 'Uruguay',
    name: 'UY',
    code: 'URY'
  },
  {
    id: 'uz',
    label: 'Uzbekistan',
    name: 'UZ',
    code: 'UZB'
  },
  {
    id: 'vu',
    label: 'Vanuatu',
    name: 'VU',
    code: 'VUT'
  },
  {
    id: 've',
    label: 'Venezuela',
    name: 'VE',
    code: 'VEN'
  },
  {
    id: 'vn',
    label: 'Vietnam',
    name: 'VN',
    code: 'VNM'
  }
];

export default countries;
