/* eslint no-shadow: 0 */

import {
  RECEIVE_PRODUCTS,
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCT_CACHE,
  RECEIVE_STORE,
  REQUEST_STORE,
  RECEIVE_COLLECTION,
  RECEIVE_COLLECTIONS,
  REQUEST_COLLECTION,
  RECEIVE_LISTING,
  RECEIVE_PRODUCT_BLANKS,
  RECEIVE_LISTING_PRODUCTS,
  REQUEST_LISTING,
  IS_FETCHING_DATA,
  UPDATE_CART,
  DESTROY_CART_ITEM,
  LOAD_FROM_PERSISTENT_CART,
  DESTROY_CART,
  FETCH_LISTINGS,
  RECEIVE_CART_PRODUCT,
  FETCH_LISTING_PRODUCTS,
  FETCH_PRODUCT_BLANK,
  DISABLE_PRODUCT_CACHE,
  IS_FETCHING_CART_LISTINGS,
  FINISH_FETCHING_CART_LISTINGS,
  IS_FETCHING_FOR_UNAVAILABILITY,
  UPDATE_FULFILLMENT_DETAILS,
  SET_PRODUCT_TILE_INDEX,
  REMOVE_LISTING
} from 'redux/actions';

import { getPersistantCart, setPersistantCart } from 'utils/cartUtils';
import { getLocalizationDetails } from 'utils/localizationUtils';
import find from 'lodash/find';
import get from 'lodash/get';
import { USE_COLLECTIONS } from '../../constants';

/**
 * Sets the is fetching variable to indicate loading
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export const isFetching = (state = false, action) => {
  switch (action.type) {
    case IS_FETCHING_DATA:
      return action.isFetchingData;
    default:
      return state;
  }
};

/**
 * Get Store data from teespring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export const stores = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_STORE:
      // Do nothing for now
      // isFetching action handles status
      return state;
    case RECEIVE_STORE:
      return Object.assign({}, state, {
        ...action.storeData,
        slug: action.storeData.slug.toLowerCase()
      });
    case RECEIVE_COLLECTIONS: {
      const { collections } = action;
      return {
        ...state,
        collections
      };
    }
    default:
      return state;
  }
};

export const fulfillmentDetails = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FULFILLMENT_DETAILS: {
      return {
        ...state,
        [action.sku]: action.data
      };
    }
    default:
      return state;
  }
};

/**
 * Get Product data from teespring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export const storeProducts = (
  state = {
    products: []
  },
  action
) => {
  const { storeProducts } = action;
  switch (action.type) {
    case REQUEST_PRODUCTS:
      // Do nothing for now
      // isFetching action handles status
      return state;
    case RECEIVE_PRODUCTS:
      if (USE_COLLECTIONS) {
        return {
          ...state,
          products: [...state.products, ...storeProducts]
        };
      } else {
        return {
          ...state,
          ...storeProducts,
          products: [...state.products, ...storeProducts.products]
        };
      }
    default:
      return state;
  }
};

/**
 * Get Product data from cached file on S3
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export const productCacheLoaded = (state = false, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCT_CACHE:
      return true;
    default:
      return state;
  }
};

/**
 * Get Collection data from teespring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export const storeCollections = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_COLLECTION:
      // Do nothing for now
      // isFetching action handles status
      return state;
    case RECEIVE_COLLECTION: {
      const { storeCollections, collectionID } = action;
      return {
        ...state,
        [collectionID]: storeCollections
      };
    }
    default:
      return state;
  }
};

/**
 * Get Product data from teespring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export const storeListings = (
  state = {
    isFetchingListing: false,
    isFetchingListingProducts: false,
    isFetchingCart: false,
    isFetchingForUnavailability: false,
    productDetails: {},
    productCacheEnabled: process.env.REACT_APP_PRODUCT_CACHE_ENABLED === 'true'
  },
  action
) => {
  switch (action.type) {
    case REQUEST_LISTING:
      // Do nothing for now
      // isFetching action handles status
      return state;
    case RECEIVE_LISTING: {
      // Preserve products when updating the listing
      const listingProducts = state[action.listingSlug]?.products || undefined;
      return {
        ...state,
        [action.listingSlug]: {
          ...action.listing,
          ...(listingProducts ? { products: listingProducts } : undefined)
        },
        isFetchingListing: false
      };
    }
    case REMOVE_LISTING: {
      return {
        ...state,
        [action.listingSlug]: {
          primaryProduct: []
        }
      };
    }
    case RECEIVE_PRODUCT_BLANKS: {
      const newState = { ...state };
      const listing = newState[action.listingSlug];
      if (listing) {
        const product = find(
          get(listing, 'products', []),
          prod => prod.productId === action.productId
        );

        if (product) product.details = action.productBlanks;
      }

      return newState;
    }
    case RECEIVE_LISTING_PRODUCTS: {
      return {
        ...state,
        [action.listingSlug]: {
          ...state[action.listingSlug],
          products: action.products_payload
        },
        isFetchingListingProducts: false
      };
    }
    case FETCH_LISTINGS:
      return {
        ...state,
        isFetchingListing: action.isFetching
      };
    case FETCH_LISTING_PRODUCTS:
      return {
        ...state,
        isFetchingListingProducts: action.isFetching
      };
    case FETCH_PRODUCT_BLANK:
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          [action.productId]: action.data
        }
      };
    case DISABLE_PRODUCT_CACHE:
      return {
        ...state,
        productCacheEnabled: false
      };
    case IS_FETCHING_CART_LISTINGS:
      return {
        ...state,
        isFetchingCart: true
      };
    case FINISH_FETCHING_CART_LISTINGS:
      return {
        ...state,
        isFetchingCart: false
      };
    case IS_FETCHING_FOR_UNAVAILABILITY:
      return {
        ...state,
        isFetchingForUnavailability: action.isFetchingForUnavailability
      };
    case SET_PRODUCT_TILE_INDEX:
      return {
        ...state,
        index: action.index
      };
    default:
      return state;
  }
};

/**
 * Add / Remove Products from cart
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export const userCart = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CART: {
      const { storeId, sku, userCart } = action;
      const newState = {
        ...state,
        [sku]: userCart
      };

      const localization = getLocalizationDetails();
      const cart = getPersistantCart(storeId);

      // Set persistant cart
      setPersistantCart(storeId, {
        ...newState,
        region: cart.region && cart.region !== localization.buyer_region ? 'MIXED' : localization.buyer_region
      });

      return newState;
    }

    case DESTROY_CART_ITEM: {
      const { storeId, sku } = action;
      const updateState = Object.assign({}, state);
      delete updateState[sku];

      // Set persistant cart
      const localization = getLocalizationDetails();
      setPersistantCart(storeId, {
        ...updateState,
        region: localization.buyer_region
      });
      return updateState;
    }

    case LOAD_FROM_PERSISTENT_CART: {
      const { storeId } = action;
      let newState = {};
      newState = getPersistantCart(storeId);

      return newState;
    }

    case DESTROY_CART: {
      const { storeId } = action;
      const newState = {};
      setPersistantCart(storeId, {});

      return newState;
    }

    default:
      return state;
  }
};

/**
 * Add / Remove Products from cart
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export const cartProducts = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_CART_PRODUCT: {
      const productMap = new Map();
      const collectProducts = (products) => {
        if (!products) {
          return;
        }
        products.forEach((product) => {
          // Add product to productMap if we haven't seen the variation id yet
          if (product && !productMap.has(product.variationId)) {
            productMap.set(product.variationId, product);
          }
        });
      };

      // Add new product info (first in wins)
      collectProducts(action.product_payload);

      // TODO: is this needed? maybe we have a product in the cart that has been delisted?
      // Maintain prior product info that isn't in new data (already collected variations will be ignored)
      collectProducts(state[action.listingSlug]?.products);

      // TODO: is this needed?
      // Looks like legacy logic brought over from another reducer in
      // https://github.com/teespring/teespring-custom-storefronts-react/commit/16d826d4
      // and does not appear to have meaning in the current context.  Further, and oddly, this would add products
      // in the PREVIOUS state (under primaryProduct) to the top level products array on the next RECEIVE_CART_PRODUCT.
      // Keeping for now, in an abundance of caution, as it's likely benign at worst.
      collectProducts(state[action.listingSlug]?.primaryProduct);

      // Convert the map values into a (new) array
      const products = [...productMap.values()];

      return {
        ...state,
        [action.listingSlug]: {
          ...state[action.listingSlug],
          products
        }
      };
    }

    case IS_FETCHING_CART_LISTINGS:
      return {
        ...state,
        isFetchingCartProducts: true
      };
    case FINISH_FETCHING_CART_LISTINGS:
      return {
        ...state,
        isFetchingCartProducts: false
      };

    default:
      return state;
  }
};
