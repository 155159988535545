/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import { AnimatePresence, motion } from 'framer-motion';
import { node, func } from 'prop-types';
import { bpProps } from 'utils/responsiveUtils';
import { useSelector } from 'react-redux';
import { Fragment, useState } from 'react';
import { Icon } from '@springforcreators/propel-ui';

const ProductOptionsModal = ({
  colorOptions,
  sizeOptions,
  addToCartButton,
  setShowProductOptions
}) => {
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));
  const [stickyMenu, setStickyMenu] = useState(true);
  const addToCartSticky = (
    <>
      <AnimatePresence>
        {stickyMenu && (
          <Fragment key="stickymenu__fragment">
            <motion.button
              type="button"
              className="stickymenu__overlay"
              key="stickymenu__overlay"
              custom="stickymenu__overlay"
              onClick={ () => {
                setStickyMenu(false);
                setShowProductOptions(false);
              } }
              initial={ { opacity: 0 } }
              animate={ { opacity: 1 } }
              exit={ { opacity: 0 } }
              transition={ { duration: 0.2 } }
            />
            <motion.div
              className="stickymenu"
              key="stickymenu"
              custom="stickymenu"
              initial={ { marginBottom: '-100%' } }
              animate={ { marginBottom: '0%' } }
              exit={ { marginBottom: '-100%' } }
              transition={ { duration: 0.3 } }
            >
              <div className="stickymenu__header">
                <div className="productoptions__title">Select options</div>
                <motion.button
                  type="button"
                  className="stickymenu__close_button"
                  key="stickymenu__close_button"
                  custom="stickymenu__close_button"
                  onClick={ () => {
                    setStickyMenu(false);
                    setShowProductOptions(false);
                  } }
                  initial={ { opacity: 0 } }
                  animate={ { opacity: 1 } }
                  exit={ { opacity: 0 } }
                  transition={ { duration: 0.2 } }
                >
                  <Icon name="Close" color="grey" />
                </motion.button>
              </div>
              <div className="divider" />
              <div className="mb2">{colorOptions}</div>
              <div className="mb2">{sizeOptions}</div>
              {addToCartButton}
            </motion.div>
          </Fragment>
        )}
      </AnimatePresence>
    </>
  );
  return (
    <>
      <AnimatePresence initial={ false }>
        {bpIsLT('mobileLg') && (
          <motion.div
            className="quick__addcart--mobile"
            initial={ { marginBottom: -60 } }
            animate={ { marginBottom: 0 } }
            exit={ { marginBottom: -60 } }
            transition={ { duration: 0.25 } }
          >
            {addToCartSticky}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

ProductOptionsModal.propTypes = {
  colorOptions: node.isRequired,
  sizeOptions: node.isRequired,
  addToCartButton: node.isRequired,
  setShowProductOptions: func.isRequired
};

export default ProductOptionsModal;
