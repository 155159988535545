import { Skeleton, Button } from '@springforcreators/propel-ui';
import { useSelector } from 'react-redux';
import { bpProps } from 'utils/responsiveUtils';
import './SkeletonListingPage.scss';

const SkeletonListingPage = () => {
  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));

  const buttons = (
    <div className="listing-details-skeleton-buttons">
      <Button className="listing-details-buy">Buy it now</Button>
      <Button className="listing-details-add">Add to cart</Button>
    </div>
  );
  return (
    <div className="skeleton-listing-page">
      {bpIsGT('mobileLg') ? (
        <>
          <div className="skeleton-thumbnails">
            <Skeleton styles={ { height: '20%', borderRadius: 15 } } />
            <Skeleton styles={ { height: '20%', borderRadius: 15 } } />
          </div>
          <Skeleton styles={ { width: 500, height: 500, borderRadius: 15 } } />
          <div>
            <div>
              <div className="listing-details-skeleton">
                <Skeleton
                  styles={ { height: 10, width: 150, borderRadius: 15 } }
                />
                <Skeleton
                  styles={ { height: 25, width: 300, borderRadius: 15 } }
                />
                <Skeleton
                  styles={ { height: 10, width: 150, borderRadius: 15 } }
                />
              </div>
              <div className="listing-details-skeleton-container">
                <div className="listing-details-skeleton-sizes">
                  <Skeleton styles={ { height: 40, width: 55 } } />
                  <Skeleton styles={ { height: 40, width: 55 } } />
                  <Skeleton styles={ { height: 40, width: 55 } } />
                  <Skeleton styles={ { height: 40, width: 55 } } />
                  <Skeleton styles={ { height: 40, width: 55 } } />
                  <Skeleton styles={ { height: 40, width: 55 } } />
                  <Skeleton styles={ { height: 40, width: 55 } } />
                </div>
              </div>
              {buttons}
            </div>
          </div>
        </>
      ) : (
        <div className="skeleton-mobile">
          <div className="listing-details-skeleton">
            <div className="listing-details-skeleton-info">
              <Skeleton
                styles={ {
                  width: '85%',
                  height: 16,
                  marginBottom: 15,
                  borderRadius: 16
                } }
              />
              <Skeleton styles={ { width: '44%', height: '10%' } } />
            </div>
            <Skeleton
              styles={ {
                width: '75%',
                height: '36%',
                borderRadius: 16,
                marginTop: 10
              } }
            />
          </div>
          <Skeleton
            styles={ { width: '100%', height: '45%', marginBottom: 25 } }
          />
          <div className="listing-details-skeleton-btn-container">
            <Skeleton
              styles={ {
                width: '100%',
                height: 50,
                marginBottom: 10,
                borderRadius: 16
              } }
            />
            {buttons}
            <Skeleton count={ 3 } styles={ { height: '30%', marginTop: 15 } } />
          </div>
        </div>
      )}
    </div>
  );
};

export default SkeletonListingPage;
