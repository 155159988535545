/* eslint no-negated-condition: 0, operator-linebreak: 0 */
import { themedCheckoutURL } from 'utils/themeUtils';
import { getPriceFromSize } from 'utils/productUtils';
import { deleteCookie, getCookie, setCookie } from 'utils/cookiesUtils';
import { getLocalizationDetails } from 'utils/localizationUtils';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import every from 'lodash/every';
import has from 'lodash/has';
import { NFT_PRODUCT_ID } from '../../constants';

/**
 * Returns total quantity for all items in cart
 * @param {Object} userCart - userCart object
 * @returns {Number} Returns total cart count
 */
export const getCartSize = (userCart) => {
  let count = 0;
  const cart = {
    ...userCart
  };

  delete cart.region;

  Object.values(cart).forEach((value) => {
    count += get(value, 'quantity') || 0;
  });

  return count;
};

export const isUnavailableItem = (cartItem, product, listing, inventory) => {
  // Check availability of listing
  if (hasIn(listing, 'errors')) return true;

  const inventoryCount = inventory[`${listing?.products[0]?.listingId}-${cartItem?.colorId}-${cartItem.sizeId}`];

  // Check availability of product
  const isUnavailableProduct = !product || product?.visibility === 'private' || inventoryCount <= 0;
  if (isUnavailableProduct) return true;

  // Check availability of variation
  let availableSizesWithId = product?.availableSizesWithId;

  if (!isEmpty(product?.variations)) {
    availableSizesWithId = product?.variations
      .find(variant => variant.variationId === cartItem.colorId)?.availableSizesWithId;
  }

  const isUnavailableVariation = !find(availableSizesWithId, ({ id }) => id === cartItem.sizeId);
  if (isUnavailableVariation) return true;

  return false;
};

export const mapLineItem = (cartItem, storeListings, inventory) => {
  const { sku } = cartItem;
  const listing = storeListings[cartItem.slug];
  const products = hasIn(listing, 'products')
    ? get(listing, 'products', [])
    : get(listing, 'primaryProduct');
  if (isEmpty(products)) return null;

  const product = find(products, listingProduct => listingProduct.variationId === cartItem.colorId);

  if (isUnavailableItem(cartItem, product, listing, inventory)) {
    return null;
  } else {
    const variations = get(product, 'variations', []);
    const variant = !isEmpty(variations)
      ? find(get(product, 'variations', []), variation => variation.variationId === cartItem.colorId)
      : find(products, variation => variation.variationId === cartItem.colorId);

    if (!variant) return null;

    const size = (get(variant, 'availableSizesWithId') || []).find(productSize => productSize.id === cartItem.sizeId);

    const {
      title,
      productType,
      inventoryCount,
      visibility,
      listingId
    } = product;

    const price = getPriceFromSize(variant, get(size, 'id')) || get(product, 'price');
    const images = get(variant, 'images');
    const primaryImages = images.find(img => img.label === 'primary' || img.label === 'front');

    return {
      primaryImgPath: get(primaryImages, 'src'),
      primaryImages,
      selectedSize: size,
      quantityInCart: cartItem.quantity,
      colorName: get(variant, 'color'),
      totalPrice: (cartItem.quantity * parseFloat(price)).toFixed(2),
      title: title || listing.title,
      productType,
      price,
      sku,
      inventoryCount,
      visibility,
      variantData: {
        ...variant
      },
      listingId: listingId || listing.listingId
    };
  }
};

export const pruneCart = (userCart) => {
  if (has(userCart, 'region')) {
    const prunedCart = {
      ...userCart
    };

    delete prunedCart.region;
    return prunedCart;
  }
  return userCart;
};

export const getCartLineItems = (userCart, storeListings, inventory) => {
  const cart = pruneCart(userCart);
  return Object.values(cart).reduce((acc, curr) => {
    const product = mapLineItem(curr, storeListings, inventory);

    if (product) acc.push(product);
    return acc;
  }, []);
};

export const cartListingsAreLoaded = (userCart, storeListings) => {
  const cart = pruneCart(userCart);
  const cartSlugs = Object.keys(cart).map(item => userCart[item].slug);
  const listingsLoaded = every(cartSlugs, (cartSlug) => {
    return has(storeListings, cartSlug);
  });

  return listingsLoaded;
};

export const cartItemsWithQty = (cartItems) => {
  return cartItems.filter(item => item.quantityInCart && item.inventoryCount > 0 && item.visibility !== 'private');
};

export const cartContentIds = (userCart, storeListings, inventory) => {
  return (getCartLineItems(userCart, storeListings, inventory) ?? []).reduce((acc, curr) => {
    acc.push(`${curr?.variantData?.teespringId}-${curr?.variantData?.productId}`);
    return acc;
  }, []);
};

export const getCartLineItemsTotal = (lineItems) => {
  const total = lineItems.reduce((acc, item) => {
    return (parseFloat(item.totalPrice)) + acc;
  }, 0);
  return parseFloat(total.toFixed(2));
};

export const getCartLineItemsTotalQuantity = lineItems => lineItems.reduce((quantity, lineItem) => quantity + lineItem.quantityInCart, 0);

export const getUnavailableItems = (userCart, storeListings, inventory) => {
  const cart = {
    ...userCart
  };

  delete cart.region;

  return Object.values(cart).map(
    (cartItem) => {
      const listing = storeListings[cartItem.slug];

      const unavailableItem = {
        sku: cartItem.sku,
        fromUnavailableListing: false
      };

      if (hasIn(listing, 'errors')) {
        return {
          ...unavailableItem,
          fromUnavailableListing: true
        };
      }

      if (isEmpty(listing) || !hasIn(listing, 'products') || !isArray(listing.products)) return null;
      const product = find(listing.products, listingProduct => listingProduct.variationId === cartItem.colorId);
      if (!product) return null;

      if (isUnavailableItem(cartItem, product, listing, inventory)) {
        return {
          ...unavailableItem,
          title: product.title,
          productType: product.productType
        };
      } else {
        return null;
      }
    }
  ).filter(value => value);
};

const skusWithQuantity = items => items.map(item => `${item.sku}_${item.quantityInCart}`).join(',');

export const redirectToCheckout = (
  paymentMethod,
  items,
  storeId,
  localizationData,
  affiliate,
  userCart,
  cartProducts,
  walletType,
  inventory,
  disableAfterpay
) => {
  document.location.href = themedCheckoutURL(
    paymentMethod,
    skusWithQuantity(items),
    storeId,
    localizationData,
    affiliate,
    userCart,
    cartProducts,
    walletType,
    inventory,
    disableAfterpay
  );
};

export const getCartTotal = (items) => {
  return items.reduce((sum, item) => sum + parseFloat(item.totalPrice), 0).toFixed(2);
};

export const getPersistantCart = (storeId) => {
  const storeCookieData = getCookie(storeId);
  const storeData = isEmpty(storeCookieData) ? {} : JSON.parse(storeCookieData);
  return storeData.cart || {};
};

export const getPersistantCartRegion = (storeId) => {
  const storeCookieData = getCookie(storeId);
  const storeData = isEmpty(storeCookieData) ? {} : JSON.parse(storeCookieData);
  return get(storeData, 'region', '');
};

export const setPersistantCart = (storeId, cartData = {}) => {
  const storeCookieData = getCookie(storeId);
  const localization = getLocalizationDetails();
  const storeData = isEmpty(storeCookieData) ? {} : JSON.parse(storeCookieData);
  const currentRegion = get(storeData, 'region', '');
  // eslint-disable-next-line no-nested-ternary
  const region = !currentRegion
    ? localization.buyer_region
    : currentRegion !== localization.buyer_region
      ? 'MIXED'
      : localization.buyer_region;

  const newStoreData = Object.assign({}, storeData, {
    cart: cartData,
    region
  });

  deleteCookie(storeId);
  setCookie(storeId, JSON.stringify(newStoreData));
  return newStoreData;
};

export const upsert = async (url, body, shouldCreate) => {
  const res = await fetch(url, {
    method: shouldCreate ? 'POST' : 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const data = await res.json();
  return data;
};

export const calculateQtyFromLineItems = (lineItems) => {
  const getQty = (lineItem) => {
    return typeof lineItem === 'string' ? parseInt(lineItem.slice(-1)) : lineItem.quantity;
  };
  return lineItems
    .map(lineItem => getQty(lineItem))
    .reduce((acc, val) => acc + val, 0);
};

export const isNFTListing = (listing) => {
  const isNft = (listing?.primaryProduct?.[0]?.productType?.toLowerCase() === 'nft'
  || listing?.primaryProductId === NFT_PRODUCT_ID) ?? false;
  return isNft;
};
