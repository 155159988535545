/* eslint-disable no-param-reassign */
let loaded = false;
export const loadZendeskScript = (cb) => {
  const zKey = process.env.REACT_APP_ZENDESK_CHAT_KEY;
  if (!zKey) return;
  const liveChatScript = `https://static.zdassets.com/ekr/snippet.js?key=${zKey}`;
  const zendeskScript = document.createElement('script');
  zendeskScript.id = 'ze-snippet';
  zendeskScript.src = liveChatScript;
  zendeskScript.onload = cb;
  document.body.appendChild(zendeskScript);
  loaded = true;
};

const getZendeskButtonWrapperDiv = (elem) => {
  if (!elem) return [];
  const siblings = [];
  elem = elem.nextElementSibling;
  while (elem) {
    if (elem.classList.length === 0 && elem.tagName === 'DIV' && !elem.id) {
      siblings.push(elem);
    }
    elem = elem.nextElementSibling;
  }
  return siblings;
};

const isMobile = () => {
  const ua = navigator.userAgent.toLowerCase();
  const platform = navigator.platform.toLowerCase();
  const platformName = ua.match(/ip(?:ad|od|hone)/) ?
    'ios' :
    (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0];
  const navigatorMobile = /ios|android|webos/.test(platformName);
  return navigatorMobile || window.innerWidth < 767;
};

// eslint-disable-next-line consistent-return
export const manageZendeskVisibility = (visible) => {
  const manageButton = () => {
    const display = visible || !isMobile() ? '' : 'none';
    const chatButton = document.querySelector('[data-product="web_widget"]');
    const divs = getZendeskButtonWrapperDiv(chatButton);
    if (divs.length > 0) divs[0].style.setProperty('display', display);
  };
  if (isMobile() && visible === false && loaded === false) {
    return manageButton();
  } else if (loaded === false) {
    loadZendeskScript(manageButton);
  } else {
    manageButton();
  }
};
