/* eslint-disable no-await-in-loop */

export const handleErrors = (response) => {
  if (response.ok) {
    return response;
  }

  if (response.status === 401 && window.location.href.indexOf('login') === -1) {
    window.location.href = '/login';
  } else {
    return response;
  }

  throw Error(response.statusText);
};

export const requestWithRetry = async ({
  url,
  options = {
    method: 'GET'
  },
  requestName = '',
  retryCount = 2
}) => {
  let retries = 0;

  let data = null;
  let error = null;

  while (retries < retryCount && !data) {
    try {
      const res = await fetch(url, options);

      if (!res.ok) {
        const { errors } = await res.json();
        if ((res.status >= 400 && res.status <= 499) && (errors && errors.length > 0)) {
          error = errors;
          break;
        } else {
          error = `${requestName} Request retry exceeded limit`;
          retries += 1;
        }
      } else {
        error = null;
        data = await res.json();
      }
    } catch (err) {
      error = `${requestName} ${err.message}`;
      retries += 1;
    }
  }

  if (error) {
    throw error;
  } else {
    return data;
  }
};
