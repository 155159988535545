// Event Types
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import { convertToDollars } from '../../moneyUtils';
import removeCurrencySymbol from '../../../lib';

export const ADD_TO_CART = 'add-to-cart';
export const REMOVE_FROM_CART = 'remove-from-cart';
export const PRODUCT_CLICK = 'product-click';
export const PRODUCT_IMPRESSIONS = 'product-impression';
export const PURCHASE_COMPLETE = 'purchase';
export const VIEW_ITEM = 'view-item';
export const BEGIN_CHECKOUT = 'begin-checkout';

export const initializeGTM = () => {
  const gtmIds = [process.env.REACT_APP_GTM_CONTAINER_ID, process.env.REACT_APP_SECONDARY_GTM_CONTAINER_ID];

  for (let i = 0; i < gtmIds.length; i += 1) {
    const gtmId = gtmIds[i];
    if (gtmId) {
      const script = document.createElement('script');
      const noscript = document.createElement('noscript');

      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}')`;

      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      document.head.insertBefore(script, document.head.childNodes[0]);
      document.body.insertBefore(noscript, document.body.childNodes[0]);
    }
  }
};

export const skuFrom = (listingId, productId, variationId, sizeId) => `${listingId}-${productId}-${variationId}-${sizeId}`;
const replaceLowDashesWithDashes = aString => aString?.replace(/_/g, '-') || '';
const formatPurchaseEvent = ({
  order,
  orders,
  storeName,
  sellerId,
  storeId,
  storeSlug
}) => {
  const shippingCost = convertToDollars(reduce(orders, (total, cartOrder) => total + cartOrder?.shippingCost?.amount, 0));
  const taxCost = convertToDollars(reduce(orders, (total, cartOrder) => total + cartOrder?.tax?.amount, 0));
  const totalCost = convertToDollars(reduce(orders, (total, cartOrder) => total + cartOrder?.totalCost?.amount, 0));
  return {
    event: PURCHASE_COMPLETE,
    ecommerce: {
      transaction_id: order.cartId,
      currency: order?.totalCost?.currency || 'USD',
      shipping: shippingCost || 0,
      tax: taxCost || 0,
      value: totalCost || 0,
      coupon: order.promo,
      sellerId,
      storeId,
      storeSlug,
      items: map(flatMap(orders, cartOrder => get(cartOrder, 'items', [])), (item) => {
        return {
          affiliation: 'TS Microstore',
          item_name: item.title,
          item_id: replaceLowDashesWithDashes(item.sku),
          price: convertToDollars(item?.price?.amount || 0),
          quantity: item.quantity,
          item_brand: storeName,
          item_variant: `Color: ${item.color} | Size: ${item.size}`,
          item_category: get(item, 'productType', ''),
          coupon: order.promo
        };
      })
    }
  };
};

const itemPriceFor = item => +parseFloat(removeCurrencySymbol(item.price)).toFixed(2);

const formatRemoveFromCartEvent = (item) => {
  const itemPrice = itemPriceFor(item);
  return ({
    event: REMOVE_FROM_CART,
    ecommerce: {
      currency: item.variantData.currency,
      value: itemPrice * item.quantityInCart,
      sellerId: item.sellerId,
      storeId: item.storeId,
      storeSlug: item.storeSlug,
      items: [{
        item_id: replaceLowDashesWithDashes(item.sku),
        item_name: item.title,
        price: itemPrice,
        item_brand: item.storeName,
        item_category: item.productType,
        item_variant: `Color: ${item.variantData.color} | Size: ${item.selectedSize.label}`,
        quantity: item.quantityInCart
      }]
    }
  });
};

const formatAddToCartEvent = (item) => {
  const itemPrice = itemPriceFor(item); //
  return ({
    event: ADD_TO_CART,
    ecommerce: {
      currency: item.currency,
      value: itemPrice * item.quantity,
      sellerId: item.sellerId,
      storeSlug: item.storeSlug,
      storeId: item.storeId,
      items: [{
        item_id: replaceLowDashesWithDashes(item.sku),
        item_name: item.title,
        item_category: item.productType,
        price: itemPrice,
        quantity: item.quantity
      }]
    }
  });
};

const formatViewItemEvent = (item) => {
  const itemPrice = itemPriceFor(item);
  return {
    event: VIEW_ITEM,
    ecommerce: {
      storeId: item.storeId,
      storeSlug: item.storeSlug,
      currency: item.currency,
      value: itemPrice,
      sellerId: item.sellerId,
      items: [{
        item_id: replaceLowDashesWithDashes(item.sku),
        item_name: item.name,
        item_category: item.productType
      }]
    }
  };
};
const formatBeginCheckoutEvent = (item) => {
  const totalCartPrice = itemPriceFor(item);
  return {
    event: BEGIN_CHECKOUT,
    ecommerce: {
      currency: item.currency,
      value: totalCartPrice,
      sellerId: item.sellerId,
      storeId: item.storeId,
      storeSlug: item.storeSlug,
      items: item.items.map(anItem => ({
        item_id: replaceLowDashesWithDashes(anItem.sku),
        item_name: anItem.name,
        item_category: anItem.productType,
        price: itemPriceFor(anItem),
        quantity: anItem.quantity
      }))
    }
  };
};
const EVENT_FORMATTERS = [
  { format: formatAddToCartEvent, canFormatEvent: eventType => eventType === ADD_TO_CART },
  { format: formatRemoveFromCartEvent, canFormatEvent: eventType => eventType === REMOVE_FROM_CART },
  { format: formatViewItemEvent, canFormatEvent: eventType => eventType === VIEW_ITEM },
  { format: formatPurchaseEvent, canFormatEvent: eventType => eventType === PURCHASE_COMPLETE },
  { format: formatBeginCheckoutEvent, canFormatEvent: eventType => eventType === BEGIN_CHECKOUT }
];

export const pushToDataLayer = (body) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(body);
};
/**
 *
 * @param {string} eventType one of the defined constants above
 * @param {object} eventBody
 *
 */

export const pushEvent = (eventType, eventBody) => {
  EVENT_FORMATTERS
    .filter(formatter => formatter.canFormatEvent(eventType))
    .forEach((formatter) => {
      const event = formatter.format(eventBody);
      pushToDataLayer(event);
    });
};
