import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="loader">
      <div className="lds-ellipsis">
        <div className="bg--primary" />
        <div className="bg--primary" />
        <div className="bg--primary" />
        <div className="bg--primary" />
      </div>
    </div>
  );
};

export default Loader;
