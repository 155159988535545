/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { manageZendeskVisibility } from 'utils/zendeskUtils';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { fetchCartListings } from 'redux/actions';
import { cartItemsWithQty, cartListingsAreLoaded, getCartLineItems } from 'utils/cartUtils';
import CheckoutButton from 'components/CheckoutButton';
import Loader from 'components/Loader';
import SEO from 'components/SEO';
import CheckoutHeader from 'pages/CheckoutPage/CheckoutHeader';
import isEmpty from 'lodash/isEmpty';
import tracker from 'utils/tracking';
import { bpProps } from 'utils/responsiveUtils';

import './CheckoutExpress.scss';

export const CheckoutExpress = () => {
  const { inventory, stores } = useSelector(state => state);
  const { name: storeName } = stores;
  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));

  const {
    userCart, storeListings, localizationData, cartProducts
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation();
  const deviceId = new URLSearchParams(search).get('deviceId');
  const method = new URLSearchParams(search).get('method');

  const methodMap = {
    afterpay: 'afterpay',
    stripe: 'stripe',
    paypal: 'paypal',
    googlepay: 'google-pay',
    applepay: 'apple-pay'
  };

  const cartItems = getCartLineItems(userCart, cartProducts, inventory);

  const fetchListings = async () => {
    await dispatch(fetchCartListings(userCart, localizationData));
  };

  const isLoaded = cartListingsAreLoaded(userCart, cartProducts) && !storeListings?.isFetchingCart;

  useEffect(() => {
    fetchListings();
  }, []);

  useEffect(() => {
    if (deviceId) tracker.setAmplitudeDeviceId(deviceId);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (isEmpty(cartItems)) {
        return history.push(`/cart`);
      } else {
        const btn = document.querySelector('.btn-hidden');
        (btn).click();
      }
    }
  }, [isLoaded]);

  useEffect(() => {
    manageZendeskVisibility(false);
  }, [bpIsGT('mobileLg')]);

  return (
    <div className="checkout--legacy">
      <SEO title={ `Checkout | ${storeName}` } />
      <CheckoutHeader />
      <div className="checkout-express">
        <Loader />
        {isLoaded && (
          <CheckoutButton
            className="btn-hidden"
            lineItems={ cartItemsWithQty(cartItems) }
            paymentMethod={ methodMap[method] ?? 'afterpay' }
          />
        )}
      </div>
    </div>
  );
};

export default CheckoutExpress;
