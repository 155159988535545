import React from 'react';

const GlobalPropsContext = React.createContext();

function useGlobalProps() {
  const context = React.useContext(GlobalPropsContext);
  return context;
}

function GlobalPropsProvider(props) {
  const [globalProps, setGlobalProps] = React.useState(0);
  const value = React.useMemo(() => [globalProps, setGlobalProps], [globalProps]);
  return <GlobalPropsContext.Provider value={ value } { ...props } />;
}

export { GlobalPropsProvider, useGlobalProps };
