import React from 'react';
import { useSelector } from 'react-redux';
import { string, number } from 'prop-types';
import get from 'lodash/get';

// Holiday Message displayed only in December 2023
const HolidayMessage = ({
  listingSlug,
  activeProductIndex
}) => {
  const getHolidayMessage = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();

    const { storeListings } = useSelector(state => state);
    const deliveryDetails = get(
      get(storeListings[listingSlug], `products[${activeProductIndex}]`),
      'deliveryDetails'
    );
    const digital = deliveryDetails && deliveryDetails.type !== 'physical';
    let message = null;

    if (currentYear === 2023 && currentMonth === 11) { // December
      if (digital && currentDay <= 25) {
        message = 'You will receive this digital product within 5 hours of purchase';
      } else if (currentDay >= 1 && currentDay <= 5) {
        message = 'Buy Now: Arrives by 12/25 with standard shipping';
      } else if (currentDay >= 6 && currentDay <= 8) {
        message = 'Check out now! Arrives by 12/25 only with rush shipping';
      } else if (currentDay >= 9 && currentDay <= 10) {
        message = 'Last Chance! Arrives by 12/25 only with super rush shipping';
      }
    }
    return message && <div className="listing__holidaymessage">{message}</div>;
  };

  const displayMessage = getHolidayMessage();
  return displayMessage && <div className="listing__holidaymessage">{displayMessage}</div>;
};

HolidayMessage.propTypes = {
  listingSlug: string.isRequired,
  activeProductIndex: number.isRequired
};

export default HolidayMessage;
