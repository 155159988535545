const getCookieValue = () => document.cookie.split(';')[0].split('=')[1];

const findCookie = (name) => {
  const cookieValue = document.cookie.split(';').find(item => item.startsWith(` ${name}`)) || '';
  return cookieValue ? cookieValue.split('=')[1] : '';
};

export const getCookie = (value) => {
  // The document.cookie is an string an may have more than one cookie.
  // Example: "_aCookie=1234; anotherCookie=12345; _ga=GA1.1.1111111111.1111111111"
  if (document.cookie) {
    return document.cookie.startsWith(`${value}`) ? getCookieValue() : findCookie(value);
  } else {
    return '';
  }
};

export const setCookie = (key, value) => {
  document.cookie = ` ${key}=${value}; path=/`;
};

export const deleteCookie = (key) => {
  document.cookie = `${key}=${getCookie(key)};max-age=0`;
};
