import { TrackJS } from 'trackjs';

const TrackJSLogger = () => next => (action) => {
  try {
    TrackJS.console.log(action);
  } catch (err) {
    TrackJS.track(err);
  }
  return next(action);
};

export default TrackJSLogger;
