/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isTouchDevice } from 'utils/responsiveUtils';
import propTypes from 'prop-types';
import {
  Icon, SelectField, Tooltip, Button
} from '@springforcreators/propel-ui';
import { setBuyerLocalization, setActiveModal } from 'redux/actions';
import get from 'lodash/get';
import './LocalizationModal.scss';

const LocalizationModal = (props) => {
  const {
    localizationData,
    dispatchSetBuyerLocalization,
    dispatchCloseModal
  } = props;

  const currencyOptions = localizationData.available_currencies.map(curr => ({
    label: `${curr.symbol} ${curr.currency}`,
    id: curr.currency
  }));

  const regionOptions = localizationData.available_regions.map(curr => ({
    label: curr.region,
    id: curr.fulfillment_region
  }));

  const fulfillmentRegion = get(localizationData, 'fulfillment_region', 'USA');
  const buyerCurrency = get(localizationData, 'buyer_currency', '');

  const [activeCurrencyIndex, setActiveCurrencyIndex] = useState(
    get(localizationData, 'available_currencies', []).findIndex(
      curr => curr.currency.toLowerCase() === buyerCurrency.toLowerCase()
    )
  );

  const [activeRegionIndex, setActiveRegionIndex] = useState(
    get(localizationData, 'available_regions', []).findIndex(
      curr => curr.region.toLowerCase() === fulfillmentRegion.toLowerCase()
    )
  );

  const forceRefresh = () => {
    window.location.reload();
  };

  const updateCurrency = (index) => {
    setActiveCurrencyIndex(index);
  };

  const updateRegion = (index) => {
    setActiveRegionIndex(index);
  };

  const onSubmit = () => {
    dispatchSetBuyerLocalization({
      buyer_currency: currencyOptions[activeCurrencyIndex].id,
      buyer_region: regionOptions[activeRegionIndex].id,
      fulfillment_region: regionOptions[activeRegionIndex].label
    });

    forceRefresh();
  };

  return (
    <>
      <div className="languagecontrols">
        <div className="mb2">
          <span>
            <span className="typ--b3">Select your fulfillment region</span>

            <Tooltip
              toggle={ <Icon name="HelpCircle" size={ 15 } className="ml1" /> }
              content="This option controls which country your order will be fulfilled from. This may affect factors including price, shipping options and product availability."
              triggerMethod={ isTouchDevice ? 'click' : 'hover' }
            />
          </span>
          <SelectField
            items={ regionOptions }
            activeIndex={ activeRegionIndex }
            onChange={ updateRegion }
            defaultValue={ regionOptions[activeRegionIndex]?.label }
            className="languagecontrols__region"
          />
        </div>

        <div className="mb2">
          <span>
            <span className="typ--b3">Select your currency</span>

            <Tooltip
              toggle={ <Icon name="HelpCircle" size={ 15 } className="ml1" /> }
              content="This option controls your preferred currency. The prices will be updated to reflect your selection."
              triggerMethod={ isTouchDevice ? 'click' : 'hover' }
            />
          </span>
          <SelectField
            items={ currencyOptions }
            activeIndex={ activeCurrencyIndex }
            onChange={ updateCurrency }
            defaultValue={ currencyOptions[activeCurrencyIndex]?.label }
            className="languagecontrols__currency"
          />
        </div>

        {/* TODO Disable language dropdown until functionality is built */}
        {/* <div>
          <span>
            <span className="typ--b3">Select your language</span>

            <Tooltip
              toggle={ (<Icon name="HelpCircle" size={15} className="ml1" />) }
              content="This option will attempt to translate the site based on your selection. Translations are provided using google translate and some inaccuracies may be present."
            />
          </span>
          <Dropdown
            items={ localeOptions }
            activeIndex={ activeLocaleIndex }
            onChange={ updateLocale }
            className="languagecontrols__locale"
          />
        </div> */}
      </div>

      <div className="languagecontrols__submit">
        <Button
          type="button"
          btnType="secondary"
          onClick={ dispatchCloseModal }
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={ () => onSubmit() }
        >
          Submit
        </Button>
      </div>
    </>
  );
};

const { func, shape, array } = propTypes;
LocalizationModal.propTypes = {
  localizationData: shape({
    available_currencies: array,
    available_regions: array,
    available_locales: array
  }).isRequired,
  dispatchSetBuyerLocalization: func.isRequired,
  dispatchCloseModal: func.isRequired
};

const mapStateToProps = state => ({
  localizationData: state.localizationData
});

const mapDispatchToProps = dispatch => ({
  dispatchSetBuyerLocalization: data => dispatch(setBuyerLocalization(data)),
  dispatchCloseModal: () => dispatch(setActiveModal(null))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalizationModal);
