import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import get from 'lodash/get';

/**
 * Higher order react component that connects WrappedComponent to
 * store and appends theme data filtered by the passed params
 *
 * @param {Object} WrappedComponent     React component to wrap and return
 * @param {(string[]|string)} params    String or Array of strings representing themeData
 *                                      to connect, can use dot notiation to reach deeply
 *                                      nested values. ie. 'content.footer.aboutText'
 *
 * @return {Object}                     Returns connected react component with injected
 *                                      themeData props
 */
function ThemeWrapper(WrappedComponent, params) {
  const BaseTheme = (props) => {
    const { themeData, ...rest } = props;
    let paramsArr = params;

    // If string is used for params, convert to array
    if (typeof params === 'string') {
      paramsArr = [params];
    }

    // Construct data object filtered by params
    let filteredData = {};
    if (paramsArr && paramsArr.length) {
      paramsArr.forEach((param) => {
        const data = get(themeData, param);

        // Substitute empty object if no data can be found.
        if (!data && process.env.REACT_APP_ENV !== 'production') {
          // eslint-disable-next-line
          console.warn(`Missing data: Could not find value pair asssociated with [${param}]`);
        }

        // Convert string param into nested object if dot notation is used
        // ie. content.footer.aboutText => content: { footer: { aboutText: '' } }
        const keys = param.split('.');
        Object.assign(filteredData, keys.reduceRight((d, key) => ({ [key]: d }), data));
      });
    } else {
      // If no params, return entire themeData object
      filteredData = themeData;
    }

    return <WrappedComponent { ...filteredData } { ...rest } />;
  };

  const mapStateToProps = state => ({
    themeData: state.themeData
  });

  const { shape } = propTypes;
  // TODO - Fill out prop types once
  // data structure is finalized
  BaseTheme.propTypes = {
    themeData: shape({
      content: shape({}),
      styles: shape({}),
      mets: shape({}),
      layout: shape({})
    }).isRequired
  };

  return connect(mapStateToProps)(BaseTheme);
}

export default ThemeWrapper;
