import { SET_DELIVERY_OPTIONS, IS_FETCHING_DELIVERY_OPTIONS, CLEAR_DELIVERY_OPTIONS } from 'redux/actions';

const INITIAL_STATE = {
  options: [],
  isFetching: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DELIVERY_OPTIONS: {
      return {
        ...state,
        options: action.options
      };
    }
    case IS_FETCHING_DELIVERY_OPTIONS:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case CLEAR_DELIVERY_OPTIONS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
