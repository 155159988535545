import amplitude from './services/amplitude';
import tiktok from './pixels/tiktok';

const branchName = 'redesign-product-details';

const services = [
  amplitude
];

const track = (event, params, context) => {
  // The branchName property is included here to identify this branch in amplitude.
  const newParams = { ...params, branchName };
  if (process.env.REACT_APP_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(`FAKE ANALYTICS: ${event} with these params: `, params);
  }
  services.forEach(
    service => service.track && service.track(event, newParams, context)
  );
};

const identifyUser = (userProperties) => {
  amplitude.identifyUser({ ...userProperties, branchName });
};

const init = (state) => {
  services.forEach(
    service => service.init && service.init(state)
  );
};

const deviceId = () => {
  return amplitude.deviceId();
};

const setAmplitudeDeviceId = (id) => {
  amplitude.setDeviceId(id);
};

export { tiktok };

export default {
  track,
  init,
  identifyUser,
  deviceId,
  setAmplitudeDeviceId
};

