import React from 'react';
import propTypes from 'prop-types';
import ListingGalleryItem from './ListingGalleryItem';
import './ListingGalleryMenu.scss';

export const ListingGalleryMenu = (props) => {
  const {
    onThumbClick,
    thumbImages,
    activeIndex
  } = props;

  return (
    <div className="listing-gallery-menu-container">
      <div className="listing-gallery-menu">
        { thumbImages && thumbImages.length > 0 && (
          thumbImages.map((image, index) => (
            <ListingGalleryItem
              key={ image.src + image.label }
              thumbData={ { index, originalData: image } }
              isActive={ index === activeIndex }
              onThumbClick={ onThumbClick }
            />
          )))}
      </div>
    </div>
  );
};

const {
  func,
  arrayOf,
  object,
  number
} = propTypes;
ListingGalleryMenu.propTypes = {
  onThumbClick: func.isRequired,
  thumbImages: arrayOf(object).isRequired,
  activeIndex: number.isRequired
};

export default ListingGalleryMenu;
