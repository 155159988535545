/*eslint-disable camelcase*/

import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { useFlags } from 'launchdarkly-react-client-sdk';
import './GlobalNotification.scss';

export const GlobalNotification = () => {
  // eslint-disable-next-line no-unused-vars
  const flags = useFlags();
  const { storeGlobalNotification } = useFlags();

  // Early out if the flag doesn't pass a sniff test
  if (!storeGlobalNotification || typeof storeGlobalNotification !== 'string') {
    return null;
  }

  // Ignore strings that are only whitespace after sanitization (this also catches
  // a string of '&nbsp;', which we're currently getting)
  const sanitizedText = sanitizeHtml(storeGlobalNotification);
  if (!sanitizedText || sanitizedText.trim() === '') {
    return null;
  }

  return (
    <div
      className="global-notification"
      // eslint-disable-next-line react/no-danger, max-len
      dangerouslySetInnerHTML={ { __html: sanitizedText } }
    />
  );
};

export default GlobalNotification;
