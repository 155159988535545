/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint no-return-await: 0, no-nested-ternary: 0, no-shadow: 0*/
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withCookies } from 'react-cookie';
import {
  CardElement,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useStripe,
  useElements,
  AfterpayClearpayMessageElement,
  PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import propTypes from 'prop-types';
import get from 'lodash/get';
import { bpProps } from 'utils/responsiveUtils';
import { setCookie } from 'utils/cookiesUtils';
import {
  calculateQtyFromLineItems,
  getCartLineItems,
  getCartLineItemsTotal,
  getPersistantCart
} from 'utils/cartUtils';
import requestPaymentIntent from 'lib/paymentIntent';
import { legalizeCopy, getWalletIcon, walletCopy } from 'utils/checkoutUtils';
import tracker from 'utils/tracking';
import {
  InputField,
  Icon,
  CheckboxInput,
  RadioGroup,
  Button
} from '@springforcreators/propel-ui';
import { setActiveModal, SET_STRIPE_ERROR } from 'redux/actions';
import {
  getPaymentIntent,
  setPayment,
  setPaymentMethod,
  setCheckoutError,
  updateCheckout
} from 'redux/actions/checkout';

import { defaultBtnStyles } from 'components/ThemeButton';
import { usePaymentRequest, usePaymentRequestOptions } from 'hooks';
import PaypalOption from './PaypalOption';
import {
  HIDE_AFTERPAY,
  RECAP_KEY
} from '../../../../constants';
import './PaymentForm.scss';

const PaymentForm = ({ placeOrder, addressHasBeenEntered, activeSteps }) => {
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const reduxState = useSelector(state => state, shallowEqual);
  const {
    checkout,
    stores,
    userCart,
    cartProducts,
    inventory,
    deliveryOptions
  } = reduxState;
  const { bpIsGT, getStyles } = useSelector(state => ({ ...bpProps(state) }));

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const paymentMethod = get(checkout, 'paymentMethod');
  const paypalSelected = paymentMethod === 'paypal';

  const getStripeError = () => {
    let stripeError = checkout?.stripeError;

    if (stripeError) {
      const stripeErrors = [
        'Your card was declined.',
        'Your card has insufficient funds.',
        'Your card has expired.',
        'An error occurred while processing your card. Try again in a little bit.',
        `Your card's security code is incorrect.`,
        'Your card number is invalid.'
      ];

      const defaultStripeError = 'Your card was declined.';

      if (!stripeErrors.includes(stripeError)) {
        stripeError = defaultStripeError;
      }
    }
    return stripeError;
  };

  const {
    handleSubmit, register, formState, getValues
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { search } = useLocation();
  const {
    paymentRequest,
    customerWalletDetails,
    paymentIntentData,
    walletType
  } = usePaymentRequest();
  const currentPaymentToken = new URLSearchParams(search).get('payment_intent');
  const [delayHandlePayment, setDelayHandlePayment] = useState(false);
  const options = usePaymentRequestOptions(paymentRequest, {
    height: '55px'
  });

  const stripeElementsStyles = {
    base: {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: '"Proxima Nova", sans-serif',
      fontSmoothing: 'antialiased'
    }
  };

  const stripeElementsClasses = {
    base: 'stripe-checkout-container form__input',
    invalid: 'stripe-checkout-container--error'
  };

  const cardElementOptions = {
    placeholder: 'Card number*',
    showIcon: true,
    style: stripeElementsStyles,
    classes: stripeElementsClasses
  };

  const cache = getPersistantCart(stores.slug);
  const isUSCart = get(cache, 'region', '').toLowerCase() === 'usa';

  const createPaymentIntent = async (paymentMethodId) => {
    return await dispatch(
      getPaymentIntent(
        paymentMethodId,
        currentPaymentToken ?? checkout?.payment?.paymentToken
      )
    );
  };

  const handleWalletReady = async () => {
    setProcessing(true);
    await placeOrder(
      paymentIntentData.paymentToken,
      paymentIntentData.paymentId,
      customerWalletDetails
    );
  };

  useEffect(() => {
    if (customerWalletDetails) {
      handleWalletReady();
    }
  }, [customerWalletDetails]);

  useEffect(() => {
    if (!disabled && !addressHasBeenEntered) setDisabled(true);
    if (!error) setDisabled(false);
  }, [disabled, error, addressHasBeenEntered]);

  const handleChange = (event) => {
    setDisabled(!!(event?.empty || event?.error?.message));
    setError(event?.error?.message ?? '');
    dispatch({
      type: SET_STRIPE_ERROR,
      data: null
    });
  };

  const cartLineItems = getCartLineItems(userCart, cartProducts, inventory);
  const cartTotal = getCartLineItemsTotal(cartLineItems);

  const trackOrderPlaced = () => {
    tracker.track('checkout.place_order.clicked', {
      checkoutId: checkout?.id,
      qty: calculateQtyFromLineItems(checkout?.lineItems),
      cartTotal,
      platform: checkout?.platform,
      paymentMethod: checkout?.paymentMethod,
      storeId: stores.id,
      storeSlug: stores.slug,
      shippingCosts: checkout?.costs?.shipping.value,
      deliveryOption: checkout?.deliveryOption
    });
  };

  const handlePayment = async (values) => {
    trackOrderPlaced();
    setProcessing(true);
    const stripeBasePayload = {
      type: paymentMethod === 'afterpay' ? 'afterpay_clearpay' : paymentMethod,
      billing_details: {
        name:
          get(values, 'name') ||
          `${get(checkout, 'customer.firstName')} ${get(
            checkout,
            'customer.lastName'
          )}`,
        email: get(checkout, 'customer.email'),
        address: {
          line1: get(checkout, 'customer.address.address1'),
          line2: get(checkout, 'customer.address.address2'),
          city: get(checkout, 'customer.address.city'),
          state: get(checkout, 'customer.address.state'),
          country: 'US',
          postal_code: get(checkout, 'customer.address.zip')
        }
      }
    };

    const payloadData =
      paymentMethod === 'card' ?
        {
          ...stripeBasePayload,
          card: elements.getElement(
            bpIsGT('mobileLg') ? CardElement : CardNumberElement
          )
        } :
        stripeBasePayload;

    if (addressHasBeenEntered) {
      const payload = await stripe.createPaymentMethod(payloadData);

      if (!payload?.error) {
        await createPaymentIntent(payload?.paymentMethod?.id);
        await placeOrder();
        setProcessing(false);
        setError(null);
      } else {
        setProcessing(false);
      }
    }
  };

  useEffect(() => {
    if (checkout?.redirectUrl) {
      window.location.assign(checkout?.redirectUrl);
    }

    if (checkout?.customer?.email.length > 0 && delayHandlePayment) {
      setDelayHandlePayment(false);
      if (addressHasBeenEntered) handlePayment(getValues());
    }
  }, [checkout, addressHasBeenEntered]);

  const handlePaypalSuccess = async () => {
    await placeOrder();
    setProcessing(false);
    setError(null);
  };

  const onCreate = async () => {
    try {
      const paymentIntentData = await requestPaymentIntent(reduxState, 'card');

      const paymentIntent = {
        id: paymentIntentData.paymentId,
        paymentToken: paymentIntentData.paymentToken
      };

      setCookie('payment', JSON.stringify(paymentIntent));

      dispatch(setPayment(paymentIntent));

      return paymentIntentData?.paymentToken;
    } catch (err) {
      throw new Error(`Error when getting payment intent: ${err.message}`);
    }
  };

  const handlePaypalError = () => {
    dispatch(setCheckoutError('There was an issue authorizing with PayPal'));
  };

  const handlePaymentOptionClick = (paymentMethod) => {
    tracker.track('checkout.payment_method.clicked', {
      checkoutId: checkout?.id,
      platform: paymentMethod === 'paypal' ? 'paypal' : 'stripe',
      paymentMethod,
      storeId: stores.id,
      storeSlug: stores.slug
    });
    dispatch(setPaymentMethod(paymentMethod));
  };

  const handlePaypalClick = () => {
    trackOrderPlaced();
  };

  const handleWalletPayment = () => {
    trackOrderPlaced();
    paymentRequest.update({
      total: { label: 'Total cost', amount: checkout?.costs?.total.value }
    });
  };

  const handleOptInClick = useCallback(
    async (checked) => {
      tracker.track('checkout.marketingOptIn.clicked', {
        marketingOptIn: checked
      });
      await dispatch(updateCheckout({ marketingOptIn: !checked }));
    },
    [dispatch]
  );

  const deliveryOptionsAvailable = deliveryOptions.options.length > 0;
  const stripeError = getStripeError();

  const paymentOptions = [
    {
      id: 'card',
      className: 'card',
      title: (
        <div className="paymentoption__heading">
          <span>Card</span>
          <img
            className="checkout__paymentinfo__item__icon"
            src={ getWalletIcon('card') }
            alt="Credit Card"
          />
        </div>
      ),
      content: paymentMethod === 'card' && (
        <div className="paymentoption__inner__card">
          {(error || stripeError) && (
            <div className="pr_form__error_box">
              <Icon name="Info" size={ 24 } color="red" className="info_icon" />
              <div className="pr_form__error">{error || stripeError}</div>
            </div>
          )}
          {bpIsGT('mobileLg') ? (
            <CardElement options={ cardElementOptions } onChange={ handleChange } />
          ) : (
            <>
              <CardNumberElement
                options={ cardElementOptions }
                onChange={ handleChange }
              />
              <div className="card-options">
                <CardExpiryElement
                  options={ {
                    style: stripeElementsStyles,
                    classes: stripeElementsClasses
                  } }
                />
                <CardCvcElement
                  options={ {
                    style: stripeElementsStyles,
                    classes: stripeElementsClasses
                  } }
                />
              </div>
            </>
          )}

          <InputField
            type="text"
            name="name"
            placeholder="Name on card"
            useStaticLabel={ true }
            required={ true }
            register={ register }
            errors={ formState?.errors?.name }
            autoComplete="cc-name"
          />
        </div>
      )
    },
    {
      id: 'paypal',
      className: 'paypal',
      title: (
        <div className="paymentoption__heading">
          <span>PayPal</span>
          <img
            className="checkout__paymentinfo__item__icon"
            src={ getWalletIcon('paypal') }
            alt="PayPal"
          />
        </div>
      )
    }
  ];

  if (paymentRequest) {
    paymentOptions.push({
      id: walletType,
      className: 'wallet',
      title: (
        <div className="paymentoption__heading">
          <span>{walletCopy(walletType)}</span>
          <img
            className="checkout__paymentinfo__item__icon"
            src={ getWalletIcon(walletType) }
            alt={ walletType }
          />
        </div>
      )
    });
  }

  if (!HIDE_AFTERPAY && isUSCart) {
    paymentOptions.push({
      id: 'afterpay',
      className: 'afterpay',
      title: (
        <div className="paymentoption__heading">
          <span>Afterpay</span>
          <img
            className="checkout__paymentinfo__item__icon"
            src={ getWalletIcon('afterpay') }
            alt="Afterpay"
          />
        </div>
      ),
      content: (
        <div className="paymentoption__inner paymentoption--afterpay">
          <AfterpayClearpayMessageElement
            options={ {
              amount: checkout?.costs?.total.value,
              currency: checkout?.costs?.total.currency,
              logoType: 'lockup',
              lockupTheme: 'black',
              introText: 'Pay',
              showWith: false,
              showInterestFree: false
            } }
          />
        </div>
      )
    });
  }

  const isDisabled =
    processing || !deliveryOptionsAvailable || !addressHasBeenEntered;

  return (

    <form onSubmit={
      handleSubmit(handlePayment)
    }
    >
      <div
        className={ `checkoutsection last ${
          activeSteps?.includes('payment') ? 'is-active' : ''
        } ${deliveryOptionsAvailable ? 'checkout__paymentinfo' : ''}` }
      >
        <div className="checkoutsection__heading">
          <span>3</span>
          <h3>Payment methods</h3>
        </div>

        {deliveryOptionsAvailable && (
          <div className="checkoutsection__inner">
            <RadioGroup
              className="paymentoptions__radiogroup"
              name="paymentoption"
              defaultCheckedId={ paymentMethod }
              items={ paymentOptions }
              onChange={ handlePaymentOptionClick }
            />

            <span className="paymentform__disclaimer">
              <Icon name="Lock" size={ 16 } />
              <span>All transactions are secure and encrypted.</span>
            </span>
          </div>
        )}
      </div>

      {bpIsLT('tabletSm') && (
        <button
          className="promocode__mobilecta"
          type="button"
          onClick={ () => dispatch(setActiveModal('mobile-cart-summary')) }
        >
          Have a promo code?
        </button>
      )}

      {deliveryOptionsAvailable && (
        <>
          <div className="mt2 mb1">
            {(paypalSelected) && (
              <PaypalOption
                onApprove={ handlePaypalSuccess }
                onCreate={ onCreate }
                onError={ handlePaypalError }
                onClick={ handlePaypalClick }
                addressHasBeenEntered={ addressHasBeenEntered }
              />
            )}

            {paymentMethod === 'afterpay' && !HIDE_AFTERPAY && isUSCart && (
              <Button
                type="submit"
                className={ `btn--icon mb1 btn-afterpay ${
                  isDisabled ? 'disabled-opacity' : ''
                }` }
                loading={ processing }
                fullWidth={ true }
              >
                <img
                  src="https://teespring-ass.s3.amazonaws.com/branded_stores/images/Afterpay_PayNow_Button_Mint-Black.png"
                  alt="afterpay logo"
                />
              </Button>
            )}

            {paymentMethod === walletType && paymentRequest && (
              <PaymentRequestButtonElement
                className={ isDisabled ? 'disabled-opacity' : '' }
                options={ options }
                onClick={ e => handleWalletPayment(e) }
              />
            )}
            {paymentMethod === 'card' && (
              <Button
                type="submit"
                style={ getStyles(`button[primary]`) || defaultBtnStyles }
                disabled={ isDisabled || !deliveryOptionsAvailable }
                icon="ArrowRight"
                fullWidth={ true }
                className={ `btn--icon mb1 ${isDisabled ? 'disabled-opacity' : ''}` }
                loading={ processing }
              >
                Place order
              </Button>
            )}
          </div>

          <p className="checkout__legalize">
            By clicking &apos;
            {legalizeCopy(paymentMethod)}
            &apos; you agree to the Seller&apos;s&nbsp;
            <Link className="typ--link" to="/privacy-policy" target="_blank">
              privacy policy
            </Link>
            &nbsp;and&nbsp;
            <Link className="typ--link" to="/terms-of-use" target="_blank">
              terms of service
            </Link>
            &nbsp;as well as the Spring&nbsp;
            <a
              className="typ--link"
              href="https://www.spri.ng/policies/spring-terms-of-service?section=privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
            &nbsp;and&nbsp;
            <a
              className="typ--link"
              href="https://www.spri.ng/policies/spring-terms-of-service?section=terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of service.
            </a>
            &nbsp;You also agree to receive periodic email updates, discounts,
            and special offers from both the Seller and Spring.
          </p>
          <div className="mt2 checkout__optin">
            <CheckboxInput
              name="checkbox-options"
              checked={ checkout?.marketingOptIn }
              onChange={ handleOptInClick }
              title={ (
                <p className="checkout__legalize">
                  By checking this box, you agree to receive marketing text
                  messages from Spring at the number provided, including
                  messages sent by autodialer. Consent is not a condition of
                  purchase. Message and data rates may apply. Message frequency
                  varies. Reply HELP for help or STOP to cancel. View our&nbsp;
                  <a
                    className="typ--link"
                    href="https://www.spri.ng/policies/spring-terms-of-service?section=privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    className="typ--link"
                    href="https://www.spri.ng/policies/spring-terms-of-service?section=sms-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  .
                </p>
              ) }
            />
          </div>
        </>
      )}
    </form>
  );
};

const {
  func, bool, arrayOf, string
} = propTypes;
PaymentForm.propTypes = {
  placeOrder: func.isRequired,
  addressHasBeenEntered: bool.isRequired,
  activeSteps: arrayOf(string).isRequired
};

export default withCookies(PaymentForm);
