/* eslint-disable prefer-const, prefer-destructuring */
import { useEffect, useState } from 'react';
import {
  string, instanceOf, shape, bool, array, number, func
} from 'prop-types';
import { Button } from '@springforcreators/propel-ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCart, fetchCartProduct, setActiveModal
} from 'redux/actions';
import get from 'lodash/get';
import tracker, { tiktok } from 'utils/tracking';
import { Cookies, withCookies } from 'react-cookie';
import { ADD_TO_CART, pushEvent } from 'utils/tracking/gtm';
import removeCurrencySymbol from 'lib';

const AddToCartButton = ({
  teespringId,
  productId,
  listingSlug,
  cookies,
  variant,
  noVariations,
  primaryProduct,
  listingProduct,
  sizeOptions,
  setShowProductOptions
}) => {
  const { stores, inventory, userCart } = useSelector(state => state);
  const [displayBtn, setDisplayBtn] = useState(true);
  const dispatch = useDispatch();
  const { itemGroupId, listingId } = listingProduct;
  const multipleSizes = sizeOptions.length > 1;

  const colorId = variant.variationId;
  const sizeId = variant?.currentSelectedSize?.id || primaryProduct[0]?.availableSizesWithId[0]?.id;
  const sku = `${itemGroupId}_${colorId}_${sizeId}`;
  const inventoryId = `${listingId}-${colorId}-${sizeId}`;

  const hasAvailableSizes = variant.availableSizesWithId.length > 0;
  const isOutOfStock = inventory[inventoryId] === 0 || !hasAvailableSizes;

  const isActive = variant && teespringId === variant.teespringId && productId === variant.productId;
  const inventoryDataReady = !!inventory.fetchingInventory;

  const handleAddToCart = ({ event, singleVariation = false }) => {
    if (event) event.preventDefault();
    if (singleVariation && variant.variationId !== primaryProduct[0].variationId) variant = primaryProduct[0];

    const productData = {
      colorId: variant.variationId,
      sizeId: variant.currentSelectedSize?.id || primaryProduct[0]?.availableSizesWithId[0]?.id,
      productId: variant.productId,
      quantity: 1,
      itemGroupId
    };

    const userCartItem = {
      [sku]: {
        ...productData,
        quantity: 1,
        sku,
        slug: listingSlug
      }
    };

    const cartData = { ...productData, slug: listingSlug, sku };
    const partnerCookie = cookies.get('_teespring_partner_attribution');

    tracker.track(
      'add_to_cart_button.quick_add_to_cart.clicked',
      {
        listingSlug,
        sku,
        colorId,
        sizeId,
        productId: variant.productId,
        itemGroupId,
        ...tiktok.trackPartnersCookieInParams(partnerCookie)
      }
    );

    const eventProduct = {
      name: get(listingProduct, 'title'),
      id: get(listingProduct, 'listingId'),
      price: removeCurrencySymbol(
        variant?.currentSelectedSize?.price
      ),
      brand: stores?.name,
      category: variant?.productType,
      variant: `Color: ${get(variant, 'color')} | Size: ${variant?.currentSelectedSize?.label}`,
      dimension8: get(stores, 'sellerId'),
      dimension9: get(listingProduct, 'listingId'),
      dimension13: get(stores, 'marketingPixels.gmcMerchantId')
    };

    if (variant.availableSizesWithId.length > 0) {
      pushEvent(ADD_TO_CART, {
        currency: variant.currency,
        sku,
        title: eventProduct.name,
        productType: variant?.productType,
        price: variant?.price,
        quantity: 1,
        sellerId: get(stores, 'sellerId'),
        storeId: stores.id,
        storeSlug: stores.slug
      });

      dispatch(fetchCartProduct(listingSlug, variant.productId));

      if (userCart[sku]) {
        dispatch(updateCart(sku, { ...userCart[sku], quantity: userCart[sku].quantity + 1 }));
      } else {
        dispatch(updateCart(sku, cartData));
      }

      dispatch(setActiveModal('quick-add-confirmation', { userCartItem }));
    } else {
      setShowProductOptions(false);
      dispatch(setActiveModal('quick-add-out-of-stock'));
    }

    setDisplayBtn(false);
  };

  useEffect(() => {
    if (isActive) {
      if (isOutOfStock || multipleSizes) {
        setDisplayBtn(true);
      } else if (noVariations) {
        setDisplayBtn(false);
      }
      if (isOutOfStock) return;
      if (noVariations) handleAddToCart({ singleVariation: true });
    }
  }, [variant, noVariations, multipleSizes]);

  if (noVariations && !isOutOfStock) {
    return null;
  }

  return (
    <>
      { isActive && displayBtn ? (
        <Button
          className={ `quick__add__base ${isOutOfStock ? 'is-disabled' : ''} ${variant?.availableSizesWithId.length > 1 ? '' : 'quick__add__full'}` }
          btnType="primary"
          type="button"
          onClick={ handleAddToCart }
          loading={ inventoryDataReady }
          fullWidth={ true }
        >
          {isOutOfStock ? 'Out of stock' : 'Add to cart'}
        </Button>
      ) : null }
    </>
  );
};

AddToCartButton.propTypes = {
  teespringId: number.isRequired,
  productId: number.isRequired,
  listingSlug: string.isRequired,
  cookies: instanceOf(Cookies).isRequired,
  list: string.isRequired,
  variant: shape({}).isRequired,
  noVariations: bool.isRequired,
  listingProduct: shape({}).isRequired,
  primaryProduct: array.isRequired,
  sizeOptions: array.isRequired,
  setShowProductOptions: func.isRequired
};

export default withCookies(AddToCartButton);
