/* eslint-disable array-callback-return, consistent-return */
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  createCheckout, setCheckoutError, updateCheckout, resetCheckout, setActiveModal, setPaymentMethod, setCheckoutLoading
} from 'redux/actions';
import { useCartItemValidation } from 'hooks';
import tracker from 'utils/tracking';
import { Button } from '@springforcreators/propel-ui';

export const CheckoutButton = (props) => {
  const {
    className,
    children,
    lineItems,
    trackingParam,
    paymentMethod,
    customStyles
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { validateCartBeforeAction } = useCartItemValidation();

  const { checkout, fulfillmentDetails } = useSelector(state => state);
  const { createCheckoutError, createCheckoutSuccess } = checkout;
  const [checkoutClicked, setCheckoutClicked] = useState(false);

  const skusWithFulfillmentDetails = items => items.map(({ sku, quantityInCart }) => {
    if (sku) {
      return {
        sku,
        quantity: quantityInCart,
        fulfillmentDetails: fulfillmentDetails[sku]
      };
    }
  });

  const proceedToCheckout = async () => {
    const checkoutId = checkout?.id;
    if (checkoutId) {
      await dispatch(updateCheckout({ lineItems: skusWithFulfillmentDetails(lineItems) }));
      await dispatch(setCheckoutError(''));
    } else {
      await dispatch(createCheckout(skusWithFulfillmentDetails(lineItems)));
    }
  };

  const handleClick = () => {
    dispatch(setPaymentMethod(paymentMethod));
    dispatch(setCheckoutLoading(true));
    setCheckoutClicked(true);
    if (trackingParam) tracker.track(trackingParam);
    if (className.includes('quick__add__btn')) {
      tracker.track('quick_add_to_cart_modal.checkout.clicked');
    }
    validateCartBeforeAction(proceedToCheckout);
  };

  useEffect(() => {
    if (createCheckoutError && checkoutClicked) {
      dispatch(resetCheckout());
      setTimeout(() => {
        setCheckoutClicked(false);
        dispatch(setActiveModal(null));
        return history.push(`/cart`);
      });
    }
    if (createCheckoutSuccess && checkoutClicked) {
      dispatch(resetCheckout());
      setTimeout(() => {
        setCheckoutClicked(false);
        dispatch(setActiveModal(null));
        return history.push(`/checkout/${checkout?.id}`);
      });
    }
  }, [createCheckoutError, createCheckoutSuccess, checkoutClicked]);

  return (
    <Button
      data-testid="springpay-checkout-button"
      type="submit"
      style={ { ...(checkoutClicked ? {} : customStyles) } }
      className={ className }
      onClick={ handleClick }
      loading={ checkoutClicked }
      disabled={ checkout?.isLoading }
      { ...props }
    >
      { children }
    </Button>
  );
};

const {
  arrayOf,
  shape,
  string,
  node
} = propTypes;

CheckoutButton.propTypes = {
  className: string.isRequired,
  children: node.isRequired,
  lineItems: arrayOf(shape({})).isRequired,
  trackingParam: string,
  paymentMethod: string,
  customStyles: shape()
};

CheckoutButton.defaultProps = {
  trackingParam: null,
  paymentMethod: 'card',
  customStyles: {}
};

export default CheckoutButton;
