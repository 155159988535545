import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { setActiveBreakpoint } from 'redux/actions';
import { initReduxBreakpoints } from 'utils/responsiveUtils';

class BpConnect extends Component {
  componentDidMount() {
    const { dispatch } = this.props;

    // Track window changes and update global breakpoint object accordingly
    initReduxBreakpoints.call(
      this, window, (breakpointName, breakpointSize, mediaQueryState) => dispatch(
        setActiveBreakpoint(breakpointName, breakpointSize, mediaQueryState)
      )
    );
  }

  render() { return <Fragment />; }
}

const {
  func
} = propTypes;
BpConnect.propTypes = {
  dispatch: func.isRequired
};

export default connect()(BpConnect);
