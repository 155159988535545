import React from 'react';
import propTypes from 'prop-types';

const DeliveryOption = ({
  id,
  label,
  price,
  estimatedDelivery,
  isSelected
}) => {
  return (
    <div
      className={ `deliveryoption ${isSelected && 'is-active'}` }
      role="radio"
      aria-checked={ isSelected }
      tabIndex="0"
      onKeyDown={ () => {} }
      id={ `delivery-option-${id}` }
    >
      <div className="deliveryoption__namedate">
        <p className="deliveryoption__name">{label}</p>
        <strong>{estimatedDelivery.deliveryDate}</strong>
      </div>
      <span className="deliveryoption__price">{`$${price.toFixed(2)}`}</span>
    </div>
  );
};

const {
  number, bool, string, shape
} = propTypes;

DeliveryOption.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  price: number.isRequired,
  estimatedDelivery: shape({}).isRequired,
  isSelected: bool.isRequired
};

export default DeliveryOption;
