import React from 'react';
import { instanceOf, bool } from 'prop-types';
import { isValidDate } from '../../utils/generalUtils';

const PhysicalDeliveryDetails = ({ shipDate, fulfilledInMexico }) => (
  <>
    <ul className="mb2">
      { isValidDate(shipDate) && (
        <li>
          Order now and your order will ship by&nbsp;
          <strong>
            { shipDate.toLocaleDateString(navigator.language || 'en-US', {
              year: 'numeric',
              day: 'numeric',
              month: 'long'
            }) }
          </strong>
        </li>
      ) }
      <li>Shipping costs will be calculated at checkout</li>
      <li>Worldwide Shipping Available</li>
      <li>Additional Shipping speed options are available at Checkout</li>
      <li>
        Products are fulfilled locally in the United States
        { fulfilledInMexico ? ', Europe and Mexico.' : ' and Europe.' }
      </li>
    </ul>
  </>
);

PhysicalDeliveryDetails.propTypes = {
  shipDate: instanceOf(Date).isRequired,
  fulfilledInMexico: bool.isRequired
};

export default PhysicalDeliveryDetails;
