import { HTTP_ERROR, CLEAR_HTTP_ERROR } from 'redux/actions';

export const initialState = {
  status: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HTTP_ERROR: {
      const newState = Object.assign({}, state, action.error);
      return newState;
    }
    case CLEAR_HTTP_ERROR: {
      return initialState;
    }
    default:
      return state;
  }
};
