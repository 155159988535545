import { Alert, Icon } from '@springforcreators/propel-ui';
import React from 'react';
import './PrivateListing.scss';

const PrivateListing = () => (
  <Alert>
    <div className="listing__content">
      <Icon name="EyeOff" size={ 30 } />
      <div>
        <p>Listing is currently set as Private</p>
        <p>This page won&apos;t be visible to anyone</p>
      </div>
    </div>
  </Alert>
);

export default PrivateListing;
