import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ThemeWrapper from 'containers/ThemeWrapper';
import propTypes from 'prop-types';
import { bpProps } from 'utils/responsiveUtils';
import './CategoryBanner.scss';

function CategoryBanner({ content, getStyles }) {
  const { categories } = content;
  if (!categories || !categories.length) return false;

  return (
    <div className="categorybanner">
      { categories.map(cat => (
        <Link
          to={ cat.slug }
          className="categorybanner__item"
          key={ cat.slug }
          style={ { backgroundImage: `url(${cat.image})` } }
        >
          <h2 style={ getStyles('categories.textStyles') }>{ cat.displayName }</h2>
        </Link>
      )) }
    </div>
  );
}

const {
  string, arrayOf, shape, func
} = propTypes;
CategoryBanner.propTypes = {
  content: shape({
    categories: arrayOf(shape({
      displayName: string,
      slug: string,
      image: string
    }))
  }).isRequired,
  getStyles: func.isRequired
};

const mapStateToProps = state => ({
  ...bpProps(state)
});

export default ThemeWrapper(connect(mapStateToProps)(CategoryBanner), ['content.categories', 'styles.categories']);
