import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@springforcreators/propel-ui';
import { string, number } from 'prop-types';
import get from 'lodash/get';
import ReturnPolicy from './ReturnPolicy';
import './PhysicalReturnDetails.scss';

const PhysicalReturnDetails = ({
  listingSlug,
  activeProductIndex
}) => {
  const { storeListings } = useSelector(state => state);
  const deliveryDetails = get(
    get(storeListings[listingSlug], `products[${activeProductIndex}]`),
    'deliveryDetails'
  );
  return (
    <>
      { deliveryDetails && deliveryDetails.type === 'physical' ? (
        <div className="physical_return__details">
          <Icon name="ShieldCheckmark" className="shield-icon" size={ 18 } color="#01BA61" />
          <div className="physical_return__content">
            <strong>30 Day Free Return Policy</strong>
            <ReturnPolicy linkText="Learn more" />
          </div>
        </div>
      ) : null}
    </>
  );
};

PhysicalReturnDetails.propTypes = {
  listingSlug: string.isRequired,
  activeProductIndex: number.isRequired
};

export default PhysicalReturnDetails;
