export const stripAffiliateCode = (affiliateCode) => {
  // The affiliate represents an affiliate code.
  // An affiliate code is a string that contains only
  // letters, numbers or a the - character.
  // This function replaces every invalid character to a ''
  return affiliateCode.replace(/[^a-zA-Z0-9-]+/g, '');
};

export const trackAffiliateIn = (cookies, affiliateCode) => {
  const strippedAffiliateCode = stripAffiliateCode(affiliateCode);

  if (strippedAffiliateCode) {
    cookies.set('affiliate', strippedAffiliateCode, { maxAge: 60 * 60 * 24 * 7 }); //  1 week
  }
};
