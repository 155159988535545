import React from 'react';
import { connect } from 'react-redux';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import { number, object } from 'prop-types';
import get from 'lodash/get';
import some from 'lodash/some';

export const ProductDetails = ({ productId, productDetails }) => {
  const { themeData } = useGlobalProps();
  const { layout } = themeData;
  const details = get(productDetails, productId, {});
  const validFields = ['description', 'materials', 'fit'];
  const hasDetails = some(validFields, key => details[key]);
  const showProductDetailsSection = {
    comparableBrands: get(
      layout,
      'listingDetails.showProductDetailsSection.comparableBrands',
      false
    ),
    description: get(
      layout,
      'listingDetails.showProductDetailsSection.description',
      true
    ),
    fit: get(layout, 'listingDetails.showProductDetailsSection.fit', false),
    materials: get(
      layout,
      'listingDetails.showProductDetailsSection.materials',
      true
    )
  };

  return (
    <>
      {details && hasDetails && (
        <>
          {showProductDetailsSection.description && details.description && (
            <div className="mb2">
              <p>{details.description}</p>
            </div>
          )}

          {showProductDetailsSection.fit && details.fit && (
            <div className="mb2">
              <span className="form__label">Fit</span>
              <p>{details.fit}</p>
            </div>
          )}

          {showProductDetailsSection.materials && details.materials && (
            <div className="mb2">
              <span className="form__label">Materials</span>
              <p>{details.materials}</p>
            </div>
          )}

          {showProductDetailsSection.comparableBrands
            && details.comparableBrands && (
            <div className="mb2">
              <span className="form__label">Comparable brands</span>
              <p>{details.comparableBrands}</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ storeListings }) => {
  return {
    productDetails: storeListings.productDetails
  };
};

ProductDetails.propTypes = {
  productDetails: object.isRequired,
  productId: number.isRequired
};

export default connect(mapStateToProps)(ProductDetails);
