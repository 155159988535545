import { useSelector } from 'react-redux';
import { listingSlugFromUrl } from '../utils/generalUtils';

const useRelatedProductsTo = (products) => {
  const storeProducts = useSelector(state => state?.storeProducts);
  return storeProducts.products.filter(
    (storeProduct) => {
      const { url } = storeProduct;
      const productSlug = listingSlugFromUrl(url);
      return products.every(product => product.url !== productSlug);
    }
  );
};

export default useRelatedProductsTo;
