import { convertToDollars } from './moneyUtils';

const formatDeliveryDate = date => date.toLocaleString('default', { month: 'short', day: 'numeric' });

export const getDateEstimates = (deliveryEstimates) => {
  return {
    arrivalDate: `between ${formatDeliveryDate(
      new Date(deliveryEstimates.early)
    )} - ${formatDeliveryDate(new Date(deliveryEstimates.late))}`,
    deliveryDate: `Delivery between ${formatDeliveryDate(
      new Date(deliveryEstimates.early)
    )} - ${formatDeliveryDate(new Date(deliveryEstimates.late))}`
  };
};

const buildDeliveryId = (name) => {
  switch (name) {
    case 'rush': {
      return 'fullRushCost';
    }
    case 'super_rush': {
      return 'fullSuperRushCost';
    }
    default: {
      return 'fullStandardCost';
    }
  }
};

export const buildDeliveryOptions = (quotes) => {
  return quotes.map((quote) => {
    return {
      id: buildDeliveryId(quote.name),
      label: `${quote.name.replace('_', ' ').replace(/^\w/, c => c.toUpperCase())} delivery`,
      price: convertToDollars(quote.cost.amount),
      estimatedDelivery: getDateEstimates(
        quote.deliveryEstimates
      )
    };
  });
};
