import find from 'lodash/find';
import { v4 } from 'uuid';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const TOAST_DURATION = 3000; // 3 seconds
export const TOAST_POSITION = 'top-right';

export const removeToast = id => (dispatch, getState) => {
  const { notifications } = getState().toast;
  dispatch({
    type: REMOVE_TOAST,
    payload: [...notifications.filter(notification => notification.id !== id)]
  });
};

export const clearToast = () => {
  return {
    type: REMOVE_TOAST,
    payload: []
  };
};

export const addToast = (toastMessage, type = 'danger', options = { timeout: TOAST_DURATION, position: TOAST_POSITION }) => (dispatch, getState) => {
  const toasts = getState().toast.notifications;
  if (!find(toasts, toast => toast.message === toastMessage)) {
    const toast = {
      id: v4(),
      message: toastMessage,
      type,
      options
    };

    dispatch({
      type: ADD_TOAST,
      toast
    });
  }
};
