import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from '@springforcreators/propel-ui';
import { bpProps } from 'utils/responsiveUtils';
import { connect } from 'react-redux';
import ThemeButton from 'components/ThemeButton';
import SupportSearch from './SupportSearch';
import { useSupport } from './hooks/useSupport';
import './Support.scss';

export const SupportPage = (props) => {
  const { setClass } = props;
  const { categories } = useSupport();

  return (
    <div>
      <h1 className={ `support__title typ--center ${setClass({ mobileLg: 'typ--h3' })}` }>Support topics</h1>

      <SupportSearch />

      <div className="support__actions">
        { categories.map(category => (
          <div key={ category.databaseId }>
            <h3 className="support__action__title">
              <Icon name={ category.icon } />
              &nbsp;
              { category.name }
            </h3>

            <ul className="support__action__items">
              { category.posts.map(post => (
                <li key={ post.databaseId }>
                  <Link to={ `/support/${post.slug}` }>
                    <Icon name="ChevronRight" size={ 16 } />
                    { post.title }
                  </Link>
                </li>
              )) }
            </ul>
          </div>
        )) }
      </div>

      <div className="support__footer">
        <div>
          <h2 className={ `mb1 ${setClass({ mobileLg: 'typ--h5' })}` }>Where is my order?</h2>
          <p className="mb2">Use your order number to get the status of your order.</p>

          <ThemeButton
            className="btn--icon"
            link="/track"
            elemType="routerLink"
            btnType="primary"
          >
            Track order
            <Icon name="ArrowRight" size={ 16 } />
          </ThemeButton>
        </div>

        <div>
          <h2 className={ `mb1 ${setClass({ mobileLg: 'typ--h5' })}` }>Contact support</h2>
          <p className="mb2">Contact a member of our Fan experience team.</p>

          <div className="support__footer__btngroup">
            <ThemeButton
              className="btn--icon"
              link="/live-chat"
              elemType="routerLink"
              btnType="primary"
            >
              Live chat (Online now)
              <Icon name="ArrowRight" size={ 16 } />
            </ThemeButton>
            <span>Available 7 days a week</span>
          </div>

          <div className="support__footer__btngroup">
            <ThemeButton
              className="btn--icon"
              link="/contact"
              elemType="routerLink"
              btnType="secondary"
            >
              Email ticket
              <Icon name="ArrowRight" size={ 16 } />
            </ThemeButton>
            <span>Reply within 24 business hours</span>
          </div>
        </div>

      </div>
    </div>
  );
};

const { func } = propTypes;
SupportPage.propTypes = {
  setClass: func.isRequired
};

const mapStateToProps = state => ({
  ...bpProps(state)
});

export default connect(mapStateToProps)(SupportPage);
