import React, { useState, useEffect } from 'react';
import './PromotionalBannerItem.scss';
import propTypes from 'prop-types';
import tracker from 'utils/tracking';

const PromotionalBannerItem = ({ promoCode }) => {
  const COPIED_TEXT = 'Copied!';
  const FREE_SHIPPING = 'Free Shipping on your order';
  const PERCENTAGE_DISCOUNT = 'percentage';
  const FIXED_VALUE_DISCOUNT = 'fixed_value';

  const [copySuccess, setCopySuccess] = useState(false);
  const [buttonText, setButtonText] = useState(null);

  const copyCodeToClipboard = () => {
    tracker.track('whitelabel.promo_banner.clicked');
    navigator.clipboard.writeText(promoCode.code);
    setCopySuccess(true);
  };

  useEffect(() => {
    let intervalID = null;
    if (copySuccess) {
      intervalID = setTimeout(() => {
        setButtonText(promoCode.code);
        setCopySuccess(false);
      }, 3000);
      setButtonText(COPIED_TEXT);
    }

    return () => clearInterval(intervalID);
  }, [copySuccess]);

  useEffect(() => {
    setButtonText(promoCode.code);
    setCopySuccess(false);
  }, [promoCode]);

  const getDiscount = (discountObject) => {
    switch (discountObject.type) {
      case PERCENTAGE_DISCOUNT:
        return `${discountObject.value}% off your order`;
      case FIXED_VALUE_DISCOUNT:
        return `$${discountObject.value.amount / 100} off your order`;
      default:
        return FREE_SHIPPING;
    }
  };

  return (
    <div key={ `${promoCode.code}` } className="promocode_container animated slideInDown">
      <p className="promocode_content">Use code:</p>
      <button
        type="button"
        className="promocode_content promocode_button"
        onClick={ () => copyCodeToClipboard() }
      >
        { buttonText }
      </button>
      <p className="promocode_content hide_in_mobile">at checkout</p>
      <p className="promocode_content">&nbsp;for&nbsp;</p>
      <p className="promocode_content underline">
        {getDiscount(promoCode)}
      </p>
    </div>
  );
};

const { object } = propTypes;

PromotionalBannerItem.propTypes = {
  promoCode: object.isRequired
};

export default PromotionalBannerItem;
