/* eslint no-use-before-define: 2 */

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import propTypes from 'prop-types';

function withTracker(WrappedComponent, passedProps) {
  const BaseTheme = (props) => {
    const { location } = props;
    useEffect(() => {
      ReactGA.pageview(location.pathname, ['default', 'client']);
    }, [location.pathname]);

    return (
      <WrappedComponent { ...props } { ...passedProps } />
    );
  };

  const { shape, string } = propTypes;
  BaseTheme.propTypes = {
    location: shape({ pathname: string }).isRequired
  };

  return BaseTheme;
}

export default withTracker;
