import { SET_LISTING_INVENTORY, SET_FETCHING_INVENTORY } from 'redux/actions';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LISTING_INVENTORY:
      return {
        ...state,
        [action.key]: action.inventoryCount,
        fetchingInventory: action.fetchingInventory
      };

    case SET_FETCHING_INVENTORY:
      return {
        ...state,
        fetchingInventory: action.fetchingInventory
      };
    default:
      return state;
  }
};
