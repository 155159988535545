import React from 'react';
import MainLayout from 'containers/MainLayout';
import { Breadcrumbs } from '@springforcreators/propel-ui';
import { bpProps } from 'utils/responsiveUtils';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link, Redirect } from 'react-router-dom';
import { useSupport } from '../hooks/useSupport';
import SupportSidenav from './SupportSidenav';
import './SupportWrapper.scss';

function SupportWrapper(props) {
  const {
    storeData,
    location,
    children,
    showSidenav,
    page,
    bpIsGT,
    showGetSupport
  } = props;

  const { activePost } = useSupport();
  const activeCategory = activePost ? activePost.categories[0] : '';

  const crumbs = [];

  if (activeCategory || activePost || page) {
    crumbs.push({
      id: 'support',
      label: 'Support',
      path: '/support'
    });
  }

  if (activeCategory) {
    crumbs.push(
      {
        id: get(activeCategory, 'databaseId'),
        label: get(activeCategory, 'name')
      }
    );
  }

  if (bpIsGT('mobileLg') && activePost) {
    crumbs.push(
      {
        id: get(activePost, 'databaseId'),
        label: get(activePost, 'title'),
        path: `/support/${get(activePost, 'slug')}`
      }
    );
  }

  if (page) crumbs.push(page);

  if (!process.env.REACT_APP_SHOW_SUPPORT) return <Redirect to="/" />;

  return (
    <MainLayout
      location={ location }
      storeData={ storeData }
    >
      <div className="support content">
        <div className="row">
          <div className="support__header">
            { crumbs.length > 0 && (
              <Breadcrumbs
                crumbs={ crumbs }
                activeCrumbId={ get(activePost, 'databaseId') || get(page, 'id') }
              />
            ) }

            { (showGetSupport && bpIsGT('mobileLg')) && (
              <div className="support__callout">
                <Link className="typ--link" to="/">
                  Get support
                </Link>
                &nbsp;as a creator
              </div>
            ) }
          </div>
        </div>

        <div className="row">
          { showSidenav && (
            <aside className="support__aside">
              <SupportSidenav />
            </aside>
          ) }
          <main className="support__main">{ children }</main>
        </div>
      </div>
    </MainLayout>
  );
}

const {
  shape, node, bool, func, string
} = propTypes;
SupportWrapper.propTypes = {
  location: shape({}).isRequired,
  storeData: shape({}).isRequired,
  children: node.isRequired,
  showSidenav: bool.isRequired,
  bpIsGT: func.isRequired,
  page: shape({
    id: string,
    label: string
  }),
  showGetSupport: bool.isRequired
};

SupportWrapper.defaultProps = {
  page: undefined
};

const mapStateToProps = state => ({
  ...bpProps(state)
});

export default connect(mapStateToProps)(SupportWrapper);
