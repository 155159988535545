/* eslint-disable operator-linebreak */
/* eslint-disable import/no-cycle, camelcase, no-console */
import fetch from 'cross-fetch';
import { getLocalizationDetails, defaultLocalization, getAvailableLocations } from 'utils/localizationUtils';
import { requestWithRetry } from 'utils/requestUtils';
import isEmpty from 'lodash/isEmpty';
import { fetchProductCache, fetchProducts } from './products';
import { API_HOST, PAYMENT_HOST } from '../../constants';
import store from '../../store';

export const SET_BUYER_LOCALIZATION = 'SET_BUYER_LOCALIZATION';
export const LOAD_CACHED_BUYER_LOCALIZATION = 'LOAD_CACHED_BUYER_LOCALIZATION';

export const RECEIVE_STORE = 'RECEIVE_STORE';
export const REQUEST_STORE = 'REQUEST_STORE';

export const RECEIVE_COLLECTION = 'RECEIVE_COLLECTION';
export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS';
export const REQUEST_COLLECTION = 'REQUEST_COLLECTION';

export const IS_FETCHING_DATA = 'IS_FETCHING_DATA';
export const SET_THEME_DATA = 'SET_THEME_DATA';
export const SET_INIT_THEME_DATA = 'SET_INIT_THEME_DATA';

// Shopping Cart Actions
export const UPDATE_CART = 'UPDATE_CART';
export const DESTROY_CART_ITEM = 'DESTROY_CART_ITEM';
export const LOAD_FROM_PERSISTENT_CART = 'LOAD_FROM_PERSISTENT_CART';
export const DESTROY_CART = 'DESTROY_CART';

export const UPDATE_FULFILLMENT_DETAILS = 'UPDATE_FULFILLMENT_DETAILS';

// Order actions
export const ORDER_REQUEST_SUCCESS = 'ORDER_REQUEST_SUCCESS';
export const ORDER_REQUEST_FAILED = 'ORDER_REQUEST_FAILED';

export const HTTP_ERROR = 'HTTP_ERROR';
export const CLEAR_HTTP_ERROR = 'CLEAR_HTTP_ERROR';
export const SET_GET_ALL_VISIBILITY = 'SET_GET_ALL_VISIBILITY';
export const SET_PREVIEW_MODE = 'SET_PREVIEW_MODE';
export * from './inventory';

export const LEGACY_API_HOST = process.env.REACT_APP_LEGACY_BACKEND_HOST
  ? process.env.REACT_APP_LEGACY_BACKEND_HOST
  : 'https://teespring.com/api';

// Other actions
export * from './listings';
export * from './products';
export * from './checkout';
export * from './deliveryOptions';
export * from './toast';

export const setBuyerLocalization = (data) => {
  return {
    type: SET_BUYER_LOCALIZATION,
    data
  };
};

export const loadCachedBuyerLocalization = () => {
  return {
    type: LOAD_CACHED_BUYER_LOCALIZATION
  };
};

export function fetchBuyerLocalization() {
  const dataUrl = `${LEGACY_API_HOST}/v1/localization_details?storeId=${store.slug}`;
  return (dispatch) => {
    return fetch(dataUrl, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(
        response => response.json(),
        (error) => {
          console.error(error);
          dispatch(setBuyerLocalization(defaultLocalization));
        }
      )
      .then((data) => {
        dispatch(setBuyerLocalization(data));
      });
  };
}

export function getBuyerLocalization() {
  return (dispatch) => {
    const localizationDetails = getLocalizationDetails();
    const availableLocations = getAvailableLocations();

    return isEmpty(localizationDetails) || isEmpty(availableLocations)
      ? dispatch(fetchBuyerLocalization())
      : dispatch(loadCachedBuyerLocalization());
  };
}

/**
 * Set app request state to show loader etc.
 * @return {function}   The dispatch function
 */
export const setIsFetching = () => (dispatch, isFetchingData = false) => {
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData
  });
};

/**
 * Starting store request
 * @returns { func } A dispatch function
 */
export const requestStore = () => (dispatch) => {
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData: true
  });
};

/**
 * Finishing request for collections
 * @return {function}           The dispatch function
 * @param {object} collections The collections data for the store
 */
export const receiveCollections = collections => (dispatch) => {
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData: false
  });

  dispatch({
    type: RECEIVE_COLLECTIONS,
    collections
  });
};

/**
 * Get collections data for a store
 * @param  {string}  storeSlug      The slug of the store
 * @return {function}           The dispatch function
 */
export function fetchCollections(storeSlug) {
  return (dispatch) => {
    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    //TODO: Adding "per" parameter as a hotfix for no pagination on collections.  This needs a better fix and to be removed.
    return fetch(`${API_HOST}/v1/stores/collections?slug=${storeSlug}`, {
      headers: { Accept: 'application/json' }
    })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        (error) => {
          throw new Error(`An error occured: ${error}`);
        }
      )
      .then((collectionsData) => {
        dispatch(receiveCollections(collectionsData.collections));
      });
  };
}

/**
 * Finished store request
 * @param  {string} storeUrl The id from the url of the store to fetch
 * @param  {object} data    The store data
 * @return {func}           The dispatch function
 */
export const receiveStore = (storeUrl, data) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData: false
  });

  dispatch({
    type: RECEIVE_STORE,
    storeUrl,
    storeData: data
  });
};

/**
 * Starting request for collections
 * @param  {Boolean}  lazyLoad  Bool to decide if these should load in the background
 * @return {function}           The dispatch function
 */
export const requestCollection = (lazyLoad = false) => (dispatch) => {
  const isFetchingData = !lazyLoad;
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData
  });
};

/**
 * Finished collection request and received collections
 * @param  {Object} storeProductsCollection The store products for a particular collection returned from the collection API
 * @param  {string} collectionSlug  The name of the collection received
 * @return {function}             The dispatch function
 */
export const receiveCollection = (storeProductsCollection, collectionSlug) => (
  dispatch
) => {
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData: false
  });

  dispatch({
    type: RECEIVE_COLLECTION,
    storeCollections: storeProductsCollection,
    collectionID: collectionSlug
  });
};

/**
 * Adds / updates item to the shopping cart
 * @param  {string} sku     Generated product sku
 * @param  {Object} data   The items data and quantity
 * @return {Function}         Dispatch function
 */
export const updateCart = (sku, data) => (dispatch, getState) => {
  const { slug } = getState().stores;
  dispatch({
    type: UPDATE_CART,
    storeId: slug,
    sku,
    userCart: data
  });
};

export const updateFulfillmentDetails = (sku, data) => (dispatch) => {
  dispatch({
    type: UPDATE_FULFILLMENT_DETAILS,
    sku,
    data
  });
};

/**
 * Removes item (entire quantity) from cart
 * @param  {string} storeId The Teespring store id
 * @param  {string} sku    The generated sku
 * @return {Function}         Dispatch function
 */
export const removeFromCart = (storeId, sku) => ({
  type: DESTROY_CART_ITEM,
  storeId,
  sku
});

/**
 * Loads the cart from the users browser storage
 * @param  {string} storeId The Teespring store id
 * @return {Function}         Dispatch function
 */
export const loadPersistentCart = storeId => (dispatch) => {
  dispatch({
    type: LOAD_FROM_PERSISTENT_CART,
    storeId
  });
};

/**
 * Removes all items from cart in redux store and in localStorage
 * @param  {string} storeId The Teespring store id
 * @return {Function}         Dispatch function
 */
export const destroyCart = storeId => (dispatch) => {
  dispatch({
    type: DESTROY_CART,
    storeId
  });
};

/**
 * Get the initial store data from store API
 * @param  {string} storeId The id of the store
 * @return {function}       The dispatch function
 */
export function fetchStore(storeId) {
  return (dispatch) => {
    // First dispatch: the app state is updated to inform
    // that the API call is starting.
    dispatch(requestStore(storeId));
    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    return fetch(`${API_HOST}/v1/stores?slug=${storeId}`, {
      headers: { Accept: 'application/json' }
    })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        (error) => {
          throw new Error(`An error occured: ${error}`);
        }
      )
      .then((json) => {
        // If we encounter an inactive store or a non existant store, redirect to global 404 page
        if (json.errors?.store?.includes('not found')) {
          window.location.href = 'https://creator-spring.com/404';
        }

        // We can dispatch many times!
        // Here, we update the app state with the results of the API call.
        dispatch(receiveStore(storeId, json));
      });
  };
}

/**
 * Get store data and then product data
 * @param  {string} storeId          The id of the store
 * @return {function}                The dispatch function
 * TODO: this could be done better without chaining as
 * we don't need the store to get the products
 */
export function fetchStoreData(storeId) {
  return (dispatch, getState) => {
    const { productCacheEnabled } = getState().storeListings;
    const fetchProductsFunc = productCacheEnabled
      ? fetchProductCache
      : fetchProducts;

    dispatch(fetchStore(storeId))
      .then(() => {
        return dispatch(fetchCollections(storeId));
      })
      .then(() => {
        return dispatch(fetchProductsFunc(1));
      });
  };
}

/**
 * Get collection data
 * @param  {string}  storeSlug      The slug of the store
 * @param  {string}  collectionSlug The slug of the collection to fetch
 * @param  {Boolean} lazyLoad     Bool to determine if we should load collections in the background
 * @param  {Object} localizationData Object containing user locale details
 * @return {function}             The dispatch function
 */
export function fetchCollection(
  storeSlug,
  collectionSlug,
  lazyLoad = false,
  localizationData
) {
  return (dispatch, getState) => {
    const { previewMode } = getState().themeData;
    // First dispatch: the app state is updated to inform
    // that the API call is starting.

    dispatch(requestCollection(lazyLoad));

    const { buyer_region, buyer_currency } = localizationData;
    let url = `${API_HOST}/v1/stores/products?collection=${collectionSlug}&slug=${storeSlug}&currency=${buyer_currency}&region=${buyer_region}&per=150`;

    if (previewMode) url += `&visibility=any`;
    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    //TODO: Adding "per" parameter as a hotfix for no pagination on collections.  This needs a better fix and to be removed.
    return fetch(url, { headers: { Accept: 'application/json' } })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        (error) => {
          throw new Error(`An error occured: ${error}`);
        }
      )
      .then(storeProductsPayload => dispatch(receiveCollection(storeProductsPayload, collectionSlug)));
  };
}

export const receiveThemeData = data => ({
  type: SET_THEME_DATA,
  data
});

export const receiveInitThemeData = data => ({
  type: SET_INIT_THEME_DATA,
  data
});

/**
 * Changes the active breakpoint
 *,
 * @param {string} breakpointName          String defining the active breakpoint
 * @param {number} breakpointSize          Number defining the active breakpoint
 * @return {Object} Action object
 */
export const setActiveBreakpoint = (breakpointName, breakpointSize) => ({
  type: 'SET_ACTIVE_BREAKPOINT',
  breakpointName,
  breakpointSize
});

/**
 * Sets the active modal
 *
 * @param {string} modalId - String identifier for modal
 * @param {Object} props - Optional props object that gets injected into target modal
 * @return {Object} Action object
 */
export const setActiveModal = (modalId, props = {}) => ({
  type: 'SET_ACTIVE_MODAL',
  modalId,
  props
});

/**
 * Sets the order data
 *
 * @param {Object} data - Props object with the order data
 * @return {Object} Action object
 */
export const orderRequestSuccess = data => ({
  type: ORDER_REQUEST_SUCCESS,
  data
});

/**
 * Sets the order failure
 *
 * @return {Object} Action object
 */
export const orderRequestFailure = () => ({
  type: ORDER_REQUEST_FAILED
});

/**
 * Sends the purchase events when someone places an order
 *
 * @param {number} cartId - Number identifier for the orders
 * @return {function} The dispatch function
 */
export const fetchOrder = cartId => async (dispatch) => {
  try {
    const url = `${API_HOST}/v1/orders?cartId=${cartId}`;

    const res = await requestWithRetry({
      url,
      options: {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      },
      requestName: 'fetchOrder'
    });

    dispatch(orderRequestSuccess(res));
  } catch (err) {
    dispatch(orderRequestFailure());
  }
};

export const setPreviewMode = () => ({
  type: SET_PREVIEW_MODE
});

export const clearHttpError = () => ({
  type: CLEAR_HTTP_ERROR
});

/**
 * Updates global theme data
 * @param {Object} data Data object defining current theme parameters
 * @param {bool} init   Determines whether to store this theme data in the initThemeData key
 * @return {Object}     Action object
 */
export function setThemeData(data, init = false) {
  return (dispatch, getState) => {
    const { themeData } = getState();
    dispatch(receiveThemeData(data));
    if (init) dispatch(receiveInitThemeData(data));
    if (data?.preview && !themeData?.previewMode) dispatch(setPreviewMode());
  };
}

export const fetchAppleVerification = () => async () => {
  try {
    await fetch(`${PAYMENT_HOST}/v1/payments/verifyDomain`, {
      method: 'POST',
      body: JSON.stringify({
        domainName: window.location.host
      })
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error: Apple domain registration failed');
  }
};
