import { isNFTListing } from 'utils/cartUtils';
import { API_HOST, AVAILABILITY_HOST, AVAILABILITY_API_KEY } from '../../constants/index';

export const SET_LISTING_INVENTORY = 'SET_LISTING_INVENTORY';
export const SET_FETCHING_INVENTORY = 'SET_FETCHING_INVENTORY';

/**
 * Gets inventory count for an individual listing based on a color and size id combination
 * @param {number} colorId  The color id
 * @param {number} sizeId   The size id
 * @param {Object} listing  Listing object
 * @return {function}     The dispatch function
 */
export const fetchListingInventoryCount = (colorId, sizeId, listing) => async (dispatch, getState) => {
  const { stores } = getState(state => state.stores);
  const { inventory } = getState(state => state.inventory);
  const key = `${listing?.listingId}-${colorId}-${sizeId}`;
  const sellerToken = stores?.sellerToken;
  let productInventoryCount;

  if (inventory[key] === undefined) {
    dispatch({ type: SET_FETCHING_INVENTORY, fetchingInventory: true });
  } else {
    return;
  }

  if (isNFTListing(listing)) {
    try {
      const [primaryProduct] = listing?.primaryProduct;
      const listingRootId = primaryProduct ? primaryProduct?.teespringId : listing?.teespringId;

      // If the product is an NFT, hit the availability endpoint
      const url = `${AVAILABILITY_HOST}/v1/availability?userToken=${sellerToken}&listingId=${listingRootId}`;
      const res = await fetch(url, {
        method: 'GET',
        headers: { 'x-api-key': AVAILABILITY_API_KEY }
      });

      if (res.ok) {
        const { count } = await res.json();
        productInventoryCount = count;
      }
    } catch (error) {
      productInventoryCount = 0;
    }
  } else {
    try {
      const url = `${API_HOST}/v1/inventory?colorId=${colorId}&sizeId=${sizeId}`;
      const response = await fetch(url);

      const { inventoryCount } = await response.json();
      productInventoryCount = inventoryCount;
    } catch (error) {
      throw new Error(`An error occured ${error.message}`);
    }
  }

  dispatch({
    type: SET_LISTING_INVENTORY,
    key,
    inventoryCount: productInventoryCount,
    fetchingInventory: false
  });
};
