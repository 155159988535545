import { SET_INIT_THEME_DATA } from 'redux/actions';

/**
 * Updates the theme data
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
export default (state = {}, action) => {
  switch (action.type) {
    case SET_INIT_THEME_DATA:
      return action.data;
    default:
      return state;
  }
};
