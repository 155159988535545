/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  func, number, shape, string
} from 'prop-types';
import { bpProps } from 'utils/responsiveUtils';
import AddToCartButton from 'components/ProductTile/ProductOptions/AddToCartButton';
import ColorOptions from 'components/ProductTile/ProductOptions/ColorOptions';
import SizeOptions from 'components/ProductTile/ProductOptions/SizeOptions';
import ProductOptionsModal from './ProductOptionsModal';

import './ProductOptions.scss';
import { fetchListingInventoryCount } from '../../../redux/actions';

const ProductOptions = ({
  listingSlug,
  productId,
  onColorChange,
  list,
  setShowProductOptions,
  listingDetails
}) => {
  const dispatch = useDispatch();
  const { bpIsLT, bpIsGT } = useSelector(state => ({ ...bpProps(state) }));
  const { primaryProduct, variations, variant } = listingDetails;
  const [selectedVariant, setSelectedVariant] = useState(variant ?? primaryProduct[0]);
  const sizeOptions = selectedVariant?.availableSizesWithId ?? [];

  const selectSize = (availableSizesWithId, sizeId) => {
    let selectedSize;
    const defaultSize = availableSizesWithId[0];
    if (sizeId) {
      selectedSize = availableSizesWithId.find(size => size.id === sizeId);
    }
    return selectedSize ?? defaultSize;
  };

  const colorOptions = primaryProduct ?? variations;
  const defaultColor = colorOptions?.[0];
  const teespringId = defaultColor?.teespringId;
  const noVariations = selectedVariant?.availableSizesWithId?.length <= 1 && primaryProduct.length <= 1;
  const noColorVariations = primaryProduct?.length <= 1;

  const setColorOption = async (variantWithNewColor) => {
    const currentSelectedSize = selectSize(variantWithNewColor.availableSizesWithId, selectedVariant?.currentSelectedSize?.id);
    const newVariant = { ...variantWithNewColor, currentSelectedSize };
    const hasAvailableSizes = newVariant.availableSizesWithId.length > 0;

    if (hasAvailableSizes) {
      await dispatch(
        fetchListingInventoryCount(
          newVariant.variationId,
          currentSelectedSize.id,
          listingDetails
        )
      );
    }
    setSelectedVariant(newVariant);
    onColorChange(newVariant);
  };
  const setSizeOption = async (newSize) => {
    const currentSelectedSize = selectSize(selectedVariant?.availableSizesWithId, newSize?.id);
    const newVariant = { ...selectedVariant, currentSelectedSize };
    const hasAvailableSizes = newVariant.availableSizesWithId.length > 0;

    if (hasAvailableSizes) {
      await dispatch(
        fetchListingInventoryCount(
          newVariant.variationId,
          currentSelectedSize.id,
          listingDetails
        )
      );
    }
    setSelectedVariant(newVariant);
  };

  const colorOptionsEl = (
    <ColorOptions
      productId={ productId }
      listingSlug={ listingSlug }
      colorOptions={ colorOptions }
      onChangeHandler={ setColorOption }
    />
  );
  const sizeOptionsEl = (
    <SizeOptions
      listingSlug={ listingSlug }
      onChangeHandler={ setSizeOption }
      sizeOptions={ sizeOptions }
    />
  );
  const addToCartButtonEl = (
    <AddToCartButton
      teespringId={ teespringId }
      productId={ productId }
      listingSlug={ listingSlug }
      list={ list }
      variant={ selectedVariant }
      noVariations={ noVariations }
      noColorVariations={ noColorVariations }
      sizeOptions={ sizeOptions }
      listingProduct={ listingDetails }
      primaryProduct={ primaryProduct }
      setShowProductOptions={ setShowProductOptions }
    />
  );
  return (
    <div onClick={ e => e.preventDefault() }>
      {bpIsGT('mobileLg') && colorOptionsEl }

      <div className="product__options">
        {bpIsLT('mobileLg') ? (
          <ProductOptionsModal
            colorOptions={ colorOptionsEl }
            sizeOptions={ sizeOptionsEl }
            addToCartButton={ addToCartButtonEl }
            setShowProductOptions={ setShowProductOptions }
          />
        ) : (
          <>
            <div className="add__options">
              { sizeOptionsEl }
              { addToCartButtonEl }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ProductOptions.propTypes = {
  productId: number.isRequired,
  listingSlug: string.isRequired,
  onColorChange: func.isRequired,
  list: string.isRequired,
  setShowProductOptions: func.isRequired,
  listingDetails: shape({}).isRequired
};

export default ProductOptions;
