import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FlyOutMenu from 'components/FlyOutMenu';
import ThemeWrapper from 'containers/ThemeWrapper';
import tracker from 'utils/tracking';
import { isExternalUrl } from 'utils/generalUtils';
import { bpProps } from 'utils/responsiveUtils';
import { connect } from 'react-redux';
import './MenuItem.scss';

const MenuItem = ({ item, getStyles, isSelected }) => {
  const [isOpen, setMenuOpen] = useState(false);
  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  const menuItemStyles = getStyles('header.menuItem') || {};
  const { pathname } = window.location;
  const trackCollectionClicked = (label, url) => {
    tracker.track(
      'header.nav_item.clicked',
      {
        navItemLabel: label,
        navIemUrl: url,
        navItemType: 'collection'
      }
    );
  };

  const trackExternalUrlClicked = (label, url) => {
    tracker.track(
      'header.nav_item.clicked',
      {
        navItemLabel: label,
        navIemUrl: url,
        navItemType: 'external_url'
      }
    );
  };

  const generateLink = (menuItem) => {
    if (isExternalUrl(menuItem.slug)) {
      return (
        <a
          href={ menuItem.slug }
          target="_blank"
          rel="noopener noreferrer"
          style={ menuItemStyles }
          onClick={ () => trackExternalUrlClicked(menuItem.name, menuItem.slug) }
        >
          { menuItem.name }
        </a>
      );
    } else {
      return (
        <Link
          className={ pathname === `/${menuItem.slug}` ? 'is-active' : '' }
          onClick={ () => trackCollectionClicked(menuItem.name, menuItem.slug) }
          style={ menuItemStyles }
          to={ `/${menuItem.slug}` }
        >
          { menuItem.name }
        </Link>
      );
    }
  };

  function singleMenuItem(menuItem) {
    return (
      <li className={ `menu-item ${ isSelected ? 'selected-menu-item' : '' }` }>
        { generateLink(menuItem) }
      </li>
    );
  }

  function menuItemWithFlyout(menuItem) {
    return (
      <li
        className={ `menu-item has-sub-menu ${ isSelected ? 'selected-menu-item' : '' }` }
        onMouseOver={ openMenu }
        onMouseOut={ closeMenu }
        onFocus={ openMenu }
        onBlur={ closeMenu }
      >
        { generateLink(menuItem) }
        <div className="menu-flyout-bridge" />
        <FlyOutMenu
          menuItems={ menuItem.collections }
          isOpen={ isOpen }
          onFocusBlur={ closeMenu }
        />
      </li>
    );
  }

  return item.collections && item.collections.length ? menuItemWithFlyout(item) : singleMenuItem(item);
};

const mapStateToProps = state => ({
  ...bpProps(state)
});

export default ThemeWrapper(connect(mapStateToProps)(MenuItem), 'styles.header');
