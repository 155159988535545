import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchListingProducts } from 'redux/actions';
import {
  bool, func, string, shape
} from 'prop-types';
import { bpProps } from 'utils/responsiveUtils';
import map from 'lodash/map';
import { Loader, Button, Carousel } from '@springforcreators/propel-ui';
import ProductTile from 'components/ProductTile';
import './Products.scss';

class Products extends Component {
  componentDidMount() {
    const {
      fetchListingProducts: localFetchListingProducts,
      localizationData,
      listingSlug,
      storeSlug
    } = this.props;

    // Make sure listing products are up to date, this is async, but
    // await-ing here serves no purpose
    localFetchListingProducts(listingSlug, storeSlug, localizationData);
  }

  createSlides = (products) => {
    const { handleProductChange } = this.props;
    return map(products, (product, i) => {
      return {
        id: product.productId,
        content: (
          <ProductTile
            product={ product }
            list="Other Products"
            position={ i }
            disableQuickAdd={ true }
            onClick={ () => handleProductChange(i) }
            showListingTitle={ false }
            showProductType={ true }
          />
        )
      };
    });
  };

  render() {
    const {
      storeListings,
      listingSlug,
      isFetching,
      bpIsGT
    } = this.props;

    const products = storeListings[listingSlug]?.products || [];
    const slides = this.createSlides(products);

    return (
      <div className="productsgrid">
        {isFetching ? (
          <div style={ { height: 250, display: 'flex' } }>
            {bpIsGT('tabletSm') && <Loader />}
          </div>
        ) : (
          <div className="products_carousel_wrapper">
            <div className={ `products_carousel ${products.length < 3 ? 'hide-controls' : ''}` }>
              { products.length > 1 ? (
                <>
                  <h3>Similar Products</h3>
                  <Carousel
                    slides={ slides }
                    options={ {
                      wrapAround: false,
                      cellAlign: 'left',
                      contain: true,
                      groupCells: bpIsGT('tabletSm') ? 3 : 2
                    } }
                    leftArrow={ (
                      <Button
                        btnType="secondary"
                        icon="ChevronLeft"
                        className="pr_btn--icononly"
                        size="md"
                      />
                    ) }
                    rightArrow={ (
                      <Button
                        btnType="secondary"
                        icon="ChevronRight"
                        className="pr_btn--icononly"
                        size="md"
                      />
                    ) }
                  />
                </>
              ) : <></>}
            </div>
          </div>
        ) }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { storeListings, localizationData } = state;
  return {
    isFetching: storeListings.isFetchingListingProducts,
    storeListings,
    localizationData,
    ...bpProps(state)
  };
};

Products.propTypes = {
  fetchListingProducts: func.isRequired,
  localizationData: shape({}).isRequired,
  listingSlug: string.isRequired,
  storeSlug: string.isRequired,
  isFetching: bool.isRequired,
  storeListings: shape({}).isRequired,
  handleProductChange: func.isRequired,
  bpIsGT: func.isRequired
};

export default connect(mapStateToProps, { fetchListingProducts })(Products);
