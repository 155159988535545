import { createGlobalStyle } from 'styled-components';
import reset from './reset';
import setupTypography from './typography';
import setupButtons from './buttons';

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${props => setupTypography(props.typography)}
  ${props => setupButtons(props.button)}

  .typ--primary {
    color: ${props => props.primaryColor}
  }

  .bg--primary {
    background-color: ${props => props.primaryColor}
  }

  .border--primary {
    border-color: ${props => props.primaryColor}
  }

  .home-content {
    overflow: hidden;
  }

  .btn--primary {
    background-color: 
  }

  .btn--secondary {

  }

  /*
  primary: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
    borderColor: '#000000'
  },
  secondary: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderColor: '#000000'
  }
  */
`;

export default GlobalStyle;
