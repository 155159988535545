import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import ListingDetailsWrapper from './ListingDetailsWrapper';
import ReportProduct from '../ReportProduct';
import './ListingDetails.scss';

export function ListingDetails(props) {
  const {
    children,
    listingHeader,
    listingForm,
    holidayCopy,
    productDetailTabs,
    physicalReturnDetails,
    listing: { url }
  } = props;

  useEffect(() => {
    if (navigator.userAgent.indexOf('Win') !== -1 && document.getElementsByClassName('varient_selector__mobile')[0]) {
      document.getElementsByClassName('varient_selector__mobile')[0].style.color = 'black';
    } else if (navigator.userAgent.indexOf('Mac') !== -1 && document.getElementsByClassName('varient_selector__mobile')[0]) {
      document.getElementsByClassName('varient_selector__mobile')[0].style.color = 'white';
    }
  }, [navigator]);

  return (
    <>
      <div className="listing-details">
        {listingHeader}
        {children}

        <div className="listing-actions">
          {listingForm}
          {holidayCopy}
          {physicalReturnDetails}
          <div className="mb2" />
          {productDetailTabs}
          <ReportProduct listingSlug={ url } />
        </div>
      </div>
      {/* The placeholder div is required for `position: sticky;` to work properly */}
      <div className="listing-details__placeholder" />
    </>
  );
}

const {
  node, func, shape
} = propTypes;

ListingDetails.propTypes = {
  children: node.isRequired,
  listingHeader: node.isRequired,
  listingForm: func.isRequired,
  productDetailTabs: node.isRequired,
  physicalReturnDetails: node.isRequired,
  listing: shape({}).isRequired,
  holidayCopy: node
};

ListingDetails.defaultProps = {
  holidayCopy: null
};

export default ListingDetailsWrapper(ListingDetails);
