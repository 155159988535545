import React from 'react';

const DigitalReturnDetails = () => (
  <>
    <p className="mb2">
      Digital product returns are limited, &nbsp;
      <a
        href="https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations/"
        target="_blank"
        rel="noopener noreferrer"
        alt="Return Policy"
      >
        learn more
      </a>
      .
    </p>
  </>
);

export default DigitalReturnDetails;
