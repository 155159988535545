import React from 'react';
import { ModalConductor } from '@springforcreators/propel-ui';
import PreorderFormModal from 'components/PreorderFormModal';
import { setActiveModal } from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import ThemeButton from 'components/ThemeButton';

const PreorderFormButton = (props) => {
  const { themeData, activeModal } = useSelector(state => state);
  const dispatch = useDispatch();
  const { content } = themeData || {};

  return (
    <>
      <ThemeButton
        type="button"
        btnType="primary"
        onClick={ () => dispatch(setActiveModal('preorderform-modal')) }
      >
        {get(content, 'preorder.titleText') || 'Pre Order'}
      </ThemeButton>
      <ModalConductor
        activeModal={ activeModal }
        setActiveModal={ state => dispatch(setActiveModal(state)) }
        modals={ [
          {
            id: 'preorderform-modal',
            className: 'preorder-modal',
            node: (
              <PreorderFormModal { ...props } />
            )
          }
        ] }
      />
    </>
  );
};

export default PreorderFormButton;

