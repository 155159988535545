/**
 * @typedef {Object} ListingUrlParts
 * @property {string} pathname - the path to the listing url
 * @property {string} search - the search/query part of the listing url
 */

/**
 * Convert listing descriptors into URL parts
 *
 * @param {string} listingSlug - the slug (aka url) for the listing
 * @param {number | string} [productId] - the specific product within the listing
 * @param {number | string} [productVariationId] - the specific variation of productId (ignored if productId is not valid)
 * @param {number | string} [productSizeId] - the specific size of productId (ignored if productId is not valid)
 * @returns {ListingUrlParts} the object containing the listing url parts
 */
export function listingUrlParts(
  listingSlug,
  productId = undefined,
  productVariationId = undefined,
  productSizeId = undefined
) {
  let search = '';
  if (productId) {
    const optionalParam = (name, value) => {
      return value ? `&${name}=${value}` : '';
    };
    search = `?product=${productId}${optionalParam('variation', productVariationId)}${optionalParam('size', productSizeId)}`;
  }

  return {
    pathname: `/listing/${listingSlug}`,
    search
  };
}

/**
 *  * Convert listing descriptors into URL string
 *
 * @param {string} listingSlug - the slug (aka url) for the listing
 * @param {number | string} [productId] - the specific product within the listing
 * @param {number | string} [productVariationId] - the specific variation of productId (ignored if productId is not valid)
 * @param {number | string} [productSizeId] - the specific size of productId (ignored if productId is not valid)
 * @returns {string} the url to the specific listing
 */
export function listingUrl(
  listingSlug,
  productId = undefined,
  productVariationId = undefined,
  productSizeId = undefined
) {
  const { pathname, search } = listingUrlParts(listingSlug, productId, productVariationId, productSizeId);
  return `${pathname}${search}`;
}
