/* eslint-disable no-nested-ternary */
import {
  UPDATE_CHECKOUT,
  SET_PAYMENT,
  CLEAR_CHECKOUT,
  SET_ORDER_COST,
  SET_CHECKOUT_ERROR,
  SET_STRIPE_ERROR,
  CREATE_CHECKOUT_ERROR,
  CREATE_CHECKOUT_SUCCESS,
  RESET_CHECKOUT_STATE,
  FETCHING_CHECKOUT_DATA,
  SET_PROMO_CODE,
  SET_PROMO_CODE_ERROR,
  SET_UPDATING_CHECKOUT,
  SET_PAYMENT_METHOD,
  SET_CHECKOUT_LOADING
} from 'redux/actions';

const INITIAL_STATE = {
  createCheckoutError: null,
  createCheckoutSuccess: false,
  isLoading: false,
  platform: 'stripe',
  paymentMethod: 'afterpay',
  updatingCheckout: false,
  marketingOptIn: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHECKOUT:
      return {
        ...state,
        ...action.data,
        createCheckoutSuccess: true
      };
    case SET_UPDATING_CHECKOUT:
      return {
        ...state,
        updatingCheckout: action.data
      };
    case FETCHING_CHECKOUT_DATA:
      return {
        ...state,
        fetchingCheckout: action.data
      };
    case SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.data
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        ...action.data
      };
    case SET_PROMO_CODE_ERROR:
      return {
        ...state,
        promoCodeErrors: action.data
      };
    case SET_PAYMENT:
      return {
        ...state,
        payment: action.data
      };
    case SET_ORDER_COST:
      return {
        ...state,
        costs: {
          ...state?.costs,
          ...action?.data
        },
        updatingCheckout: false
      };
    case SET_CHECKOUT_ERROR:
      return {
        ...state,
        error: action.data
      };
    case CLEAR_CHECKOUT:
      return INITIAL_STATE;
    case CREATE_CHECKOUT_ERROR:
      return {
        ...state,
        createCheckoutError: action?.error
      };
    case CREATE_CHECKOUT_SUCCESS:
      return {
        ...state,
        createCheckoutSuccess: true
      };
    case RESET_CHECKOUT_STATE:
      return {
        ...state,
        createCheckoutSuccess: false,
        createCheckoutError: null
      };
    case SET_CHECKOUT_LOADING:
      return {
        ...state,
        isLoading: action.data
      };
    case SET_STRIPE_ERROR:
      return {
        ...state,
        stripeError: action.data
      };
    default:
      return state;
  }
};
