import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@springforcreators/propel-ui';
import './SupportContactSuccess.scss';

export const SupportContactSuccess = () => {
  return (
    <div className="support__contactsuccess typ--center">
      <h1 className="mb2">Your message has been sent.</h1>
      <h3 className="mb2">Someone on our team will get back to you shortly.</h3>
      <p className="mb3">Have you checked our support topics for an answer?</p>

      <Link className="support__contactsuccess__btn" to="/support">
        Visit support topics
        <Icon name="ArrowRight" size={ 24 } />
      </Link>
    </div>
  );
};

export default SupportContactSuccess;
