export const mapArrayToKeys = (itemArray, key) => {
  const map = {};
  itemArray.forEach((item) => {
    map[item[key]] = item;
  });

  return map;
};

export const objectToArray = (object) => {
  return Object.keys(object).map(key => object[key]);
};
