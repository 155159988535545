export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function setJsonVariables(json, values) {
  if (!isJsonString(json)) {
    throw Error('Not a valid json string.  Did you remember to stringify first?');
  }

  let newJson = JSON.stringify(Object.assign({}, JSON.parse(json)));
  const vars = Object.keys(values);

  vars.forEach((variable) => {
    const regex = new RegExp(`{{${variable}}}`, 'g');
    newJson = newJson.replace(regex, values[variable]);
  });

  return newJson;
}

