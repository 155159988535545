import propTypes from 'prop-types';
import ThankYouPageWrapper from './ThankYouPageWrapper';

export const ThankYouPage = (props) => {
  const {
    title,
    orderSummary,
    usefulLinks
  } = props;

  return (
    <div className="thankyou content row py6">
      { title }
      { orderSummary }
      { usefulLinks }
    </div>
  );
};

const {
  node
} = propTypes;
ThankYouPage.propTypes = {
  title: node.isRequired,
  orderSummary: node.isRequired,
  usefulLinks: node.isRequired
};

export default ThankYouPageWrapper(ThankYouPage);
