/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Price from 'components/Price';
import { setActiveModal, fetchCartListings, setCheckoutLoading } from 'redux/actions';
import {
  getCartLineItems,
  redirectToCheckout,
  getCartTotal,
  getCartSize
} from 'utils/cartUtils';
import { connect, useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { withCookies, Cookies, useCookies } from 'react-cookie';
import { bpProps } from 'utils/responsiveUtils';
import { Button, Icon } from '@springforcreators/propel-ui';
import tracker from 'utils/tracking';
import tiktok from 'utils/tracking/pixels/tiktok';
import get from 'lodash/get';
import { useCheckout, useCartItemValidation, usePaymentRequest } from 'hooks';
import { isDigitalProduct } from 'utils/productUtils';
import CartPill from 'components/CartPill';
import AfterPay from 'components/AfterPay';
import { Link } from 'react-router-dom';
import CheckoutButton from '../../../components/CheckoutButton';
import './CartConfirmation.scss';
import RelatedGrid from '../../../components/RelatedGrid';

export const CartConfirmation = (props) => {
  const {
    dispatchCloseModal,
    triggerRedirectToCheckout,
    userCart,
    cartProducts,
    storeId,
    userCartItem,
    bpIsGT,
    localizationData,
    setClass,
    cookies,
    previewMode
  } = props;

  const [{ _teespring_partner_attribution: partnerCookie }] = useCookies();
  const { usCheckoutOnly } = useCheckout();
  const { validateCartBeforeAction } = useCartItemValidation();
  const trackPartnersCookieInParams = tiktok.trackPartnersCookieInParams(partnerCookie);
  const { walletType } = usePaymentRequest();
  const [loadingCheckout, setLoadingCheckout] = useState(null);
  const { inventory, checkout } = useSelector(state => state);
  const dispatch = useDispatch();
  const isButtonDisabled = checkout?.isLoading;

  const viewCart = () => {
    tracker.track('product_page.view_cart_button.clicked', trackPartnersCookieInParams);
    dispatchCloseModal(trackPartnersCookieInParams, userCartItem);
  };

  const onRelatedClicked = () => {
    // Close this modal, so the clicked related item can be seen
    dispatchCloseModal(trackPartnersCookieInParams, userCartItem);
  };

  useEffect(async () => {
    tracker.track('product_page.cart_preview_modal.viewed', trackPartnersCookieInParams);
    await fetchCartListings(userCart);
  }, []);

  const currentSku = userCartItem ? Object.keys(userCartItem)[0] : '';
  const cartLineItems = getCartLineItems(userCart, cartProducts, inventory).sort((a, b) => {
    // Sort cart items so item just added to cart appears first
    let sort = 0;
    if (a.sku === currentSku) sort = -1;
    if (b.sku === currentSku) sort = 1;
    return sort;
  });
  const cartTotalPrice = getCartTotal(cartLineItems);
  const cartTotal = <Price value={ cartTotalPrice } />;

  const goToCheckout = () => {
    const affiliate = cookies.get('affiliate');
    triggerRedirectToCheckout('stripe', cartLineItems, storeId, localizationData, affiliate, userCart, cartProducts, walletType, inventory);
  };

  const totalContainer = (
    <div className="cartconfirm__total mb3">
      <div>
        <p>Total</p>
        <p>{ cartTotal }</p>
      </div>
      <p className="cartconfirm__shipping_calculated">Shipping calculated at checkout</p>
    </div>
  );

  return (
    <>
      { cartLineItems?.length > 0 && (
        <>
          <button type="button" data-testid="cart-confirmation-close" onClick={ () => dispatchCloseModal(trackPartnersCookieInParams, userCartItem) }>
            <Icon
              name="Close"
              color="black"
              className="cartconfirm__closeicon"
              size={ bpIsGT('mobileLg') ? 24 : 48 }
            />
          </button>
          <div className="cartconfirm__cartitems">
            { bpIsGT('mobileLg') && <CartPill /> }
            { cartLineItems.map(item => (
              <div className="cartconfirm__item" key={ `${item.sku}-${item.quantityInCart}` }>
                <img
                  className={ setClass({ default: 'mr2' }) }
                  src={ get(item, 'primaryImages.thumblarge') }
                  alt={ `${item.title} ${get(item, 'selectedSize.label')} ${item.colorName}` }
                />
                <div className="cartconfirm__item__info">
                  <p className="cartconfirm__item__title">
                    <span>{ item.title }</span>
                  </p>
                  <p className="mb0 typ--mediumgrey">{ item.productType }</p>
                  {!isDigitalProduct(item) && (
                    <>
                      <p className="mb0 typ--mediumgrey">
                        { `Size: ${get(item, 'selectedSize.label')}, Color: ${item.colorName}` }
                      </p>
                    </>
                  )}
                  <p className="mb0 typ--mediumgrey">
                    { `Quantity: ${item.quantityInCart}` }
                  </p>
                </div>
                <div className="cartconfirm__item__price">
                  <p className={ `mb0 ${setClass({ mobileLg: 'mr3' })}` }>
                    <Price value={ item.price } />
                  </p>
                </div>
              </div>
            )) }
          </div>
          { totalContainer }
        </>
      ) }
      <div className="relatedgrid_container">
        <RelatedGrid
          carousel={ true }
          products={ cartLineItems }
          title="Frequently bought together"
          disableQuickAdd={ true }
          onClick={ onRelatedClicked }
          recommendedShelfEvent="whitelabel.product_page.cart_preview_modal.recommended_products.viewed"
          recommendedShelfProductEvent="whitelabel.product_page.cart_preview_modal.recommended_products.clicked"
        />
      </div>
      <div className="cartconfirm__buttons-section">
        <div className="cartconfirm__buttons" data-cy="cart-modal-actions">
          <Link
            to="/cart"
            className="btn--fullwidth pr_btn pr_btn--lg btn--secondary label-m"
            onClick={ viewCart }
          >
            { `View Cart (${getCartSize(userCart)})`}
          </Link>
          {
            usCheckoutOnly
              ? (
                <CheckoutButton
                  customStyles={ {
                    backgroundColor: 'var(--primary-ui)',
                    color: 'var(--neutral-00)',
                    borderColor: 'var(--primary-ui)'
                  } }
                  className={ `${previewMode || isButtonDisabled ? 'is-disabled' : ''}` }
                  lineItems={ cartLineItems }
                  fullWidth={ true }
                  trackingCookie={ trackPartnersCookieInParams }
                  btnType="primary"
                >
                  Checkout
                </CheckoutButton>
              ) : (
                <Button
                  btnType="primary"
                  disabled={ previewMode || isButtonDisabled }
                  type="submit"
                  fullWidth={ true }
                  onClick={ () => {
                    dispatch(setCheckoutLoading(true));
                    tracker.track('product_page.checkout_button.clicked', trackPartnersCookieInParams);
                    setLoadingCheckout('card');
                    validateCartBeforeAction(goToCheckout);
                  } }
                  loading={ loadingCheckout === 'card' }
                >
                  Checkout
                </Button>
              )
          }
        </div>
        { bpIsGT('mobileLg') && (
          <div className="cartconfirm__afterpay">
            <AfterPay
              price={ cartTotalPrice }
              currency={ localizationData?.buyer_currency }
              introText="or"
            />
          </div>
        )}
      </div>
      <span className="cartconfirm__refund_policy_text">
        <svg className="cartconfirm__refund_policy_icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 3V16L12 23L3 16V3C3 3 6 1 12 1C18 1 21 3 21 3Z" stroke="#01BA61" strokeWidth="1.5" strokeLinejoin="round" />
          <path d="M7 11L10 14L17 7" stroke="#01BA61" strokeWidth="1.5" strokeLinejoin="round" />
        </svg>
        30 Day Free Return Policy
        <a
          className="cartconfirm__refund_policy_link"
          href="https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations/"
          target="_blank"
          rel="noopener noreferrer"
          alt="Return Policy"
        >
          Learn more
        </a>
      </span>

    </>
  );
};

const {
  func, shape, string, instanceOf, bool
} = propTypes;

CartConfirmation.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
  dispatchCloseModal: func.isRequired,
  triggerRedirectToCheckout: func.isRequired,
  userCart: shape({}).isRequired,
  cartProducts: shape({}).isRequired,
  storeId: string.isRequired,
  userCartItem: shape({}).isRequired,
  bpIsGT: func.isRequired,
  bpIsLT: func.isRequired,
  localizationData: shape({}).isRequired,
  setClass: func.isRequired,
  previewMode: bool.isRequired
};

const mapStateToProps = state => ({
  userCartItem: state.activeModal?.mProps?.userCartItem,
  userCart: state.userCart,
  cartProducts: state.cartProducts,
  storeId: state.stores.slug,
  localizationData: state.localizationData,
  previewMode: get(state, 'themeData.previewMode'),
  ...bpProps(state)
});

const mapDispatchToProps = dispatch => ({
  dispatchCloseModal: (amplitudeEventsParams, userCartItem) => {
    tracker.track('product_page.cart_close_button.clicked', amplitudeEventsParams);
    dispatch(setActiveModal(null, { userCartItem }));
  },
  triggerRedirectToCheckout: (method, cartLineItems, storeId, localizationData, affiliate, userCart, cartProducts, walletType, inventory, disableAfterpay) => redirectToCheckout(method, cartLineItems, storeId, localizationData, affiliate, userCart, cartProducts, walletType, inventory, disableAfterpay)
});

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(CartConfirmation));
