import { createSelector } from 'reselect';
import { mapArrayToKeys, objectToArray } from '../../utils/reduxUtils';

export const storeProducts = state => state.storeProducts;

export const allProductsSelector = createSelector(
  [storeProducts],
  ({ products }) => {
    if (products.length) {
      return Object.assign({}, mapArrayToKeys(products, 'id'));
    }
    return {};
  }
);

export const allProductsArraySelector = createSelector(
  [allProductsSelector],
  (products) => {
    return objectToArray(products);
  }
);
