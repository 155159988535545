import fetch from 'cross-fetch';
import isEmpty from 'lodash/isEmpty';
import { LEGACY_API_HOST } from 'redux/actions';

const getTrackingVars = () => {
  const trackingVars = {};

  const urlParams = new URLSearchParams(window.location.search);

  urlParams.forEach((value, key) => {
    if (!['product', 'size', 'variation'].includes(key)) {
      trackingVars[key] = value;
    }
  });

  return trackingVars;
};

export const getTrafficSource = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('utm_source') ?? '';
};

export const trackProductPage = (listingId) => {
  if (process.env.REACT_APP_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(`FAKE SPRING TRACKING: viewed_campaign with listingId:`, listingId);
    return;
  }

  const { referrer } = document;

  const trackingVars = getTrackingVars();

  const host = LEGACY_API_HOST.replace('/api', '');

  const url = `${host}/tracking/viewed_campaign`;

  const opts = {
    campaign_id: listingId,
    referrer
  };

  if (!isEmpty(trackingVars)) {
    opts.get_vars = trackingVars;
  }

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(opts)
  });
};

export default {
  trackProductPage
};
