import { ORDER_REQUEST_FAILED, ORDER_REQUEST_SUCCESS } from '../actions';

export const LOADED = 'LOADED';
export const FAILURE = 'FAILURE';
export const NONE = 'NONE';

const initialState = {
  data: [{
    lookupNumber: '',
    status: '',
    shippingMethod: '',
    created: '',
    updated: '',
    totalCost: '',
    items: [],
    shipments: []
  }],
  loadStatus: NONE
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDER_REQUEST_SUCCESS: {
      return { loadStatus: LOADED, data: action.data };
    }
    case ORDER_REQUEST_FAILED: {
      return { ...state, loadStatus: FAILURE };
    }
    default:
      return state;
  }
};
