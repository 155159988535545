import React from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { bpProps } from 'utils/responsiveUtils';
import './SingleSelector.scss';

const SingleSelector = (props) => {
  const { item, type } = props;
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));

  return (
    <span className="singleselector">
      { (type && bpIsLT('mobileLg')) && <span className="singleselector__label">{ type }</span> }
      { item.hex && <span className="singleselector__hex" style={ { backgroundColor: item.hex } } /> }
      <span>{ item.label }</span>
    </span>
  );
};

const { shape, string, oneOf } = propTypes;
SingleSelector.propTypes = {
  item: shape({
    hex: string,
    label: string
  }).isRequired,
  type: oneOf(['color', 'size']).isRequired
};

export default SingleSelector;
