export default {
  custom: {},

  1: {
    container: {
      gridTemplateColumns: {
        default: '2fr 6fr 2fr',
        mobileLg: '1fr'
      },
      gridTemplateAreas: {
        default: [
          ['menu', 'about']
        ],
        mobileLg: [
          ['about'],
          ['menu']
        ]
      }
    },
    footerAboutStyles: {
      textAlign: 'center',
      margin: {
        default: '0 auto',
        mobileLg: '0'
      }
    },
    socialStyles: {
      justifyContent: 'center'
    },
    hero: { display: 'none' }
  },

  // Template 1: logo on left, actions / menuItems align right
  2: {
    container: {
      gridTemplateColumns: {
        default: 'repeat(2, 1fr)',
        tabletLg: '2fr 6fr',
        mobileLg: '1fr'
      },
      gridTemplateAreas: {
        default: [
          ['hero', 'about'],
          ['hero', 'menu'],
          ['hero', 'newsletter']
        ],
        mobileLg: [
          ['about'],
          ['menu'],
          ['newsletter']
        ]
      }
    },
    hero: {
      display: {
        default: 'block',
        mobileLg: 'none'
      }
    },
    newsletterStyles: {
      marginTop: { default: '2rem', tabletLg: '0' }
    }
  }
};
