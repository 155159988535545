/* eslint-disable react/prop-types, import/no-dynamic-require */

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import SearchPage from 'pages/SearchPage';
import ListingPage from 'pages/ListingPage';
import Collection from 'pages/Collection';
import ShoppingCart from 'pages/ShoppingCart';
import AboutPage from 'pages/AboutPage';
import FourOhFourPage from 'pages/FourOhFourPage';
import RedirectWithPreprocess from 'pages/RedirectWithPreprocess';
import ThankYouPage from 'pages/ThankYouPage';
import Home from 'pages/Home';
import TermsPage from 'pages/TermsPage';
import PrivacyPage from 'pages/PrivacyPage';
import SupportPage from 'pages/SupportPage';
import SupportWrapper from 'pages/SupportPage/SupportWrapper';
import SupportArticle from 'pages/SupportPage/SupportArticle';
import SupportContact from 'pages/SupportPage/SupportContact';
import SupportContactSuccess from 'pages/SupportPage/SupportContactSuccess';
import RecoverOrderPage from 'pages/SupportPage/RecoverOrder';
import GenericFourOhFourPage from 'pages/GenericFourOhFourPage';
import TrackOrderPage from 'pages/SupportPage/TrackOrderPage';
import CheckoutPage from 'pages/CheckoutPage';
import LoginPage from 'pages/LoginPage';
import CheckoutExpress from 'pages/CheckoutExpress';
import get from 'lodash/get';
import ScrollToTop from 'containers/ScrollToTop';
import 'scrolling-element';
import store from '../store';

let themeRoutes;
try {
  themeRoutes = require(`themes/${store.slug}/routes`).default;
} catch (error) {
  themeRoutes = [];
}

function collectionRoute(collection, passThroughProps) {
  return (
    <Route
      key={ collection.slug }
      path={ `/${ collection.slug }` }
      render={ props => <Collection { ...props } { ...passThroughProps } /> }
    />
  );
}

function subCollectionRoutes(collection, passThroughProps) {
  return collection.collections
    ? collection.collections.map(subCollection => collectionRoute(subCollection, passThroughProps))
    : [];
}

function generateCollectionRoutes(collections, passThroughProps) {
  return collections.reduce((acc, collection) => {
    return [
      ...acc,
      collectionRoute(collection, passThroughProps),
      ...subCollectionRoutes(collection, passThroughProps)
    ];
  }, []);
}

function generateThemeRoutes(routes, passThroughProps) {
  return routes.map(route => (
    <Route
      key={ Math.random() }
      path={ route.path }
      exact={ true }
      render={ props => <route.node { ...props } { ...passThroughProps } /> }
    />
  ));
}

export default function router(passThroughProps, themeData, collectionsData) {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          { themeRoutes && generateThemeRoutes(themeRoutes, passThroughProps) }
          <Route
            path="/"
            exact={ true }
            render={ props => <Home { ...props } { ...passThroughProps } /> }
          />
          <Route
            path="/search"
            exact={ true }
            render={ props => <SearchPage { ...props } { ...passThroughProps } /> }
          />
          <Route
            path="/listing/:slug"
            exact={ true }
            render={ props => <ListingPage { ...props } { ...passThroughProps } /> }
          />

          <RedirectWithPreprocess
            path="/checkout-complete/:cartId"
            exact={ true }
            passThroughProps={ passThroughProps }
            beforeRedirect={ (props) => {
              const { computedMatch } = props;
              const passProps = props.passThroughProps;
              const { destroyCart } = passProps;

              destroyCart(computedMatch.params.cartId);
              return props;
            } }
            redirectPath="/thankyou"
          />

          <Route
            path="/thankyou"
            exact={ true }
            render={ props => <ThankYouPage { ...passThroughProps } { ...props } /> }
          />
          <Route
            path="/cart"
            exact={ true }
            render={ props => <ShoppingCart { ...passThroughProps } { ...props } /> }
          />
          <Route
            path="/terms-of-use"
            exact={ true }
            render={ props => <TermsPage { ...passThroughProps } { ...props } /> }
          />
          <Route
            path="/privacy-policy"
            exact={ true }
            render={ props => <PrivacyPage { ...passThroughProps } { ...props } /> }
          />

          <Route
            path="/support"
            exact={ true }
            render={ props => (
              <SupportWrapper
                { ...passThroughProps }
                { ...props }
                showGetSupport={ true }
                showSidenav={ false }
              >
                <SupportPage { ...passThroughProps } { ...props } />
              </SupportWrapper>
            ) }
          />
          <Route
            path="/support/:postSlug"
            exact={ true }
            render={ props => (
              <SupportWrapper
                { ...passThroughProps }
                { ...props }
                showGetSupport={ true }
                showSidenav={ true }
              >
                <SupportArticle { ...passThroughProps } { ...props } />
              </SupportWrapper>
            ) }
          />

          <Route
            path="/contact"
            exact={ true }
            render={ props => (
              <SupportWrapper
                { ...passThroughProps }
                { ...props }
                showSidenav={ true }
                showGetSupport={ true }
                page={ { id: 'contact', label: 'Contact' } }
              >
                <SupportContact { ...passThroughProps } { ...props } />
              </SupportWrapper>
            ) }
          />

          <Route
            path="/contact-success"
            exact={ true }
            render={ props => (
              <SupportWrapper
                { ...passThroughProps }
                { ...props }
                showSidenav={ false }
                showGetSupport={ true }
                page={ { id: 'contact', label: 'Contact' } }
              >
                <SupportContactSuccess { ...passThroughProps } { ...props } />
              </SupportWrapper>
            ) }
          />

          <Route
            path="/track"
            exact={ true }
            render={ props => (
              <SupportWrapper
                { ...passThroughProps }
                { ...props }
                showSidenav={ false }
                showGetSupport={ false }
                page={ { id: 'track', label: 'Track my order' } }
              >
                <TrackOrderPage { ...passThroughProps } { ...props } />
              </SupportWrapper>
            ) }
          />

          <Route
            path="/track/recover"
            exact={ true }
            render={ props => (
              <SupportWrapper
                { ...passThroughProps }
                { ...props }
                showSidenav={ false }
                showGetSupport={ false }
                page={ { id: 'recover-order', label: 'Recover order number' } }
              >
                <RecoverOrderPage { ...passThroughProps } { ...props } />
              </SupportWrapper>
            ) }
          />
          {/* Deprecate /checkout/express route once Checkout is rolled out */}
          <Route
            path="/checkout/express"
            exact={ true }
            render={ props => <CheckoutExpress { ...passThroughProps } { ...props } /> }
          />

          <Route
            path="/checkout/:checkoutId"
            exact={ true }
            render={ props => <CheckoutPage { ...passThroughProps } { ...props } /> }
          />

          <Route
            path="/login"
            exact={ true }
            render={ props => <LoginPage { ...passThroughProps } { ...props } /> }
          />

          { get(themeData, 'content.header.showAbout') && (
            <Route
              path="/about"
              exact={ true }
              render={ props => <AboutPage { ...passThroughProps } { ...props } /> }
            />
          ) }

          { generateCollectionRoutes(collectionsData, passThroughProps) }

          <Route
            path="/404"
            render={ () => <GenericFourOhFourPage /> }
          />
          <Route
            path="*"
            render={ props => <FourOhFourPage { ...passThroughProps } { ...props } /> }
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}
