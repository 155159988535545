import React, { useState } from 'react';
import { shape, func, bool } from 'prop-types';

export const ListingGalleryItem = ({
  thumbData,
  isActive,
  onThumbClick
}) => {
  const [hovering, setHovering] = useState(false);
  const image = thumbData?.originalData;
  const active = isActive || hovering;

  return (
    <button
      type="button"
      className={ `listing-gallery-menu-item ${active ? 'is-active' : ''}` }
      onMouseEnter={ () => setHovering(true) }
      onMouseLeave={ () => setHovering(false) }
      onClick={ () => onThumbClick(thumbData) }
      data-cy={ `listing-gallery-menu-item-${thumbData?.index}` }
    >
      <img
        srcSet={ `${image.thumbsmall}, ${image.thumblarge} 2x` }
        src={ image.thumbsmall }
        alt={ `${image.label}` }
      />
    </button>
  );
};

ListingGalleryItem.propTypes = {
  thumbData: shape({}).isRequired,
  isActive: bool.isRequired,
  onThumbClick: func.isRequired
};

export default ListingGalleryItem;
