export default {
  custom: {},

  1: {
    container: {
      gridTemplateColumns: {
        default: 'auto minmax(0, 1fr) auto',
        tabletMd: '50px minmax(0, 1fr) 75px'
      },
      gridTemplateAreas: {
        default: [['logo', 'menuItems', 'actions']],
        tabletMd: [['hamburger', 'logo', 'actions']]
      }
    },
    hamburger: {
      display: {
        default: 'none',
        tabletMd: 'block'
      }
    },
    logo: {
      maxHeight: { default: '55px', tabletMd: '30px' },
      display: 'flex',
      justifyContent: { tabletMd: 'center' },
      alignItems: 'center'
    },
    menuItems: {
      textAlign: 'center',
      fontFamily: 'Inter, sans-serif',
      display: {
        default: 'block',
        tabletMd: 'none'
      }
    },
    constrain: false
  }
};
