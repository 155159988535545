/*eslint-disable camelcase, consistent-return */

import React, { useEffect } from 'react';
import { bpProps } from 'utils/responsiveUtils';
import { connect } from 'react-redux';
import { Carousel, Button } from '@springforcreators/propel-ui';
import propTypes, { bool } from 'prop-types';
import tracker from 'utils/tracking';
import { PRODUCT_CLICK, PRODUCT_IMPRESSIONS, pushEvent } from 'utils/tracking/gtm';
import get from 'lodash/get';
import map from 'lodash/map';
import ProductTile from 'components/ProductTile';
import removeCurrencySymbol from '../../lib';
import './RelatedGrid.scss';
import useRelatedProducts from '../../hooks/useRelatedProducts';

const carouselOptions = {
  wrapAround: true,
  cellAlign: 'left',
  contain: true,
  groupCells: 2,
  setGallerySize: true,
  resize: true
};

const RelatedGrid = (props) => {
  const {
    setClass,
    products,
    title,
    localizationData,
    storeName,
    sellerId,
    onClick,
    bpIsGT,
    carousel,
    recommendedShelfEvent,
    recommendedShelfProductEvent
  } = props;

  const trackRecommendedShelfProduct = () => {
    if (recommendedShelfProductEvent) {
      tracker.track(recommendedShelfProductEvent);
    }
  };

  const onProductClick = (product, position) => {
    onClick();
    trackRecommendedShelfProduct();
    const eventBody = {
      currencyCode: get(localizationData, 'buyer_currency'),
      click: {
        actionField: {
          list: 'You Might Also Like'
        },
        products: [{
          name: get(product, 'name'),
          id: get(product, 'listingId'),
          price: removeCurrencySymbol(get(product, 'price')),
          brand: storeName,
          category: get(product, 'productName'),
          position: position + 1,
          dimension8: sellerId,
          dimension9: get(product, 'listingId')
        }]
      }
    };
    pushEvent(PRODUCT_CLICK, eventBody);
  };

  const relatedProducts = useRelatedProducts(products).slice(0, 4);

  const trackRecommendedShelfView = () => {
    if (recommendedShelfEvent) {
      tracker.track(recommendedShelfEvent);
    }
  };

  useEffect(() => {
    if (relatedProducts.length) {
      const productImpressions = relatedProducts.map((product, position) => (
        {
          name: get(product, 'name'),
          id: get(product, 'listingId'),
          price: removeCurrencySymbol(get(product, 'price')),
          brand: storeName,
          category: get(product, 'productName'),
          list: 'You Might Also Like',
          position: position + 1,
          dimension8: sellerId,
          dimension9: get(product, 'listingId')
        }
      ));
      const eventBody = {
        currencyCode: get(localizationData, 'buyer_currency'),
        impressions: productImpressions
      };
      pushEvent(PRODUCT_IMPRESSIONS, eventBody);
    }
    trackRecommendedShelfView();
  }, []);

  const createSlides = (relProducts) => {
    return map(relProducts, (product, i) => {
      return {
        id: product.id,
        content: (
          <ProductTile
            product={ product }
            list="You Might Also Like"
            position={ i }
            disableQuickAdd={ true }
            onClick={ () => onProductClick(product, i) }
            showListingTitle={ true }
            showProductType={ true }
            shortenText={ true }
          />
        )
      };
    });
  };

  const slides = createSlides(relatedProducts);

  return relatedProducts.length > 0 && (
    <div className={ `relatedgrid ${setClass({ default: 'mb6', mobileLg: 'mb2' })}` }>
      { bpIsGT('tabletLg') && !carousel
        && <h3 className={ `typ--center ${ setClass({ default: 'mb5', mobileMd: 'typ--h4 mb3 relatedgrid__title' }) }` }>{ title }</h3>
      }
      { bpIsGT('tabletLg') && !carousel
        ? (
          <div className="relatedgrid__products">
            { relatedProducts.map((product, i) => {
              return (
                <ProductTile
                  product={ product }
                  list="You Might Also Like"
                  position={ i }
                  key={ `relatedGrid-tile-${product.id}` }
                  onClick={ () => onProductClick(product, i) }
                  disableQuickAdd={ true }
                />
              );
            }) }
          </div>
        )
        : slides.length > 1 && (
          <div className=" products_carousel">
            <h3 className="typ--h4 mb3 relatedgrid__title">{ title }</h3>
            <Carousel
              slides={ slides }
              options={ carouselOptions }
              leftArrow={ (
                <Button
                  btnType="secondary"
                  icon="ChevronLeft"
                  className="pr_btn--icononly"
                  size="md"
                />
              ) }
              rightArrow={ (
                <Button
                  btnType="secondary"
                  icon="ChevronRight"
                  className="pr_btn--icononly"
                  size="md"
                />
              ) }
            />
          </div>
        )
      }
    </div>
  );
};

const {
  func,
  arrayOf,
  object,
  string,
  shape,
  number
} = propTypes;

RelatedGrid.propTypes = {
  setClass: func.isRequired,
  products: arrayOf(object).isRequired,
  title: string,
  localizationData: shape({}).isRequired,
  storeName: string,
  sellerId: number.isRequired,
  onClick: func,
  bpIsGT: func.isRequired,
  carousel: bool,
  recommendedShelfEvent: string,
  recommendedShelfProductEvent: string
};

RelatedGrid.defaultProps = {
  title: 'You might also like',
  storeName: '',
  onClick: () => null,
  carousel: false,
  recommendedShelfEvent: null,
  recommendedShelfProductEvent: null
};

const mapStateToProps = state => ({
  ...bpProps(state),
  localizationData: state.localizationData,
  storeName: state.stores.name,
  sellerId: state.stores.sellerId
});

export default connect(mapStateToProps)(RelatedGrid);
