import {
  AfterpayClearpayMessageElement
} from '@stripe/react-stripe-js';
import { string, oneOfType, number } from 'prop-types';
import { convertToCents } from 'utils/moneyUtils';
import tracker from 'utils/tracking';
import './AfterPay.scss';

const AfterPay = ({ price, currency, introText }) => {
  return (
    <div className="afterpay__cta" onClick={ () => tracker.track('product_page.afterpay.clicked') } role="presentation">
      <AfterpayClearpayMessageElement
        options={ {
          amount: convertToCents(price),
          currency,
          introText,
          showInterestFree: false
        } }
      />
    </div>
  );
};

AfterPay.propTypes = {
  price: oneOfType([string, number]).isRequired,
  currency: string.isRequired,
  introText: string
};

AfterPay.defaultProps = {
  introText: 'Pay in'
};

export default AfterPay;
