import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ThemeWrapper from 'containers/ThemeWrapper';
import get from 'lodash/get';
import { Icon } from '@springforcreators/propel-ui';
import './SearchInput.scss';

function isFocused(className) {
  return document.activeElement.classList.contains(className);
}

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyEnter = this.onKeyEnter.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyPressHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyPressHandler);
  }

  onChangeHandler(event) {
    const { onSearchChange } = this.props;
    if (onSearchChange instanceof Function) onSearchChange(event);
  }

  onKeyEnter(event) {
    const { onSearchSubmit, searchValue } = this.props;

    if ((event && onSearchSubmit instanceof Function) || isFocused('search-box')) {
      onSearchSubmit(get(event, 'currentTarget.innerText') || searchValue);
      document.querySelector('.search-box').blur();
    }
  }

  onKeyUp() {
    const { items } = this.props;
    if (items.length <= 0) return;

    const buttons = document.querySelectorAll('.auli__button');
    const currIndex = parseInt(document.activeElement.getAttribute('data-index'));

    if (isFocused('auli__button') && currIndex !== 0) {
      buttons[currIndex - 1].focus();
    } else if (isFocused('auli__button') && currIndex === 0) {
      document.querySelector('.search-box').focus();
    }
  }

  onKeyDown() {
    const { items } = this.props;
    if (items.length <= 0) return;

    const buttons = document.querySelectorAll('.auli__button');
    const currIndex = parseInt(document.activeElement.getAttribute('data-index'));
    const lastIndex = buttons.length;

    if (isFocused('search-box')) {
      buttons[0].focus();
    } else if (isFocused('auli__button') && currIndex !== lastIndex - 1) {
      buttons[currIndex + 1].focus();
    }
  }

  onKeyPressHandler(event) {
    const { searchResultActive } = this.props;

    if (!searchResultActive) {
      const keyCodeHandler = ({
        13: this.onKeyEnter,
        38: this.onKeyUp,
        40: this.onKeyDown
      }[event.keyCode]);

      if (keyCodeHandler instanceof Function) {
        keyCodeHandler();
      }
    }
  }

  render() {
    const {
      items,
      searchResultActive,
      searchValue,
      meta
    } = this.props;

    const searchPath = `/search?searchterm=${searchValue}`;

    return (
      <div className="search-input">
        <input
          type="text"
          className="search-box"
          placeholder={ get(meta, 'searchOptions.placeholder') || 'Longsleeve Tee' }
          value={ searchValue }
          onChange={ this.onChangeHandler }
        />
        <Link to={ searchPath } onClick={ this.onKeyEnter } className="search-submit">
          <Icon name="Search" />
        </Link>

        { (!searchResultActive && items) && (
          <div className="auto-complete-container">
            <ul className="auto-complete-list">
              { items.slice(0, 5).map((item, index) => (
                <li key={ item.id } className="auto-complete-list-item">
                  <button
                    data-index={ index }
                    className="auli__button"
                    type="button"
                    value={ item.value }
                    onClick={ this.onKeyEnter }
                  >
                    { item.label }
                  </button>
                </li>
              )) }
            </ul>
          </div>
        ) }
      </div>
    );
  }
}

const {
  arrayOf,
  func,
  bool,
  string,
  object,
  oneOfType,
  shape
} = PropTypes;
SearchInput.propTypes = {
  onSearchChange: func.isRequired,
  onSearchSubmit: func.isRequired,
  items: arrayOf(object).isRequired,
  searchResultActive: oneOfType([bool, string]).isRequired,
  searchValue: string.isRequired,
  meta: shape({
    searchOptions: shape({
      placeholder: string
    })
  }).isRequired
};

export default ThemeWrapper(SearchInput, 'meta.searchOptions');
